import { ServiceMaker } from './index'

export const filterBranchesByCityAndCompany = (data) => ServiceMaker('branch/filter', 'POST', data)

export const getAllBranchesByCompany = (id) => ServiceMaker('branch/list/by/company/' + id, 'GET')

export const viewBranch = (id) => ServiceMaker('branch/view/' + id, 'GET')

export const filterBookBranches = data => ServiceMaker('branch/book/filter', 'POST', data)

export const fetchBranchesList = data => ServiceMaker('branch/fetch','POST',data)

export const deleteBranchService = (id) => ServiceMaker(`branch/delete/${id}`,'DELETE')

export const createBranchService = (data) => ServiceMaker('branch/manage','POST',data,0)

export const updateBranchService = (data) => ServiceMaker('branch/manage','POST',data)