import React, { Component } from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from './containers/Loading'
import 'react-toastify/dist/ReactToastify.css'

const asyncLogin = Loadable({
  loader: () => import('./containers/Login'),
  loading: Loading,
})

const asyncDashBoard = Loadable({
  loader: () => import("./containers/DashBoard"),
  loading: Loading,
})

const asyncTrack = Loadable({
  loader: ()=>import("./containers/operations/bookutils/Track"),
  loading: Loading
})

class App extends Component {
  
  componentWillUnmount(){
    console.log('I will unmount')
    sessionStorage.removeItem('kabra:token')
  }
  
  render() {
    return ( 
      <Router>
        <Switch>
          <Route path="/login" component={asyncLogin} />
          <Route path="/dashboard" component={asyncDashBoard} />
          <Route path="/track/:docketNumber([^]*)" component={asyncTrack} />
          <Route path="*" component={asyncLogin} />
        </Switch>
      </Router>
    )
  }
}

export default App;
