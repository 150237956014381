import { Map, fromJS } from 'immutable'
import * as Yup from 'yup'
export const Customer_boolean= (val) => ({
    name: val,
    contact: val,
    GSTIN: val,
    address:{
        l1:val,
        l2:val,
        city:val,
        pincode:val
    }
})


export const validationSchema = fromJS({
    name: Yup.string().required().min(3).max(15),
    contact: Yup.string().required().min(10, 'Contact must be 10 digits').max(10, 'Contact must be 10 digits'),
    GSTIN: Yup.string().required(),
    address: {
        l1: Yup.string().required().min(3).max(15),
        l2: Yup.string().required().min(3).max(15),
        city: Yup.string().required().min(3).max(15),
        pincode: Yup.string().required().min(3).max(10)
    },
    // connected:Yup.string().min(2).max()

})

export const validate = (schema, obj) => {
    try {
        const res = schema.validateSync(obj)
        return false
    }
    catch (err) {
        console.log(err)
        return err.message
    }
}

// export const preparePromises = (path, obj) => {
   
//     return Object.keys(obj).map(key =>{
//         console.log('typeof obj[key]:',typeof obj[key]);
//         console.log('key:', key,'value', obj[key], 'is array : ', Array.isArray(obj[key]));
//         return (
//             typeof obj[key] == 'object' ? 
//             preparePromises([...path, key], obj[key]) :
//             Array.isArray(obj[key])?
//             { what: ['here'], val: 'ours' }
//             :
//             ({
//                 what: [...path, key], 
//                 val: obj[key]
//             })
//     )})
// }