import { isArrayCheck } from '../../generics/CheckArray';
import { AssertionError } from 'assert';

process.hrtime = require('browser-process-hrtime')
// @ts-check

/**
 * A package
 * @typedef {Object} Package
 * @property {string} destCity - a destination city
 * @property {string} route - a route
 * @property {string} destBranch - a destination branch
 * @property {boolean} deliverable - a flag to show if package can be delivered
 * @property {boolean} verified - a flag to show if package is verified
 * 
 * @typedef {Object} Select
 * @property {string} label
 * @property {string} value
 * 
 * @typedef {Object} Criteria
 * @property {Select} city
 * @property {Select} route
 * @property {Select} branch
 * @property {boolean} deliverableFilter
 * @property {boolean} unverifiedFilter
 * 
 * @param {Package} pck
 * @param {Criteria} criteria
 */

export const filter = (pck, criteria) => {

    let {
        city,
        route,
        branch,
        deliverableFilter,
        unverifiedFilter
    } = criteria

    // console.log('got criteria : ', criteria, '\npck : ', pck)

    if (city && typeof city.value === 'string' && city.value !== pck.destCity) return false
    if (route && typeof route.value === 'string' && route.value !== pck.route) return false
    if (branch && typeof branch.value === 'string' && branch.value !== pck.destBranch) return false
    if (deliverableFilter === true && pck.deliverable != true) return false
    if (unverifiedFilter === true && pck.verified != true) return false

    return true
}

export const updateDocketMap = (docketMap, criteria, updateDoc, options) => {

    let onlyOne = options ? options.onlyOne === true : false
    let docketNo = options && options.docketNo ? options.docketNo : null
    let filterData = options && options.filterData === true ? true : false

    let flag = true

    if (docketNo !== null) {
        // let start = process.hrtime()
        let packageMap = docketMap[docketNo]
        Object.keys(packageMap).forEach(uid => {
            if (filterData) {
                let packages = packageMap[uid].filter(pck => criteria(pck))
                if (isArrayCheck(packages)) {
                    packageMap[uid] = packages
                } else {
                    delete packageMap[uid]
                }
            } else {

                packageMap[uid] = packageMap[uid].map(pck => {
                    if (flag && criteria(pck)) {
                        if (onlyOne === true) {
                            flag = false
                        }
                        return ({
                            ...pck,
                            ...((typeof updateDoc == 'object') ? updateDoc : updateDoc(pck))
                        })
                    }
                    return pck
                })
            }

        })
        docketMap[docketNo] = packageMap
        // let end = process.hrtime(start)
        // console.log('time took : ', end[0]*1000 + end[1]/1000000, ' ms')
        return docketMap
    }

    // let start = process.hrtime()
    Object.keys(docketMap).forEach(d => {
        let packageMap = docketMap[d]
        Object.keys(packageMap).forEach(uid => {

            if (filterData) {
                let packages = packageMap[uid].filter(pck => criteria(pck))
                if (isArrayCheck(packages)) {
                    packageMap[uid] = packages
                } else {
                    delete packageMap[uid]
                }
            } else {
                packageMap[uid] = packageMap[uid].map(pck => {
                    if (flag && criteria(pck)) {
                        if (onlyOne === true) {
                            flag = false
                        }
                        return ({
                            ...pck,
                            ...((typeof updateDoc == 'object') ? updateDoc : updateDoc(pck))
                        })
                    }
                    return pck
                })
            }
        })
        docketMap[d] = packageMap
    })
    // let end = process.hrtime(start)
    // console.log('v2 time took : ', end[0]*1000 + end[1]/1000000, ' ms')

    return docketMap

}

export const makeUID = p => {
    const {
        materialType,
        packingType,
        dimension,
        weight,
        rate,
        amount,
        stack,
        haz,
        frag
    } = p, { l, b, h } = dimension
    let uniqueId = [
        materialType._id,
        packingType._id,
        l, b, h,
        weight,
        rate,
        amount,
        stack,
        haz,
        frag
    ]
    return uniqueId.join('@')
}

/**
 * 
 * @param { Object} docketMap 
 * @param {Function} criteria 
 * @param {Object} updateDoc 
 * @param {Object} options
 * @property { string } boxNumber
 * @property { Function} procPackages
 */

export const boxifyUpdateDocketMap = (docketMap, criteria, updateDoc, options) => {


    let { add, remove, create, boxNumber } = options

    if (create == true) {
        docketMap[boxNumber] = {}
    }
    Object.keys(docketMap).forEach(d => {

        if (remove != true && d == boxNumber) {
            return
        }


        let packageMap = docketMap[d]
        Object.keys(packageMap).forEach(uid => {

            let packages = packageMap[uid].filter(pck => {
                if (criteria(pck)) {
                    if (remove == true) {
                        let docket = pck.docket[0].docketNumber[0]
                        console.log("docket:", docket)
                        if (!(docket in docketMap)) {
                            docketMap[docket] = {}
                        }
                        if (!(uid in docketMap[docket])) {
                            docketMap[docket][uid] = [{ ...pck, ...updateDoc }]
                        }
                        else docketMap[docket][uid].push({ ...pck, ...updateDoc })
                    } else {
                        if (!(uid in docketMap[boxNumber])) {
                            docketMap[boxNumber][uid] = [{ ...pck, ...updateDoc }]
                        }
                        else docketMap[boxNumber][uid].push({ ...pck, ...updateDoc })
                    }
                    return false
                }
                return true
            })
            if (isArrayCheck(packages)) {
                packageMap[uid] = packages
            } else {
                delete packageMap[uid]
            }
        })
        if (Object.keys(packageMap).length < 1) {
            delete docketMap[d]
        } else {
            docketMap[d] = packageMap
        }

    })

}