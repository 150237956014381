import {
    REGISTER_SOCKET,
    REGISTER_SUCCESS,
    REGISTER_FAIL
} from '../constants/auth'

import { toast } from 'react-toastify'

export default (state, action) => {
    switch (action.type) {
        case REGISTER_SOCKET: {
            console.log('socket registered successfully!')
            return state
        }
        case REGISTER_SUCCESS: {
            return {
                ...state,
                socketLockAcquired: true
            }
        }
        case REGISTER_FAIL: {
            toast.error('Already logged in from another device!')
            return {
                ...state,
                token: null
            }
            return state
        }
    }
}