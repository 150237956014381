const prefix = 'BOOK:'

export const BUILTY_UPDATE_ACK = prefix + 'BUILTY_UPDATE_ACK'

export const BUILTY_UPDATE_REQ = prefix + 'BUILTY_UPDATE_REQ'

export const PACKAGE_UPDATE_ACK = prefix + 'PACKAGE_UPDATE_ACK'

export const DEST_CITY_UPDATE_ACK = prefix + 'DEST_CITY_UPDATE_ACK'

export const PAYMENT_UPDATE_ACK = prefix + 'PAYMENT_UPDATE_ACK'

export const SET_DOCKET_FROM_EWAYBILL = prefix + 'SET_DOCKET_FROM_EWAYBILL'

export const PAYMENT_UPDATE_REQ = prefix + 'PAYMENT_UPDATE_REQ'

export const REFRESH_BOOKING = prefix + 'REFRESH_BOOKING'

export const GET_BRANCHES_BY_COMPANY = prefix + 'GET_BRANCHES_BY_COMPANY'

export const CANCEL_BOOKING = prefix + 'CANCEL_BOOKING'

export const CANCEL_BOOKING_FAILED = prefix + 'CANCEL_BOOKING_FAILED'

export const RESEND_SMS = prefix + 'RESEND_SMS'

export const RESEND_SMS_FAILED = prefix + 'RESEND_SMS_FAILED'

export const ID_FETCH_ACK = prefix + 'ID_FETCH_ACK'

export const BILL_FETCH_ACK = prefix + 'BILL_FETCH_ACK'

export const DOCKETS_FETCH_ACK = prefix + 'DOCKETS_FETCH_ACK'

export const SENDER_GST_INVALID = prefix + 'SENDER_GST_INVALID'

export const RECEIVER_GST_INVALID = prefix + 'RECEIVER_GST_INVALID'

export const RESET_SENDER_ADDRESS = prefix + 'RESET_SENDER_ADDRESS'

export const RESET_RECEIVER_ADDRESS = prefix + 'RESET_RECEIVER_ADDRESS'

export const SHOW_LOADER = prefix + 'SHOW_LOADER'

export const HIDE_LOADER = prefix + 'HIDE_LOADER'

export const HIDE_EWAYBILL_LOADER = prefix + 'HIDE_EWAYBILL_LOADER'

export const SHOW_EWAYBILL_LOADER = prefix + 'SHOW_EWAYBILL_LOADER'

export const SHOW_GST_LOADER = prefix + 'SHOW_GST_LOADER'

export const HIDE_GST_LOADER = prefix + 'HIDE_GST_LOADER'

export const CREDIT_NUMBER_HIDE_LOADER = prefix + 'CREDIT_NUMBER_HIDE_LOADER'

export const CREDIT_NUMBER_SHOW_LOADER = prefix + 'CREDIT_NUMBER_SHOW_LOADER'

export const SHOW_GST_LOADING_FOR_RECEIVER = prefix + 'SHOW_GST_LOADING_FOR_RECEIVER'

export const HIDE_GST_LOADING_FOR_RECEIVER = prefix + 'HIDE_GST_LOADING_FOR_RECEIVER'

export const NO_DATA = prefix + 'NO_DATA'

export const SET_SENDER_ADDRESS_FROM_OPBRANCH = prefix + 'SET_SENDER_ADDRESS_FROM_OPBRANCH'

export const SET_SENDER_ADDRESS_FROM_OTHER = prefix + 'SET_SENDER_ADDRESS_FROM_OTHER'

export const SET_RECEIVER_ADDRESS_FROM_OPBRANCH = prefix + 'SET_RECEIVER_ADDRESS_FROM_OPBRANCH'

export const SET_RECEIVER_ADDRESS_FROM_OTHER = prefix + 'SET_RECEIVER_ADDRESS_FROM_OTHER'

export const SET_ROUTES = prefix + 'SET_ROUTES'

export const TOGGLE_FRANCHISE = prefix + 'TOGGLE_FRANCHISE'

export const CHANGE_FRANCHISE_NO = prefix + 'CHANGE_FRANCHISE_NO'

export const GIVE_COMMISSION = prefix + 'GIVE_COMMISSION'

export const FRANCHISE_LOADING = prefix + 'FRANCHISE_LOADING'

export const FRANCHISE_FAILED = prefix + 'FRANCHISE_FAILED'

export const HIDE_LOADER_WITHOUT_BLUR = prefix + 'HIDE_LOADER_WITHOUT_BLUR'

export const SHOW_LOADER_WITHOUT_BLUR = prefix + 'SHOW_LOADER_WITHOUT_BLUR'

export const UPDATE_BUILTY = prefix + 'UPDATE_BUILTY'

export const SET_PACKAGE_ERROR_FLAG_FALSE = prefix + 'SET_PACKAGE_ERROR_FLAG_FALSE'

export const TOUCH_SENDER = prefix + 'TOUCH_SENDER'

export const TOUCH_RECEIVER = prefix + 'TOUCH_RECEIVER'

