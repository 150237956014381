import {
  UNLOAD_FAIL,
  UNLOAD_PENDING,
  UNLOAD_SUCCESS,
  OUT_FOR_DELIVERY_REMARK,
  DEST_CITY_FILTER,
  DEST_BRANCH_FILTER
} from '../constants/unloading'

import {
  SUCCESS,
  FAILED,
  INFO,
  errorHandler
} from './helpers'

import { showMessage, success } from './UserReducer'

export default (state, action) => {

  switch (action.type) {

    case UNLOAD_PENDING: {
      return { ...state, unload_api: true }
    }

    case UNLOAD_FAIL: {
      // errorHandler(action.payload)
      showMessage('Unloaded failed', FAILED)
      return { ...state, unload_api: false }
    }

    case UNLOAD_SUCCESS: {
      showMessage('Unloaded successfully', SUCCESS)
      return { ...state, unload_api: false }
    }

    case OUT_FOR_DELIVERY_REMARK: {
      showMessage('Reason Set Successfully !', success)
      return state
    }

    case DEST_CITY_FILTER: {
      return {
        ...state,
        unloadDestBranch: '',
        unloadDestCity: action.payload,
      }
    }

    default: {
      return state
    }
  }
}