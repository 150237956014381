import { fromJS, Map as immutableMap } from 'immutable'
import { toast } from "react-toastify";
import * as yup from 'yup'

import {
    CHANGE_PERMITTED_BRANCH,
    CHANGE_CONNECTED_CUSTOMERS,
    RATES_ADD,
    RATES_DEL,
    RATES_EDIT,
    GET_ROUTES,
    GET_CUSTOMER_LIST,
    CREDIT_FIELD_CHANGE,
    NEW_CREDIT_SUBMIT,
    FETCH_DUE_CREDIT,
    SETTLE_DUES_SUCCESS,
    SETTLE_DUES_FAILED,
    CHANGE_PACKAGE_AMOUNT_SETTLEMENT,
    GET_INVOICE_DETAILS,
    CLEAR_RECEIPT_NUMBER,
    TOGGLE_CREDIT_ROW,
    CHANGE_FIXED,
    CLOSE_CREDIT_PRINT
} from '../constants/customer'

import {
    RESET_INCUR_EXPENSE,
    DELETE_EXPENSE_RECORD,
    EDIT_EXPENSE_RECORD,
    SET_EXPENSE_INCUR,
    FUEL_IN_EXPENSE_ON_CHANGE,
    FUEL_EXPENSE_CREATE_SUCCESS,
    SET_FUEL_VALUES,
    DELETE_FUEL_EXPENSE_FAILED,
    DELETE_FUEL_EXPENSE,
    EDIT_EXPENSE_RECORD_FAILED,
    COULD_NOT_FETCH_CONTACT,
    DELETE_EXPENSE_RECORD_FAILED,
    NON_CASH_CHANGE,
    FIXED_EXPENSE_TYPE,
    SET_PAST_EXPENSES,
    AVAILABLE_BALANCE,
    SHOW_LOADER_IN_AMOUNT,
    HIDE_LOADER_IN_AMOUNT,
    DISABLE_OP_KMS,
    ENABLE_OP_KMS,
    PRINT_EXPENSE_VOUCHER,
    CLOSE_VOUCHER_MODAL
} from "../constants/expense"

import {
    DELIVERY_FAIL,
    DELIVERY_PENDING
} from '../constants/delivery'
import { OUT_FOR_DELIVERY_REMARK, FEDEX_PICKUP_SUCCESS } from "../constants/unloading";

import {
    branchName as branchNameValidator,
    branchShortName as shortNameValidator,
} from '../validators/branch'

import bookReducer from './booking'
import loadingReducer from './loading'
import unloadingReducer from './unloading'
import authReducer from './auth'
import memoReducer from './memo'

import {
    PAYMENT_UPDATE_REQ,
    TOGGLE_FRANCHISE,
    CHANGE_FRANCHISE_NO
} from '../constants/booking'
import {
    Customer_boolean,
    validationSchema,
    validate
} from './schemas/customer'

import {
    expense_Boolean,
    expenseValidationSchema,
    validateExpense,
    fuelExpenseValidation
} from './schemas/expense'

import { UPDATE_COMPANY_DOC } from '../constants/company'
import err from '../validators/err';
import { isArrayCheck } from '../containers/generics/CheckArray';
import Axios from 'axios';
import { procPackages } from '../containers/operations/loadutils/processPackages';
import { updateDocketMap } from '../containers/operations/loadutils/filterUtil';
import { fetchBranchesList } from '../services/branch'
import { SET_COMPANY_ROUTES } from '../constants/route';

export const success = '#42f462',
    failed = '#ef533e',
    info = '#37befc'

export const isBox = function (p) {
    if (p && p.docketBox && p.docketBox.hasOwnProperty("boxNumber")) {
        return true
    }
    return false
}
const hours = yup.string().required().matches(/^([0-1]\d|[2][0-3])$/,"invalid value hh")
const minutes = yup.string().required().matches(/^[0-5]\d$/,"Invalid value mm")

const branchSchemaFieldWise = fromJS({
    branchName: yup.string().required('this field is required'),
    shortName: yup.string('this field is required').required('this field is required').min(3, "too short").max(5, "too big"),
    working: {
        rohh: hours, romm: minutes, rchh: hours, rcmm: minutes,
        mohh: hours, momm: minutes, mchh: hours, mcmm: minutes,
        tohh: hours, tomm: minutes, tchh: hours, tcmm: minutes,
        wohh: hours, womm: minutes, wchh: hours, wcmm: minutes,
        thohh: hours, thomm: minutes, thchh: hours, thcmm: minutes,
        fohh: hours, fomm: minutes, fchh: hours, fcmm: minutes,
        saohh: hours, saomm: minutes, sachh: hours, sacmm: minutes,
        suohh: hours, suomm: minutes, suchh: hours, sucmm: minutes,
    },
    pContact: yup.string().required('Primary contact is required').matches(/^[\d]{10}$/ ,"Invalid Primary contact"),
    sContact: yup.string().matches(/^[\d]{10}$/ ,"Invalid secondary contact"),
    email: yup.string('Email is required').email("Invalid email"),
    paid: yup.boolean().required('booking.paid is required'),
    topay: yup.boolean().required('booking.topay is required'),
    credit: yup.boolean().required('booking.credit is required'),
    foc: yup.boolean().required('booking.foc is required'),
    dCredit: yup.boolean().required('booking.credit is required'),
    isBooking: yup.boolean().required('Booking field is required'),
    isDelivery: yup.boolean().required('Delivery field is required'),
    // isTr: yup.boolean().required('this field is required'),
    // trBy: '',
    // trArr: [],
    ownership: yup.string().required('Ownership info required').matches(/^(o|r)$/,"Invalid ownership info"),
    isHub: yup.boolean().required('Please Select hub field'),
    cams: yup.string("invalid Cam url").url("invalid Cam url"),
    l1: yup.string().required('L1 address is required'),
    l2: yup.string().required('L2 address is required'),
    pin: yup.string().required('this field is required').matches(/^[1-9][\d]{5}$/,"Invalid pincode"),

})
const initExpense = {
    type: 'add',
    sub: '',
    sub_val: '',
    supplier: '',
    suppliers: null,
    route: '',
    ref: '',
    amt: '',
    end_date: new Date(),
    start_date: new Date(),
    is_route: false,
    monthly_duration: false,
    tripDate: '',
    month: '',
    year: '',
    attachRoute: false,
    duration: true,
    assetType: '',
    assetBalance: '',
    non_cash: false,
    remarks: "",
    isFixedExpense: false,
}

const initBranchForm = fromJS({
    branchName: '',
    shortName: '',
    working: {
        rohh: '00', romm: '00', rchh: '00', rcmm: '00',
        mohh: '00', momm: '00', mchh: '00', mcmm: '00',
        tohh: '00', tomm: '00', tchh: '00', tcmm: '00',
        wohh: '00', womm: '00', wchh: '00', wcmm: '00',
        thohh: '00', thomm: '00', thchh: '00', thcmm: '00',
        fohh: '00', fomm: '00', fchh: '00', fcmm: '00',
        saohh: '00', saomm: '00', sachh: '00', sacmm: '00',
        suohh: '00', suomm: '00', suchh: '00', sucmm: '00',
    },
    pContact: '',
    sContact: '',
    email: '',
    paid: false,
    topay: false,
    credit: false,
    foc: false,
    dCredit: false,
    isBooking: false,
    isDelivery: false,
    ownership: 'o',
    isHub: false,
    cashBranch:false,
    manager:'',
    cams: [],
    l1: '',
    l2: '',
    pin: '',
    city: '',
    location:{
        latitude:'',
        longitude:''
    },
    rented: {
        rentAgreement: null,
        rentAmount: null,
        rentDate: null,
    },
    errors: {
        branchName: null,
        shortName: null,
        working: {
            rohh: null, romm: null, rchh: null, rcmm: null,
            mohh: null, momm: null, mchh: null, mcmm: null,
            tohh: null, tomm: null, tchh: null, tcmm: null,
            wohh: null, womm: null, wchh: null, wcmm: null,
            thohh: null, thomm: null, thchh: null, thcmm: null,
            fohh: null, fomm: null, fchh: null, fcmm: null,
            saohh: null, saomm: null, sachh: null, sacmm: null,
            suohh: null, suomm: null, suchh: null, sucmm: null,
        },
        pContact: null,
        sContact: null,
        email: null,
        paid: null,
        topay: null,
        credit: null,
        foc: null,
        dCredit: null,
        isBooking: null,
        isDelivery: null,
        ownership: null,
        isHub: null,
        cashBranch: null,
        manager: null,
        cams: [],
        l1: null,
        l2: null,
        pin: null,
        city: null,
        rented: {
            rentAgreement: null,
            rentAmount: null,
            rentDate: null,
        }
    }
})

const initCredit = {
    balance: "",
    company: "",
    customer: "",
    permissions: []
}

const days = [
    'mohh', 'momm', 'mchh', 'mcmm',
    'tohh', 'tomm', 'tchh', 'tcmm',
    'wohh', 'womm', 'wchh', 'wcmm',
    'thohh', 'thomm', 'thchh', 'thcmm',
    'fohh', 'fomm', 'fchh', 'fcmm',
    'saohh', 'saomm', 'sachh', 'sacmm',
    'suohh', 'suomm', 'suchh', 'sucmm'
]

const colReport = {
    bookings: [],
    expenses: [],
    deliveries: [],
    sumOfExpense: 0,
    sumOfGross: 0
}

const negotiationsSchema = {
    company: '',
    customer: '',
    route: '',
    material: '',
    negType: '',
    kgWise: [{
        weightMin: 10,
        weightMax: 10,
        weightPrice: 10
    }],
    flat: '',
    bids: [{
        kgWise: [{
            weightMin: '',
            weightMax: '',
            weightPrice: '',
        }],
        flat: '',
        by: '',
    }],
    status: {
        type: '',
        default: false,
    }
}

const initState = {
    expenseTypes: [],
    subExpenses: [],
    assetTypes: [],
    assetBalances: [],
    loginType: null,
    userName: null,
    socketLockAcquired: false,
    uid: null,
    token: null,
    contact: null,
    pinPoint: {},
    opBranch: {},
    opFleet: {},
    company: {
        id: null,
        name: null
    },
    bookingMode: {
        label: 'Paid',
        value: 'paid'
    },
    giveCommission: false,
    franchiseNumber: '',
    bookingUpdate: {
        packages: false,
        destCity: false,
        payment: false
    },
    branchForm: fromJS({
        branchName: '',
        shortName: '',
        working: {
            rohh: 0, romm: 0, rchh: 0, rcmm: 0,
            mohh: 0, momm: 0, mchh: 0, mcmm: 0,
            tohh: 0, tomm: 0, tchh: 0, tcmm: 0,
            wohh: 0, womm: 0, wchh: 0, wcmm: 0,
            thohh: 0, thomm: 0, thchh: 0, thcmm: 0,
            fohh: 0, fomm: 0, fchh: 0, fcmm: 0,
            saohh: 0, saomm: 0, sachh: 0, sacmm: 0,
            suohh: 0, suomm: 0, suchh: 0, sucmm: 0,
        },
        pContact: '',
        sContact: '',
        email: '',
        paid: false,
        topay: false,
        credit: false,
        foc: false,
        dCredit: false,
        isBooking: false,
        isDelivery: false,
        // isTr: false,
        // trBy: '',
        // trArr: [],
        ownership: 'o',
        // frBook: [],
        // frDel: [],
        isHub: false,
        cashBranch:false,
        cams: [],
        l1: '',
        l2: '',
        pin: '',
        city: '',
        rented: {
            rentAgreement: null,
            rentAmount: null,
            rentDate: null,
        },
        errors: {
            branchName: null,
            shortName: null,
            working: {
                rohh: null, romm: null, rchh: null, rcmm: null,
                mohh: null, momm: null, mchh: null, mcmm: null,
                tohh: null, tomm: null, tchh: null, tcmm: null,
                wohh: null, womm: null, wchh: null, wcmm: null,
                thohh: null, thomm: null, thchh: null, thcmm: null,
                fohh: null, fomm: null, fchh: null, fcmm: null,
                saohh: null, saomm: null, sachh: null, sacmm: null,
                suohh: null, suomm: null, suchh: null, sucmm: null,
            },
            pContact: null,
            sContact: null,
            email: null,
            paid: null,
            topay: null,
            credit: null,
            foc: null,
            dCredit: null,
            isBooking: null,
            isDelivery: null,
            // isTr: null,
            // trBy: null,
            // trArr: [],
            ownership: null,
            // frBook: [],
            // frDel: [],
            isHub: null,
            cashBranch:null,
            cams: [],
            l1: null,
            l2: null,
            pin: null,
            city: null,
            rented: {
                rentAgreement: null,
                rentAmount: null,
                rentDate: null,
            },
        }
    }),
    updateBuilty: false,
    goods: '',
    branches: null,
    branchMap: new Map(),
    rights: null,
    gross: null,
    routes: null,
    selectedRoute: null,
    selectedFuelRoute: null,
    receiptNo: null,
    allcities: null,
    allbranches: null,
    selectExpenseTypes: [],
    selectAssetBal: [],
    selectRoutes: [],
    fuel: {
        op: '',
        cl: '',
        ppl: '',
        places: [],
        new: '',
        rnum: '',
        driver: '',
        dName: '',
        remarks: '',
        from: '',
        fuelQty: '',
        to: '',
        selectedFuel: '',
        filterContact: '',
        filterVehicle: '',
        avgIdeal:'',
    },
    fuelErrors: fromJS({
        op: true,
        cl: true,
        ppl: true,
        rnum: true,
        driver: true,
        dName: true,
        remarks: true,
        fuelQty: true,
        remarks: true,
        avgIdeal:true,
    }),
    assign: {
        branches: null,
        departments: null,
        fleets: null
    },
    ppf: false,
    kgw: false,
    disableOpKms: false,
    fr_ppf: false,
    balanceDialog: false,
    fr_kgw: false,
    editBranchId: null,
    suggPrice: null,
    routeSugg: null,
    originalCreditDueList: [],
    creditDocketMap: new Map(),
    invoiceDetails: [],
    invoiceParty: new Map(),
    modalLoader: false,
    customer: immutableMap({
        _id: '',
        name: '',
        contact: '',
        GSTIN: '',
        connected: [],
        address: {
            l1: '',
            l2: '',
            city: '',
            pincode: ''
        }
    }),
    customerList: [],
    customer_errors: fromJS({ ...Customer_boolean(true) }),
    customer_touched: fromJS({ ...Customer_boolean(false) }),
    bookingCredit: {},
    credit: fromJS({ ...initCredit }),
    newCredit: fromJS({
        customer: "",
        company: "",
        balance: "",
        branches: [],
        user: "",
    }),
    docketMapRegNumWise: {},
    unloadingBranches: [],
    unloadingCities: [],
    negotiationsArr: [],
    printExpenseVoucher: {},
    negotiations: negotiationsSchema,
    fr: 0,
    allBranchBalance: '',
    totalBalance: '',
    bookroute: '',
    editDocket: false,
    bf: 0,
    pickupCharge: 0,
    deliveryCharge: 0,
    hf: 0,
    ewb: '',
    bill: '',
    resetPackageErrors: false,
    deliveryType: {value: "Branch To Branch",label:"Branch To Branch"},
    bookdestcity: null,
    bookdestbranch: null,
    gross: 0,
    packages: [{
        id: 0,
        qty: '',
        materialType: '',
        packingType: '',
        size: { label: 'custom', value: 'custom' },
        dimension: { l: '', b: '', h: '' },
        weight: '',
        rate: { value: 'Direct', label: 'Direct' },
        unit: '',
        amount: '',
        stack: false,
        haz: false,
        frag: false
    }],
    risk: 0,
    isCol: true,
    howcollect: '',
    col: '',
    voucherPrint: false,
    colMonth: 0,
    customPack: 'null',
    bookingRate: 'Direct',
    purpose: { label: 'Commercial', value: 'C' },
    // credit: initCredit,
    docketMap: null,
    docketReRenderMap: immutableMap({}),
    loadcity: null,
    loadroute: null,
    loadbranch: null,
    fedexPickupNumber: null,
    unloadDestBranch: '',
    unloadDestCity: '',
    loaddocket: '',
    loadVehicle: null,
    twoWayLoad: false,
    assignroute: '',
    bookdestbranch: '',
    docketNumber: '',
    sender: {
        name: '',
        contact: '',
        gst: '',
        l1: '',
        l2: '',
        pin: '',
        city: '',
        otherAddr: []
    },
    receiver: {
        name: '',
        contact: '',
        gst: '',
        l1: '',
        l2: '',
        pin: '',
        city: '',
        otherAddr: []
    },
    receiverBranchAddress: {},
    creditNumber: '',
    expense: initExpense,
    expenseErrors: { ...expense_Boolean(true) },
    expenseTouched: { ...expense_Boolean(false) },
    colReport,
    remainingLimit: 0,
    loading: false,
    loaderAcrossAmount: false,
    loadingWithOutBlur: false,
    ewayBillLoading: false,
    gstLoading: false,
    delAmt: '',
    creditNumberLoading: false,
    gstLoadingReceiver: false,
    showAttachmentOptions: false,
    bookErrors: immutableMap(),
    bookTouches: immutableMap(),
    specificDelivery: false,
    memo: {},
    receiverGst: false,
    senderGst: false,
    notification:[]
}

export const mockState = initState

export function showMessage(msg, color, duration = 2000) {
    var x = document.getElementById("snackbar")
    if (!x) return
    x.innerHTML = msg
    x.style.backgroundColor = color
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, duration)
}

export const getDocketNumber = (order, splitted) => {
    let prefix = order.replace(/\d/g, '')
    console.log('prefix : ', prefix)
    let suffix = order.replace(/\D/g, '')
    console.log('suffix : ', suffix)
    let preSuffix = suffix.substring(0, 10)
    if (splitted) {
        splitted.prefix = prefix
        splitted.suffix = preSuffix
    }
    return prefix + preSuffix
}

const makeDocketMapRegNumWise = (arr, fleets) => {
    let docketMap = {}
    arr.forEach(pack => {
        let curr = pack.regNumber
        if (fleets.map(f => f.regNumber).includes(curr)) {
            if (!(curr in docketMap)) {
                docketMap[curr] = [{ ...pack }]
            }
            else {
                docketMap[curr].push({ ...pack })
            }
        }
    })
    console.log('prepared docket map : ', docketMap)
    let newDocketMap = {}
    Object.keys(docketMap).forEach(d => {
        newDocketMap[d] = {}
        let currDocs = docketMap[d]
        for (let i = 0; i < currDocs.length; i++) {

            let order, currDocket, isBox

            order = currDocs[i].order
            currDocket = getDocketNumber(order)
            isBox = false

            if (!(currDocket in newDocketMap[d])) {
                newDocketMap[d][currDocket] = [{ ...currDocs[i] }]
            }
            else {
                newDocketMap[d][currDocket].push({ ...currDocs[i] })
            }
        }
    })

    return newDocketMap
}

const procWorking = (working) => {
    let { regular, custom } = working
    let result = {}
    let days = [
        { front: 'm', name: 'mon' },
        { front: 't', name: 'tue' },
        { front: 'w', name: 'wed' },
        { front: 'th', name: 'thu' },
        { front: 'f', name: 'fri' },
        { front: 'sa', name: 'sat' },
        { front: 'su', name: 'sun' }
    ]
    let dayMap = {
        'mon': 'm',
        'tue': 't',
        'wed': 'w',
        'thu': 'th',
        'fri': 'f',
        'sat': 'sa',
        'sun': 'su'
    }
    let rohh = parseInt(regular.regStart / 100, 10),
        romm = regular.regStart - rohh * 100,
        rchh = parseInt(regular.regEnd / 100, 10),
        rcmm = regular.regEnd - rchh * 100
    result['rohh'] = rohh
    result['romm'] = romm
    result['rchh'] = rchh
    result['rcmm'] = rcmm

    days.forEach(day => {
        result[day.front + 'ohh'] = rohh
        result[day.front + 'omm'] = romm
        result[day.front + 'chh'] = rchh
        result[day.front + 'cmm'] = rcmm
    })

    custom.forEach(day => {
        console.log('cus start : ', day.cusStart,
            '\ncus end : ', day.cusEnd)

        let ohh = parseInt(day.cusStart / 100, 10),
            omm = day.cusStart - ohh * 100,
            chh = parseInt(day.cusEnd / 100, 10),
            cmm = day.cusEnd - chh * 100
        result[dayMap[day['cusDay']] + 'ohh'] = ohh
        result[dayMap[day['cusDay']] + 'omm'] = omm
        result[dayMap[day['cusDay']] + 'chh'] = chh
        result[dayMap[day['cusDay']] + 'cmm'] = cmm
    })

    return result
}

const reducer = (state = initState, action) => {

    if (action.type.includes('UNLOADING:')) {
        return unloadingReducer(state, action)
    }

    if (action.type.includes('LOADING:')) {
        return loadingReducer(state, action)
    }

    if (action.type.includes('BOOK:')) {
        return bookReducer(state, action)
    }

    if (action.type.includes('AUTH:')) {
        return authReducer(state, action)
    }

    if (action.type.includes('MEMO:')) {
        return memoReducer(state, action)
    }

    switch (action.type) {
        //universal functionality
        case GET_ROUTES: {
            return {
                ...state,
                routes: action.payload
            }
        }
        case 'SET_NOTIFICATION_FULFILLED':{
            console.log('SET_NOTIFICATION_FULFILLED')
            console.log('notification res',action.payload)
            return{
                ...state,
                notification:action.payload
            }
        }
        case 'UPDATE_BALANCE': {
            if (isNaN(action.payload)) {
                return state
            }
            let { options } = action
            let {
                loginType,
                opBranch,
                opFleet,
                allBalances
            } = state
            if (options && options.sub && options.entity) {
                return {
                    ...state,
                    allBalances: allBalances.map(b => b._id == options.entity ? ({ ...b, balance: options.balance || (action.payload + (options.add ? b.balance : 0)) }) : b)
                }
            }
            if (loginType == 'B') {
                console.log("login type branch:", opBranch._id);

                let allBalancesUpdated = allBalances.map((balanc) => {
                    console.log("balance:", balanc);

                    if (balanc && balanc._id && balanc._id.length == 24 && balanc._id == opBranch._id) {
                        return {
                            ...balanc, balance: action.payload
                        }
                    }
                    return { ...balanc }
                })
                return { ...state, allBalances: allBalancesUpdated }
            }
            if (loginType == 'F') {
                let allBalancesUpdated = allBalances.map((balanc) => {
                    if (balanc && balanc._id && balanc._id.length == 24 && balanc._id == opFleet._id) {
                        return {
                            ...balanc, balance: action.payload
                        }
                    }
                    return { ...balanc }
                })
                return { ...state, allBalances: allBalancesUpdated }
            }
            return {
                ...state
            }
        }
        case 'UPDATE_INCENTIVE': {
            return {
                ...state,
                incentive: action.payload
            }
        }
        case 'SEL_OP_BRANCH': {
            try {
                if (!state.branches) return state
                let { value: id } = action.payload,
                    opBranch = null
                state.branches.forEach(br => {
                    if (br._id === id) {
                        opBranch = br
                    }
                })
                showMessage('Operating Branch Set : ' + opBranch.branchName, success)
                if (global.utils && global.utils.setupData) {
                    console.log('select branch utils : ', global.utils)
                    setTimeout(global.utils.setupData, 500)
                }
                return {
                    ...state,
                    opBranch: opBranch,
                    loginType: 'B'
                }
            }
            catch (err) {
                console.log(err)
                return state
            }
        }
        case 'SEL_OP_FLEET': {
            try {
                let opFleet
                state.fleets.forEach(fl => {
                    if (fl._id == action.payload.value) {
                        opFleet = fl
                    }
                })
                if (global.utils && global.utils.setupData) {
                    setTimeout(global.utils.setupData, 500)
                }
                showMessage('Operating Fleet Set : ' + opFleet.regNumber, success)
                return {
                    ...state,
                    opFleet,
                    loginType: 'F'
                }
            }
            catch (err) {
                console.log(err)
                return state
            }
        }
        case 'SET_MATERIAL': {
            const { bookingUpdate } = state
            return {
                ...state,
                material: action.payload,
                bookingUpdate: {
                    ...bookingUpdate,
                    packages: true
                }
            }
        }
        case 'SET_PACKING_TYPE': {
            const { bookingUpdate } = state
            return {
                ...state,
                packingType: action.payload,
                bookingUpdate: {
                    ...bookingUpdate,
                    packages: true
                }
            }
        }
        case "CREATE_BRANCH_FULFILLED": {
            const status = action.payload.data.status
            if (status !== 1) {
                showMessage("Something went wrong!", '#ef533e')
            }
            showMessage("Branch created successfully!", '#42f462')
            return state
        }
        //login functionality
        case "LOGIN_FULFILLED": {
            toast.success('Successfully signed-in!')
            console.log('payload is ', action.payload)
            sessionStorage.setItem('kabra:token', action.payload.data.accessToken)
            sessionStorage.setItem('kabra:user', action.payload.data.response.user._id)
            const status = action.payload.data.status
            if (status !== 1) {
                showMessage("Invalid mobile or password!", '#ef533e')
                return state
            }
            const { role } = action.payload.data
            const data = action.payload.data.response
            return {
                ...state,
                role,
                userName: data.user.name.fName,
                uid: data.user._id,
                contact: data.user.contact,
                token: '5b0ce34h6j',
                // rights: dep,
                branches: data.user.branches,
                fleets: data.user.fleets,
                company: {
                    id: data.user.company._id,
                    name: data.user.company.companyName,
                    sizes: data.user.company.sizes,
                    ...data.user.company
                }
            }
        }
        //logout functionality
        case "LOG_OUT": {
            sessionStorage.clear()
            return initState
        }
        case 'LOGIN_FAIL': {
            showMessage(action.payload || 'Invalid credentials!', failed)
            return state
        }
        case "SET_CITIES_FULFILLED": {
            let cities = action.payload.data.response
            console.log(cities,'all city')
            let citiesMap = new Map()
            if (isArrayCheck(cities)) {
                cities.forEach(c => {
                    if (!citiesMap.has(c._id)) {
                        citiesMap.set(c._id, c)
                    }
                })
            }
            return {
                ...state,
                allcities: cities,
                citiesMap
            }
        }
        case "SET_BRANCHES_FULFILLED": {
            if (action.payload.data.status !== 1) {
                return state
            }
            let branches = action.payload.data.response
            let branchMap = new Map()
            for (let i = 0; i < branches.length; i++) {
                if (branchMap.has(branches[i]._id)) {
                    continue
                }
                else {
                    branchMap.set(branches[i]._id, branches[i])
                }
            }
            return {
                ...state,
                allbranches: branches,
                branchMap: branchMap

            }
        }
        case 'SET_DEPS_FULFILLED': {
            if (action.payload.data.status !== 1) {
                return state
            }
            let departments = action.payload.data.response
            return {
                ...state,
                alldeps: departments
            }
        }
        //booking module
        case 'BOOK_DOCKET':
        case 'PICKUP_CHARGE_CHANGE':
        case 'BOOK_EWB':
        case 'BOOK_SENDER':
        case 'BOOK_RECEIVER':
        case 'SET_DOCKET':
        case 'SET_BUILTY_CONCERNED':
        case 'BOOK_COL_MONTH':
        case 'BOOK_RATE':
        case 'BOOK_PACK':
        case 'BOOK_HOW_COL':
        case 'BOOK_COL_TOGGLE':
        case 'BOOK_RISK_CHANGE':
        case 'BOOK_GOODS_CHANGE':
        case 'BOOK_INS_CHANGE':
        case 'BOOK_COL_CHANGE':
        case 'BOOKING_EDIT_PACKAGE':
        case 'BOOK_ADD_PACKAGE':
        case 'BOOK_DEL_PACKAGE':
        case 'SET_ACROSS':
        case 'BOOK_FR_CHANGE':
        case 'FILTER_ROUTES_BY_BRANCH':
        case 'SUGG_PRICE':
        case 'SUGG_BOOK_ROUTES':
        case 'SUGG_BOOK_RATE':
        case 'ROUTE_RATE':
        case 'GEN_BUILTY':
        case 'SET_LR':
        case 'SET_ORANGE_LR':
        case 'RESET_BOOK':
        case 'SET_BOOK':
        case 'BOOK_BRANCHES':
        case 'BOOK_CHANGE_INS':
        case 'BOOK_CHANGE_DELIVERY_TYPE':
        case 'BOOK_CHANGE_FEDEX_SERVICE_TYPE':
        case 'SET_FEDEX_SERVICE_OPTIONS':
        case 'BOOK_DEST_CITY':
        case 'BOOK_DEST_BRANCH':
        case "BOOK_DEST_ROUTE":
        case 'RESET_EDIT':
        case 'BOOK_PAY_MODE':
        case 'CREDIT_NUM_CHANGE':
        case 'BOOK_CREDIT_SET':
        case 'BOOK_CREDIT_RESET':
        case 'CHANGE_TO_CREDIT_SUCCESS':
        case 'DELIVERED_AT_BRANCH':
        case 'BOOKING_REFRESH':
        case 'BOOK_PURPOSE':
        case 'BOOK_BILL':
        case 'BOOK_SET_SENDER':
        case 'SET_RECEIVER_SUGGESTIONS':
        case 'SET_PACKAGE_SUGGESTIONS':
        case 'SET_RATE_SUGGESTIONS':
        case 'BOOK_SET_RECEIVER':
        case 'SPECIFIC_DELIVERY':
        case 'RESET_RECEIVER_NAME':
        case 'RESET_SENDER_NAME':
            {
                return bookReducer(state, action)
            }
        case 'SET_DEL_DOCKET': {
            return { ...state, delDocket: action.payload }
        }
        case 'LOAD_SET_PACKAGES': {

            let { concern } = action
            if (concern && concern.length > 0 && !window.location.pathname.includes(concern)) {
                return state
            }

            const {
                validCities,
                validBranches,
                docketMap: docketMap,
                boxOptions
            } = action.payload
            return {
                ...state,
                boxOptions,
                validCities,
                validBranches,
                docketMap: docketMap,
                fetchLoadPending: false,
                fetchLoadError: null
            }
        }
        case 'SELECT_PACKAGE': {
            try {
                let {
                    order,
                    docket
                } = action.payload
                return {
                    ...state,
                    docketMap: updateDocketMap({ ...state.docketMap }, (p) => p.order === order, (p) => ({ selected: !p.selected })),
                    docketReRenderMap: state.docketReRenderMap.set(docket, true)
                }
            }
            catch (err) {
                console.log(err)
                return state
            }
        }
        case 'BULK_SELECT_PACKAGE': {
            try {
                let { docket } = action
                let docketMap = Object.assign({}, state.docketMap)
                console.log('docketMap: ', docketMap);
                let allSelected = true
                let packageMap = { ...docketMap[docket] }
                Object.keys(packageMap).forEach(uid => {
                    let packages = packageMap[uid]
                    packages.forEach(pkg => {
                        allSelected = allSelected && pkg.selected
                    })
                })

                if (allSelected) {
                    //user wants to deselect all packages
                    Object.keys(packageMap).forEach(uid => {
                        let packages = packageMap[uid].map(pkg => { return { ...pkg, selected: false } })
                        packageMap[uid] = packages
                    })
                } else {
                    Object.keys(packageMap).forEach(uid => {
                        let packages = packageMap[uid].map(pkg => { return { ...pkg, selected: true } })
                        packageMap[uid] = packages
                    })
                }
                docketMap[docket] = packageMap
                return {
                    ...state,
                    docketMap,
                    docketReRenderMap: state.docketReRenderMap.set(docket, true)
                }
            }
            catch (err) {
                console.log(err)
                return state
            }
        }
        case 'LOAD_ROUTE_SET': {
            return {
                ...state,
                loadroutes: action.payload,
                loadroute: {}
            }
        }
        case 'LOAD_ROUTE_FILTER': {
            return { ...state, loadroute: action.payload }
        }
        case 'LOAD_BRANCH_FILTER': {
            const filter = (pck, criteria) => {
                let {
                    city,
                    route,
                    branch,
                    deliverableFilter,
                    unverifiedFilter
                } = criteria
                console.log('got criteria : ', criteria, '\npck : ', pck)

                if (typeof city.value === 'string' && city.value !== pck.destCity) return false
                if (typeof route.value === 'string' && route.value !== pck.route) return false
                if (typeof branch.value === 'string' && branch.value !== pck.destBranch) return false
                if (deliverableFilter === true && pck.deliverable != true) return false
                if (unverifiedFilter === true && pck.verified != true) return false

                return true
            }

            let criteria = {
                city: state.loadcity,
                route: state.loadroute,
                branch: action.payload,
                docket: state.loaddocket
            }

            let { docketMap } = state
            let filteredPackages = Object.values(docketMap)
                .map(packages => packages.filter(p => filter(p, criteria)).length)
                .reduce((acc, i) => acc + i, 0)

            console.log('filtered: ', filteredPackages)
            return {
                ...state,
                filteredPackages,
                fetchLoadPending: false
            }
        }
        case 'LOAD_DOCKET_FILTER': {
            return { ...state, loaddocket: action.payload }
        }
        case 'LOAD_ASS_ROUTE_SET': {
            return { ...state, assignroute: action.payload }
        }
        case 'NEXT_PACKAGE_ADD': {
            let { act, docket } = action.payload
            switch (act) {
                case '+': {
                    let docketMap = Object.assign({}, state.docketMap)
                    let prevPackageMap = docketMap[docket]
                    let flag = true
                    Object.keys(prevPackageMap).forEach(uid => {
                        if (flag === true) {
                            let tempPackages = []
                            prevPackageMap[uid].forEach(p => {
                                if (flag === true && !p.selected) {
                                    p.selected = true
                                    flag = false
                                }
                                tempPackages.push(p)
                            })
                            prevPackageMap[uid] = tempPackages
                        }
                    })
                    docketMap[docket] = prevPackageMap
                    return {
                        ...state,
                        docketMap: docketMap,
                        docketReRenderMap: state.docketReRenderMap.set(docket, true)
                    }
                }
                case '-': {
                    let docketMap = Object.assign({}, state.docketMap)
                    let prevPackageMap = docketMap[docket]
                    let flag = true
                    Object.keys(prevPackageMap).forEach(uid => {
                        if (flag === true) {
                            let tempPackages = []
                            prevPackageMap[uid].forEach(p => {
                                if (flag === true && p.selected) {
                                    p.selected = false
                                    flag = false
                                }
                                tempPackages.push(p)
                            })
                            prevPackageMap[uid] = tempPackages
                        }
                    })
                    docketMap[docket] = prevPackageMap
                    return {
                        ...state,
                        docketMap: docketMap,
                        docketReRenderMap: state.docketReRenderMap.set(docket, true)
                    }
                }
            }
        }
        case 'NEXT_PACKAGE_ADD_INSIDE_PACKAGE': {
            let { act, uid, docket } = action.payload
            const makeUID = p => {
                const {
                    materialType,
                    packingType,
                    dimension,
                    weight,
                    rate,
                    amount,
                    stack,
                    haz,
                    frag
                } = p, { l, b, h } = dimension
                let uniqueId = [
                    materialType._id,
                    packingType._id,
                    l, b, h,
                    weight,
                    rate,
                    amount,
                    stack,
                    haz,
                    frag
                ]
                return uniqueId.join('@')
            }
            switch (act) {
                case '+': {
                    return {
                        ...state,
                        docketMap: updateDocketMap({ ...state.docketMap },
                            p => p.selected !== true && makeUID(p) == uid,
                            { selected: true },
                            { onlyOne: true }),
                        docketReRenderMap: state.docketReRenderMap.set(docket, true)
                    }
                }
                case '-': {
                    return {
                        ...state,
                        docketMap: updateDocketMap({ ...state.docketMap },
                            p => p.selected !== false && makeUID(p) == uid,
                            { selected: false },
                            { onlyOne: true }),
                        docketReRenderMap: state.docketReRenderMap.set(docket, true)
                    }
                }
            }
            return state
        }
        case 'CLEAR_DOCKET_MAP': {
            return {
                ...state,
                docketMap: null,
                loadbranch: null,
                loadcity: null,
                loadroute: null,
                loadFilters: {}
            }
        }
        case 'SELECT_LOAD_VEHICLE': {
            return { ...state, loadVehicle: action.payload }
        }
        case 'USER_ALL_BALANCES': {
            const { balances, branches, fleets } = action.payload
            let { company } = state 
            console.log('action.payload: ', action.payload);
            let allBranchBalance, totalBalance = 0
            if (isArrayCheck(balances)) {
                allBranchBalance = balances.filter(b => ( (b.company && company && company._id && company._id.length == 24) ? b.company == company._id : true)).map(b => {
                    totalBalance += b.balance
                    if (b.sub == 'B') {
                        let branch = branches.get(b.entity)
                        return {
                            balance: b.balance,
                            entity: branch && branch.branchName ?
                                branch.branchName : 'NA',
                            _id: b.entity
                        }
                    }
                    else if (isArrayCheck(fleets) && b.sub == 'F') {
                        let fleet = fleets.find(f => f._id == b.entity)
                        console.log('fleet: ', fleet);
                        return {
                            balance: b.balance,
                            entity: fleet && fleet.regNumber ?
                                fleet.regNumber : 'NA',
                            _id: b.entity
                        }
                    }else{
                        return {
                            balance: b.balance,
                            entity: 'NA',
                            _id: b.entity
                        }
                    }
                })//.filter(b => b && b.entity != null && b.entity != undefined)
            }
            return {
                ...state,
                allBalances: allBranchBalance
            }
            
        }
        case 'USER_ALL_BALANCE_UPDATE_FROM_SERVER_EVENT' : {
            const balances = action.payload
            console.log("got payload--------------",action);
            
            const allBalances = state.allBalances 
            return {
                ...state,
                allBalances: allBalances.map(oldBalance => {
                    let entity = balances.find(newBalance => (newBalance.entity == oldBalance._id))
                    if(entity){
                        console.log("got entity:--",entity);
                        console.log("oldB:---",oldBalance);
                        oldBalance.balance = entity.balance 
                    }
                    return oldBalance
                })
            }
        }
        case 'UNLOAD_SUCCESS': {
            toast.success("Packages unloaded successfully!")
            let docketMap = Object.assign({}, state.docketMap)
            let criteria = p => p.selected !== true
            return {
                ...state,
                docketMap: updateDocketMap(docketMap, criteria, null, {
                    filterData: true
                }),
                unload_api: false
            }
        }
        case 'FEDEX_PICKUP_SUCCESS' : {
            console.log("fedex pickup success");
            
            // const { fedexPickupNumber, packages } = action.payload
            // toast.success("Pickup Number generated successfully")
            // let docketMap = Object.assign({}, state.docketMap)
            // const criteria = pck => {
            //     return (packages.find(pck2 => (pck2 == pck._id)) != undefined) ? true : false 
            // }
            // const updateDoc = (pck) => ({
            //             ...pck,
            //             fedexPickupNumber : fedexPickupNumber
            // })
            

            // return {
            //     ...state,
            //     docketMap: updateDocketMap(docketMap, criteria, updateDoc, {
            //         filterData: true
            //     }),
            //     fedexPickupNumber : fedexPickupNumber
            // }
            const { fedexPickupNumber, packages } = action.payload
            toast.success("Pickup Number generated successfully")
            let docketMap = Object.assign({}, state.docketMap)
            let criteria = p => p.selected !== true
            return {
                ...state,
                docketMap: updateDocketMap(docketMap, criteria, null, {
                    filterData: true
                }),
                fedexPickupNumber : fedexPickupNumber
            }
        }
        case 'SHOW_BALANCE_DIALOG': {
            return {
                ...state,
                balanceDialog: true
            }
        }
        case 'HIDE_BALANCE_DIALOG': {
            return {
                ...state,
                balanceDialog: false
            }
        }
        case 'SET_UN_PACKAGES': {
            let { concern } = action
            if (concern && concern.length > 0 && !window.location.pathname.includes(concern)) {
                return state
            }
            const {
                validCities: unloadingCities,
                validBranches: unloadingBranches,
                docketMap
            } = action.payload
            return {
                ...state,
                unloadingCities: unloadingCities.map(c => ({
                    label: c.name,
                    value: c._id
                })),
                unloadingBranches: unloadingBranches.map(b => ({
                    label: b.branchName,
                    value: b._id,
                    city: b.address.city._id
                })),
                docketMap,
                fetchLoadError: null
            }
        }
        case 'REFRESH_UNLOAD_PACKAGES': {
            return {
                ...state,
                unloadDestBranch: null,
                unloadDestCity: null,
                docketMap: {},
                loaddocket: '',
                unloadingBranches: [],
                unloadingCities: [],
            }
        }
        case 'OUT_FOR_DELIVERY_PACKAGES_REGNUMWISE': {
            let { packages: arr, fleets } = action.payload
            if (!arr || arr.length === 0) return {
                ...state,
                docketMapRegNumWise: null,
            }
            return {
                ...state,
                docketMapRegNumWise: makeDocketMapRegNumWise(arr, fleets)
            }
        }
        case PRINT_EXPENSE_VOUCHER: {
            
            return {
                ...state,
                printExpenseVoucher: action.payload
            }
        }
        case 'SET_UNLOAD_VEHICLES': {
            return {
                ...state,
                suggvehicles: action.payload,
                fetchingUnload: false
            }
        }
        case 'UNLOAD_PENDING': {
            return { ...state, fetchingUnload: true }
        }
        //customer module
        case 'SET_CUST': {
            let { credit, customer } = action.payload
            console.log('customer: ', customer);
            console.log('credit: ', credit);
            const { allbranches } = state

            let customerDoc = Object.assign({}, customer)
            customerDoc.connected = Array.isArray(customer.connected) && customer.connected.length > 0 ?
                customer.connected.map(c => ({ label: c.contact, value: c._id })) : []
            customer = state.customer.merge(customerDoc)

            if (credit !== undefined) {
                let newCredit = { ...credit.credit }
                newCredit['permissions'] = credit.branch_permissions.map(o => {
                    let branch = allbranches.find(b => b._id == o.branch)
                    if (branch)
                        return {
                            label: branch.branchName,
                            value: o.branch
                        }
                }).filter(cp => cp != null && cp != undefined)
                credit = state.credit.merge(newCredit)
            }
            else {
                credit = fromJS({ ...initCredit })
            }

            return {
                ...state,
                customer,
                credit
            }
        }
        case FETCH_DUE_CREDIT: {
            let creditDetails = action.payload.creditDetails.slice()
            let docketDetails = action.payload.docketDetails.slice()
            let branchMap = new Map(docketDetails.map(d => ([d.branchBooked._id, d.branchBooked])))
            console.log('got branch map : ', branchMap)
            if (Array.isArray(creditDetails) && creditDetails.length > 0) {
                creditDetails.forEach(c => {
                    let branch = branchMap.get(c.branch)
                    let d = new Date(c.created_at)
                    c.branch = branch ? branch.branchName : 'N/A'
                    c.created_at = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
                })
            }
            let creditMap = new Map(creditDetails.map(c => ([c.docket, c])))
            console.log('credit map : ', creditMap)
            let docketMap = new Map(docketDetails.map(d => {
                let totalAmount = parseInt(creditMap.get(d._id).amt, 10)
                console.log('total amount : ', totalAmount)
                let packagesAmount = d.packages.reduce((acc, i) => acc + parseInt(i.packageDoc.amount, 10), 0)
                console.log('packages amount : ', packagesAmount)
                console.log('docket test : ', d, ' pickle : ', creditMap.get(d._id).amt, '\nparse : ',
                    parseInt(creditMap.get(d._id).amt, 10))
                return ([d._id, {
                    ...d,
                    packages: d.packages.map(p => ({
                        ...p.packageDoc,
                        ...p.dimension,
                        received: p.packageDoc.amount
                    })),
                    fixed: parseInt(creditMap.get(d._id).amt, 10) - d.packages.reduce((acc, i) => acc + parseInt(i.packageDoc.amount, 10), 0)
                }
                ])
            }))
            console.log('docket map : ', docketMap)

            let data = []
            for (let creditItem of creditMap) {
                console.log('got credit item : ', creditItem)
                const docket = docketMap.get(creditItem[0])
                if (docket && docket.packages) {
                    const totalDue = parseInt(creditItem.amt, 10)
                    const packagesAmount = docket.packages.reduce((acc, i) => acc + parseInt(i.amount, 10), 0)
                    console.log('got docket : ', docket)
                    data.push({
                        ...creditItem[1],
                        ...docketMap.get(creditItem[0]),
                        fixed: totalDue - packagesAmount
                    })
                }
            }
            console.log('map data', data);
            return {
                ...state,
                originalCreditDueList: data,
                creditDocketMap: docketMap
            }
        }
        case CHANGE_PACKAGE_AMOUNT_SETTLEMENT: {
            const { row, val } = action.payload
            const { docket, received: amount, material, packing, dimension } = row
            console.log('Recuderrow: ', row, val);
            console.log('dimension: ', dimension);
            const makeUID = (p) => [p.material, p.packing, p.dimension].join('@')
            const uid = makeUID({ material, packing, dimension })
            let { creditDocketMap } = state
            let temp
            console.log('creditDocketMap: ', creditDocketMap);
            let flag = true
            if (creditDocketMap.has(docket)) {
                let { packages } = creditDocketMap.get(docket)
                temp = packages.map(p => {
                    console.log('paclkage: ', p);
                    let new_uid = [p.materialType.name, p.packingType.name, Object.keys(p.dimension).map(k => p.dimension[k])].join('@')
                    console.log('new uid: ', new_uid);
                    console.log('uid: ', uid);
                    if (new_uid == uid) {
                        if (parseInt(val, 10) > parseInt(p.amount, 10)) {
                            flag = false
                        }
                        console.log('i aM HERE', { ...p }, val);
                        return { ...p, received: val }
                    }
                    else return p
                })
                creditDocketMap.set(docket, { ...creditDocketMap.get(docket), packages: temp })
            }
            if (!flag) {
                return state
            }
            return {
                ...state,
                creditDocketMap: creditDocketMap
            }
        }
        case GET_INVOICE_DETAILS: {
            const { records, response } = action.payload
            console.log('records: ', records);
            console.log('response: ', response);
            let recordsMap = new Map(Array.isArray(records) ? records.map(r => ([r._id, r])) : ([]))
            console.log('recordsMap: ', recordsMap);
            return {
                ...state,
                invoiceDetails: response,
                invoiceParty: recordsMap
            }
        }
        case CLEAR_RECEIPT_NUMBER: {
            return {
                ...state,
                receiptNo: null
            }
        }
        case TOGGLE_CREDIT_ROW: {
            const { originalCreditDueList } = state
            const id = action.payload
            const creditList = originalCreditDueList.map((row) => row.id == id ? ({ ...row, selected: !row.selected }) : row)
            return {
                ...state,
                originalCreditDueList: creditList
            }
        }
        case 'CLEAR_CUST': {
            return {
                ...state,
                customer: immutableMap({
                    name: '',
                    contact: '',
                    GSTIN: '',
                    connected: [],
                    address: {
                        l1: '',
                        l2: '',
                        city: '',
                        pincode: ''
                    }
                }),
                credit: fromJS({ ...initCredit }),
                customer_errors: fromJS({ ...Customer_boolean(true) }),
                customer_touched: fromJS({ ...Customer_boolean(false) }),
            }
        }
        case CREDIT_FIELD_CHANGE: {
            let { what, val } = action.payload
            return {
                ...state,
                newCredit: state.newCredit.setIn(what, val)
            }
        }
        case NEW_CREDIT_SUBMIT: {
            showMessage('Successfull !', success)
            return state
        }
        case GET_CUSTOMER_LIST: {
            console.log('action.payload:', action.payload);
            return {
                ...state,
                customerList: action.payload
            }
        }
        case CHANGE_CONNECTED_CUSTOMERS: {
            console.log('action.payload', action.payload);
            return {
                ...state,
                connected: state.customer.set('connected', action.payload)
            }
        }
        case CHANGE_FIXED: {

            const { docket, value } = action.payload

            const creditDocketMap = new Map(state.creditDocketMap)

            if (creditDocketMap.has(docket)) {
                const fixed = creditDocketMap.get(docket)
                if ((!isNaN(value) && parseInt(value) > fixed)) {
                    return state
                }
                creditDocketMap.set(docket, { ...creditDocketMap.get(docket), fixSettled: value })
            }
            return {
                ...state,
                creditDocketMap
            }
        }
        case CHANGE_PERMITTED_BRANCH: {
            console.log('action.payload', action.payload);
            return {
                ...state,
                credit: state.credit.set('permissions', action.payload)
            }
        }
        case SETTLE_DUES_SUCCESS: {
            showMessage('Credit Is Settled', success)
            console.log('action.payload', action.payload);
            const receiptNo = action.payload.receiptNo
            return {
                ...state,
                receiptNo: receiptNo,
                printCredit: true
            }
        }
        case CLOSE_CREDIT_PRINT: {
            return {
                ...state,
                printCredit: false
            }
        }
        case SETTLE_DUES_FAILED: {
            showMessage(action.payload[0], action.payload[1])
            return state
        }
        case 'CHANGE_CUST_DET': {
            let { what, val } = action.payload
            console.log('val: ', val);
            console.log('what: ', what);
            return {
                ...state,
                customer: state.customer.setIn(what, val),
                customer_errors: state.customer_errors.setIn(what, validate(validationSchema.getIn(what), val)),
                customer_touched: state.customer_touched.setIn(what, true)
            }
        }

        case 'CREATED_CUSTOMER': {
            showMessage("Customer Created Successfully!", success)
            return {
                ...state,
            }
        }

        case 'EDITED_CUSTOMER': {
            showMessage("Customer Edited!", success)
            return state
            // return {
            //     ...state,
            //     customer: fromJS(action.payload)
            // }
        }

        //delivery module
        case 'DELIVERY_SET': {
            return { ...state, delDocket: action.payload }
        }
        case 'DEL_MODE': {
            let delDocket = Object.assign({}, state.delDocket)
            delDocket.docket.paymentMode = action.payload
            return { ...state, delDocket: delDocket }
        }
        case 'CLEAR_REGNUM_WISE_DOCKET': {
            return {
                ...state,
                docketMapRegNumWise: {}
            }
        }
        
        //expense module
        case DISABLE_OP_KMS: {
            return {
                ...state,
                disableOpKms: true
            }
        }
        case ENABLE_OP_KMS: {
            return {
                ...state,
                disableOpKms: false
            }
        }
        case 'SET_EXPENSE_TYPES': {
            let expenses = action.payload
            let selectExpenseTypes = []
            let expenseTypes = {}
            for (let i = 0; i < expenses.length; i++) {
                const e = expenses[i];
                selectExpenseTypes.push({ label: e.name, value: e.id })
                expenseTypes[e.id] = e
            }
            // return { ...state, expenseTypes: action.payload, expenseTypesMap: expenseTypes, selectExpenseTypes }
            return { ...state, expenseTypes, selectExpenseTypes }
        }
        case 'SELECT_EXPENSE_TYPE': {
            let expense = Object.assign({}, initExpense)
            let { val, expenseData } = action.payload
            expense = {
                ...expense,
                ...expenseData,
                ref: val
            }
            expense.subExpenses = null
            expense.subExpense = ''
            let expenseErrors = { ...state.expenseErrors }
            let expenseTouched = { ...state.expenseTouched }
            expenseTouched.expenseType = true
            expenseErrors.expenseType = validateExpense(expenseValidationSchema.getIn(['expenseType']), action.payload)
            if (action.payload == '') expense.expenseType = ''
            return {
                ...state,
                expense: expense,
                expenseErrors,
                expenseTouched,
                remainingLimit: 0,
            }
        }
        case 'SELECT_SUB_EXPENSE_TYPE': {
            let expense = Object.assign({}, state.expense)
            let id = 'id'
            if (state.expense.sub == 'U' || state.expense.sub == 'B') {
                id = '_id'
            }
            expense = {
                ...expense,
                assetBalance: '',
                sub_val: action.payload
            }
            let expenseErrors = { ...state.expenseErrors }
            expenseErrors.subExpense = validateExpense(expenseValidationSchema.getIn(['subExpense']), action.payload)
            let expenseTouched = { ...state.expenseTouched }
            expenseTouched.subExpense = true
            // expenseErrors.subExpense = validateExpense(expenseValidationSchema.subExpense, action.payload) 
            if (action.payload == '') expense.subExpense = ''
            return {
                ...state,
                expense,
                expenseErrors,
                expenseTouched
            }
        }
        case 'SET_SUB_EXPENSES': {
            console.log('set sub payload : ', action.payload)
            return { ...state, subExpenses: action.payload }
        }
        case SHOW_LOADER_IN_AMOUNT: {
            return {
                ...state,
                loaderAcrossAmount: true
            }
        }
        case HIDE_LOADER_IN_AMOUNT: {
            return {
                ...state,
                loaderAcrossAmount: false
            }
        }
        case DELETE_FUEL_EXPENSE: {
            showMessage("Fuel Expense Deleted !")
            return state
        }
        case 'EXPENSE_AMT': {
            let expense = Object.assign({}, state.expense)
            expense.amt = action.payload
            let expenseErrors = state.expenseErrors
            expenseErrors.amt = validateExpense(expenseValidationSchema.getIn(['amt']), action.payload)
            let expenseTouched = state.expenseTouched
            expenseTouched.amt = true
            return {
                ...state,
                expense,
                expenseErrors,
                expenseTouched
            }
        }
        case AVAILABLE_BALANCE: {
            console.log(' i am here', action.payload, action.payload.fixed.amt - action.payload.past);
            let remainingLimit = action.payload && action.payload.fixed ?
                action.payload.fixed.amt - action.payload.past : 0
            console.log('remainingLimit: ', remainingLimit);
            return {
                ...state,
                remainingLimit
            }
        }
        case 'TOGGLE_ATTACH_ROUTE': {
            let expense = Object.assign({}, state.expense)
            expense.attachRoute = !expense.attachRoute
            return { ...state, expense: expense }
        }
        case 'SET_EXP_ROUTES': {
            let expense = Object.assign({}, state.expense)
            expense.routes = action.payload
            return { ...state, expense: expense }
        }
        case 'SEL_EXP_ROUTE': {
            let expense = Object.assign({}, state.expense)
            let route = ''
            console.log('state.routes: ', state.routes);
            state.routes.map(rt => {
                if (rt._id == action.payload.value) {
                    route = action.payload
                }
            })
            expense.route = route
            let expenseErrors = state.expenseErrors
            expenseErrors.route = validateExpense(expenseValidationSchema.getIn(['route']), action.payload)
            let expenseTouched = state.expenseTouched
            expenseTouched.route = true
            return {
                ...state,
                expense: expense,
                expenseErrors: expenseErrors
            }
        }
        case 'EXP_START_DATE': {
            let expense = Object.assign({}, state.expense)
            expense.start_date = action.payload
            return { ...state, expense: expense }
        }
        case 'EXP_END_DATE': {
            let expense = Object.assign({}, state.expense)
            expense.end_date = action.payload
            return { ...state, expense: expense }

        }
        case RESET_INCUR_EXPENSE: {
            let expenseErrors = { ...expense_Boolean(true) }
            return {
                ...state,
                expense: initState.expense,
                fuel: initState.fuel,
                fuelErrors: initState.fuelErrors,
                expenseErrors: expenseErrors,
                remainingLimit: 0,
                disableOpKms: false,
                voucherPrint: false
            }
        }
        case CLOSE_VOUCHER_MODAL: {
            return {
                ...state,
                voucherPrint: false
            }
        }
        case DELETE_EXPENSE_RECORD: {
            let expense = {
                type: 'add',
                sub: null,
                sub_val: '',
                supplier: '',
                suppliers: null,
                route: '',
                ref: '',
                amt: '',
                remarks: '',
                non_cash: false,
                end_date: new Date(),
                start_date: new Date(),
                is_route: false,
                tripDate: '',
                attachRoute: false,
                duration: true,
                assetType: '',
                assetBalances: '',
                assetBalance: '',
                isFixedExpense: false
            }
            let expenseErrors = { ...expense_Boolean(true) }
            showMessage("Expense Record Deleted !", success)
            return {
                ...state,
                expense: expense,
                expenseErrors: expenseErrors
            }
        }
        case FIXED_EXPENSE_TYPE: {
            let expense = Object.assign({}, state.expense)
            expense.isFixedExpense = action.payload
            return {
                ...state,
                expense: expense
            }
        }
        case COULD_NOT_FETCH_CONTACT: {
            showMessage("Couldn't Fetch Contact", success)
            return state
        }
        case SET_EXPENSE_INCUR: {
            let expense = action.payload
            expense = {
                ...expense,
                type: 'edit',
                expenseType: expense.ref
            }
            return {
                ...state,
                expense: expense
            }
        }
        case SET_FUEL_VALUES: {
            return {
                ...state,
                fuel: action.payload
            }
        }
        case PAYMENT_UPDATE_REQ: {
            console.log('action.payload: ', action.payload);
            console.log('showAttachmentOptions ', state.showAttachmentOptions);
            return {
                ...state,
                showAttachmentOptions: action.payload
            }
        }
        case EDIT_EXPENSE_RECORD: {
            showMessage("Expense Record Has Been Edited !", success)
            let expense = {
                type: 'add',
                sub: null,
                sub_val: '',
                supplier: '',
                suppliers: null,
                route: '',
                ref: '',
                amt: '',
                remarks: "",
                non_cash: false,
                end_date: new Date(),
                start_date: new Date(),
                is_route: false,
                tripDate: '',
                attachRoute: false,
                duration: true,
                assetType: '',
                assetBalances: '',
                assetBalance: '',
                remarks: '',
                isFixedExpense: false
            }
            return { ...state, expense: expense }
        }
        case DELETE_EXPENSE_RECORD_FAILED: {
            showMessage(action.payload || "Couldn't Delete Expense Record !", success)
            return state
        }
        case EDIT_EXPENSE_RECORD_FAILED: {
            showMessage(action.payload, success)
            return state
        }

        case FUEL_IN_EXPENSE_ON_CHANGE: {
            let { what, val } = action.payload
            console.log('what, val reducer: ', what, val);
            let fuel = Object.assign({}, state.fuel)
            let key = { ...what }
            fuel[what] = val
            return {
                ...state,
                fuel: fuel,
                fuelErrors: state.fuelErrors.setIn(what, validateExpense(fuelExpenseValidation.getIn(what), val))
            }
        }
        case NON_CASH_CHANGE: {
            let { what, val } = action.payload
            console.log(' what, val: ', what, val);
            let expense = Object.assign({}, state.expense)
            expense[what] = val
            console.log('expense: ', expense);
            return {
                ...state,
                expense: expense,
            }
        }
        case SET_PAST_EXPENSES: {
            return {
                ...state,
                pastExpenses: action.payload
            }
        }
        case 'INCUR_EXP_SUCCESS': {
            showMessage("Expense Recorded Successfully!", success)
            return {
                ...state,
                voucherPrint: true,
                voucherNumber: action.payload
                // expense: { ...initExpense, expenseTypes: state.expense.expenseTypes },
                // fuel: initState.fuel
            }
        }
        case 'INCUR_EXP_FAILED': {
            showMessage(action.payload ? action.payload : "couldn't add expense", failed)
            return state
        }
        case 'EXP_ASSET_SET': {
            // let expense = Object.assign({}, state.expense)
            let assetTypes = action.payload
            return { ...state, assetTypes: assetTypes }
        }
        case 'EXP_SEL_ASSET_TYPE': {
            let expense = Object.assign({}, state.expense)
            expense.assetType = action.payload

            let expenseErrors = state.expenseErrors
            expenseErrors.assetType = validateExpense(expenseValidationSchema.getIn(['assetType']), action.payload)
            let expenseTouched = state.expenseTouched
            expenseTouched.assetType = true
            return {
                ...state,
                expense: expense,
                expenseErrors: expenseErrors
            }
        }
        case 'SET_ASSET_BAL': {
            let selectAssetBal, assetBalance = {}
            console.log('action.payload: ', action.payload);
            if (isArrayCheck(action.payload)) {
                selectAssetBal = action.payload.map(a => (
                    {
                        label: a.uniqueId
                            ? a.uniqueId : a.qty + ' ' + a.type.assetName,
                        value: a._id
                    }
                ))
                action.payload.forEach(e => {
                    let key = e.uniqueId ? e.uniqueId : e.qty + ' ' + e.type.assetName
                    assetBalance[key] = e
                })
                console.log('assetBalance: ', assetBalance);
                console.log('selectAssetBal: ', selectAssetBal);
            }
            return { ...state, assetBalances: assetBalance, selectAssetBal }
        }
        case 'SELECT_ASSET_BAL': {
            let expense = Object.assign({}, state.expense)
            expense = {
                ...expense,
                assetBalance: action.payload,
                sub_val: ''
            }
            let expenseErrors = state.expenseErrors
            expenseErrors.assetBalance = validateExpense(expenseValidationSchema.getIn(['assetBalance']), action.payload)
            let expenseTouched = state.expenseTouched
            expenseTouched.assetBalance = true
            return {
                ...state,
                expense,
                expenseErrors: expenseErrors
            }
        }
        case 'SET_COL_REPORT': {
            let colReport = Object.assign({}, state.colReport)
            Object.keys(action.payload).forEach(key => {
                colReport[key] = action.payload[key]
            })
            return { ...state, colReport: colReport }
        }
        case 'BR_CHANGE_SIMPLE': {
            let branchForm = state.branchForm
            const { index, val } = action.payload
            branchForm = branchForm.setIn(index, val)
            try {

                branchSchemaFieldWise.getIn(index).validateSync(val)
                branchForm = branchForm.setIn(['errors', ...index], null)
            } catch (err) {

                if (err.name == "ValidationError") {
                    branchForm = branchForm.setIn(['errors', ...index], err.message)
                }
                console.log("error while set error:", err);

            }
            if (index.length == 2 && ['rohh', 'romm', 'rchh', 'rcmm'].includes(index[1])) {
                days.forEach(day => {
                    if (day.includes(index[1].substring(1, 4))) {
                        branchForm = branchForm.setIn(['working', day], val)
                    }
                })
                return { ...state, branchForm }
            }
            return { ...state, branchForm }
        }
        case 'BR_CAM_ADD': {
            let branchForm = state.branchForm
            let cams = branchForm.get('cams').toJS().slice()
            cams.push(action.payload)
            branchForm = branchForm.set('cams', fromJS(cams))
            return { ...state, branchForm }
        }
        case 'BR_CAM_REMOVE': {
            let i = action.payload
            let branchForm = state.branchForm
            let cams = branchForm.get('cams').toJS().slice()
            cams.splice(i, 1)
            branchForm = branchForm.set('cams', fromJS(cams))
            return { ...state, branchForm }
        }
        case 'FETCH_BRANCH_SUCCESS': {
            console.log('got in reducer')
            let branchForm = state.branchForm
            let data = action.payload,
                sameFields = [
                    'branchName', 'shortName', 'pContact', 'sContact',
                    'email', 'isBooking', 'isDelivery',
                    '_id'
                ]
            sameFields.forEach(field => {
                console.log('for field : ', field, 'value: ', data[field])
                branchForm = branchForm.setIn([field], data[field])
            })
            branchForm = branchForm.setIn(['cams'], fromJS(data['cams']))

            let { paid, toPay: topay, credit, foc } = data.booking
            let { credit: dCredit} = data.delivery
            let { booking: isBooking, delivery: isDelivery } = data.operation
            let { l1, l2, pincode: pin, city } = data.address
            let { hub: isHub,cashBranch:cashBranch,location:location,manager:manager } = data
            let own
            switch (data.ownership) {
                case 0: {
                    own = 'o';
                    break
                }
                case 1: {
                    own = 'r';
                    branchForm = branchForm.set('rented', fromJS(data.rented))
                    break
                }
            }

            branchForm = branchForm.
                set('topay', topay).
                set('paid', paid).
                set('credit', credit).
                set('dCredit',dCredit).
                set('foc', foc).
                set('isBooking', isBooking).
                set('isDelivery', isDelivery).
                set('l1', l1).
                set('l2', l2).
                set('pin', pin).
                set('city', city).
                set('ownership', own).
                set('isHub', isHub).
                set('cashBranch', cashBranch).
                set('location', location).
                set('manager', manager)
            // try {
            //     if (data.transhipment &&
            //         data.transhipment.rate &&
            //         data.transhipment.rate.perPackage &&
            //         data.transhipment.rate.kgWise
            //     ) {
            //         branchForm.isTr = true
            //         let { rate } = data.transhipment
            //         if (rate.perPackage) {
            //             branchForm.trBy = 'ppf'
            //             branchForm.trArr = rate.perPackage.map(({ packageMin, packageMax, packagePrice }) => (
            //                 {
            //                     min: packageMin,
            //                     max: packageMax,
            //                     price: packagePrice
            //                 }))
            //         }
            //         else {
            //             branchForm.trby = 'kgw'
            //             branchForm.trArr = rate.kgWise.map(
            //                 ({ weightMin, weightMax, weightPrice }) => ({
            //                     min: weightMin,
            //                     max: weightMax,
            //                     price: weightPrice
            //                 }))
            //         }
            //     }

            // }
            // catch (err) {
            //     console.log(err)
            // }

            // if (data.franchise.delivery) {
            //     let { delivery } = data.franchise
            //     if (delivery.perPackage) {
            //         branchForm.frDel = delivery.perPackage.map(item => {
            //             return {
            //                 min: item.packageMin,
            //                 max: item.packageMax,
            //                 price: item.packagePrice
            //             }
            //         })
            //     }
            // }

            if (isArrayCheck(data.working.custom) && data.working.regular) {
                const result = procWorking(data.working)
                Object.keys(result).map(key => {
                    branchForm = branchForm.setIn(['working', key], result[key])
                })

            }

            return {
                ...state,
                branchForm
            }
        }
        case 'BRANCH_RESET': {
            return {
                ...state,
                branchForm: initBranchForm
            }
        }
        case 'BRANCH_CREATE_OR_UPDATE_FULFILLED': {
            let {message, response:branch} = action.payload 
            console.log(action.payload,'action payload')
            showMessage(message || "Branch Updated Successfully", success)

            let branches = state.branches.map(tempBranch => {
                if(tempBranch._id == branch._id){
                    return branch
                }else{
                    return tempBranch
                }
            })

            return { ...state,
                 branchForm: initBranchForm, 
                 branches, 
                 opBranch: (state.opBranch && state.opBranch._id == branch._id) ? branch : state.opBranch
            }
        }
        // case 'BRANCH_UPDATE_FULFILLED' : {
        //     showMessage("Branch updated Successfully", success)
        //     return { ...state, branchForm: initBranchForm }
        // }
        case 'SET_ERROR': {
            try {
                let { branchForm } = state
                const { payload } = action
                branchSchemaFieldWise.getIn(payload).validateSync(branchForm.getIn(payload))
                branchForm = branchForm.setIn(['errors', ...payload], null)
                return { ...state, branchForm }
            } catch (err) {
                const { payload } = action
                if (err.name == "ValidationError") {
                    let { branchForm } = state
                    branchForm = branchForm.setIn(['errors', ...payload], err.message)
                    return { ...state, branchForm }
                }
                console.log("error while set error:", err);

            }
            return state

        }
        case 'DELETE_BRANCH_FULFILLED': {
            showMessage('Branch Deleted successfully',success)
            return { ...state, branchForm: initBranchForm }  
        }
        case DELIVERY_PENDING: {
            return { ...state, deliveryAPI: true }
        }
        case DELIVERY_FAIL: {
            return { ...state, deliveryAPI: false }
        }
        case 'REFRESH_MEMO': {
            return { ...state }
        }
        case UPDATE_COMPANY_DOC: {
            try {
                let company = state.company._id
                console.log('action.data: ', action.data);
                if (action.data && company == action.data._id) {
                    showMessage('Company Policy Updated !', success)
                    return { ...state, company: action.data }
                }
                else throw 'err'
            } catch (error) {
                console.log('error: ', error);
                return state
            }
        }
        case 'INIT:SET_LOADING_REPORTS': {
            return {
                ...state,
                pendingLoadingReports: action.payload
            }
        }
        case 'INIT:SET_FRANCHISE_REQUESTS':{
            return {
                ...state,
                franchiseRequests: action.payload
            }
        }
        case 'SIDEBAR:UPDATE_ACK': {
            return {
                ...state,
                updateSidebar: false
            }
        }
        // report module
        case SET_COMPANY_ROUTES : {
            return {
                ...state,
                routes: action.payload
            }
        }
        //could not match to any action
        default: {
            return state
        }
    }
}

export default reducer

export const makeReducer = (customInitState) => (state = customInitState, action) => reducer(state, action)