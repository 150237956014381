import { isObject } from "util";

// const evaluate = (obj) => {
//     console.log('evaludate : ', obj)
//     return (typeof obj == 'boolean') ?
//         obj :
//         (typeof obj == 'object') ?
//             Object.keys(obj).reduce((acc, key) => evaluate(obj[key]) || acc, false) :
//             false
// }

export const processRights = (departments) => {
    // update this rights schema and sync with nodeserver config when changed
    let defaultRights = {
        company: {
          charges: false,
          messages: false,
          expense: false,
          income: false,
          manage: false,
          incentive: false
        },
        operations: {
          booking: {
            create: false,
            edit: false,
            delete: false
          },
          loading: false,
          unloading: false,
          delivery: false
        },
        branch: false,
        listbranch: false,
        attendance: {
          add: false,
          report: false
        },
        user: false,
        fleet: {
          create: false,
          edit: false
        },
        zone: {
          create: false,
          edit: false,
          delete: false,
        },
        department: false,
        fuel: {
          create: false,
          edit: false,
          report: false,
        },
        customer: false,
        route: {
          create: false,
          edit: false,
          delete: false,
          view: false,
        },
        rate: {
          create: false,
          edit: false,
          delete: false,
          view: false,
        },
        report: {
          expense: false,
          income: false,
          daily: false,
          booking: false,
          bookingSummary: false,
          delivery: false,
          loading: false,
          fuel: false,
          MIS: false,
          pendingMemos: false,
          shortage: false,
          userIssue: false,
          userBalance: false,
          incomingDocket: false,
        },
        memo: false,
        assign: {
          branch: false,
          department: false,
          fleet: false,
        },
        remarks: false,
        assets: {
          types: false,
          balance: false
        },
        incurExpense: {
          incur: false,
          nonCash: false,
          adminLevelIncur: false,
          edit: false,
          delete: false
        },
        incurIncome: {
          incur: false,
          nonCash: false,
          adminLevelIncur: false,
          edit: false,
          delete: false
        },
        transfer: false,
        franchise: false,
        tallySync: false,
        notification: { ungeneratedReport: false }
      }
   
    //setting overall rights
    departments.forEach(({ rights }) => {

        const recursiveMerge = (obj1, obj2, options) => {

            if(!obj2 || !obj1) return
            let result = {}
            try{
                Object.keys(obj1).filter(key => {
                    if(isObject(obj1[key])){
                        let mergedObj = recursiveMerge(obj1[key],obj2[key])
                        result[key] =  mergedObj || obj1[key]
                    }else{
                        result[key] = isObject(obj2[key]) ? obj1[key] : (obj1[key] || obj2[key])
                    }
                    
                    
                    
                })
            }catch(err){
                return
            }
            return result
        }
        defaultRights = recursiveMerge(defaultRights,rights)
    })

    return defaultRights
}
