import { showMessage } from './UserReducer'
import { countHandling, countBuilty } from './utils/count'
import { acrossFun, clearNewPackage } from './utils/helpers'
import { BookingError } from '../validators/err'
import { Map } from 'immutable'
import { SET_DOCKET_FROM_EWAYBILL, SET_ROUTES, UPDATE_BUILTY, SET_PACKAGE_ERROR_FLAG_FALSE } from '../constants/booking'

import {
    BUILTY_UPDATE_ACK,
    BUILTY_UPDATE_REQ,
    PACKAGE_UPDATE_ACK,
    DEST_CITY_UPDATE_ACK,
    PAYMENT_UPDATE_ACK,
    REFRESH_BOOKING,
    GET_BRANCHES_BY_COMPANY,
    CANCEL_BOOKING,
    CANCEL_BOOKING_FAILED,
    ID_FETCH_ACK,
    BILL_FETCH_ACK,
    DOCKETS_FETCH_ACK,
    SENDER_GST_INVALID,
    RECEIVER_GST_INVALID,
    RESET_SENDER_ADDRESS,
    RESET_RECEIVER_ADDRESS,
    SHOW_LOADER,
    HIDE_LOADER,
    HIDE_EWAYBILL_LOADER,
    SHOW_EWAYBILL_LOADER,
    SHOW_GST_LOADER,
    HIDE_GST_LOADER,
    CREDIT_NUMBER_HIDE_LOADER,
    CREDIT_NUMBER_SHOW_LOADER,
    SHOW_GST_LOADING_FOR_RECEIVER,
    HIDE_GST_LOADING_FOR_RECEIVER,
    NO_DATA,
    SET_SENDER_ADDRESS_FROM_OPBRANCH,
    SET_SENDER_ADDRESS_FROM_OTHER,
    SET_RECEIVER_ADDRESS_FROM_OPBRANCH,
    SET_RECEIVER_ADDRESS_FROM_OTHER,
    CHANGE_FRANCHISE_NO,
    TOGGLE_FRANCHISE,
    GIVE_COMMISSION,
    FRANCHISE_LOADING,
    FRANCHISE_FAILED,
    SHOW_LOADER_WITHOUT_BLUR,
    HIDE_LOADER_WITHOUT_BLUR,
    TOUCH_SENDER,
    TOUCH_RECEIVER
} from '../constants/booking'
import { isArrayCheck } from '../containers/generics/CheckArray'
import { toast } from 'react-toastify';
import { log } from 'util'

class Branch {

    constructor(doc) {
        this.branchName = doc.branchName
        this.address = doc.address
        this.company = doc.company
    }

    getAddress() {
        return (this.address && this.address.city) ? (this.address.l1 + ', ' + this.address.l2 + ', ' + this.address.pincode + ', ' + this.address.city.name) : ''
    }
}

export const success = '#42f462',
    failed = '#ef533e',
    info = '#37befc'

let padTo = (number, to) => number <= 9999 ? ("000" + number).slice(-to) : number;
let toRSOption = (value) => value ? ({ label: value, value: value }) : null
const d = new Date()
let day = padTo(d.getDate(), 2),
    month = padTo(d.getMonth() + 1, 2),
    year = padTo(d.getFullYear(), 4)

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
const initExpense = {
    sub: null,
    expenseTypes: null,
    expenseType: '',
    subExpenses: null,
    subExpense: '',
    supplier: '',
    suppliers: null,
    amt: 0,
    fromDate: year + '-' + month + '-' + day,
    toDate: year + '-' + month + '-' + day,
    route: '',
    tripDate: null,
    routes: null,
    attachRoute: false,
    duration: true,
    assetType: '',
    assetTypes: null,
    assetBalances: null,
    assetBalance: ''
}

const initCredit = {
    balance: null,
    company: null,
    customer: null,
    permissions: []

}

const colReport = {
    bookings: [],
    expenses: [],
    deliveries: [],
    sumOfExpense: 0,
    sumOfGross: 0
}

const initState = {
    loginType: null,
    userName: null,
    uid: null,
    token: null,
    contact: null,
    driverName: null,
    from: null,
    to: null,
    selectedFuel: null,
    filterContact: null,
    filterVehicle: null,
    assign: {
        branches: null,
        departments: null,
        fleets: null
    },
    ppf: false,
    kgw: false,
    fr_ppf: false,
    fr_kgw: false,
    editBranchId: null,
    suggPrice: null,
    routeSugg: null,
    customer: {
        name: '',
        contact: '',
        gst: '',
        address: {
            l1: '',
            l2: '',
            city: '',
            pincode: ''
        }
    },
    fr: 0,
    editDocket: false,
    deliveryType: {value: "Branch To Branch",label:"Branch To Branch"},
    bf: 0,
    hf: 0,
    gross: 0,
    packages: [{
        id: 0,
        qty: '',
        materialType: '',
        packingType: '',
        size: { label: 'custom', value: 'custom' },
        dimension: { l: '', b: '', h: '' },
        weight: '',
        rate: { value: 'Direct', label: 'Direct' },
        unit: '',
        amount: '',
        stack: false,
        haz: false,
        frag: false
    }],
    risk: '',
    isCol: false,
    howcollect: '',
    colMonth: 0,
    customPack: 'null',
    bookingRate: 'Direct',
    credit: initCredit,
    loadcity: '',
    loadroute: '',
    loadbranch: '',
    loaddocket: '',
    loadVehicle: '',
    assignroute: '',
    bookdestbranch: '',
    isEwayBill: false,
    sender: {
        name: '',
        contact: '',
        gst: '',
        l1: '',
        l2: '',
        pin: '',
        city: '',
        otherAddr: []
    },
    receiver: {
        name: '',
        contact: '',
        gst: '',
        l1: '',
        l2: '',
        pin: '',
        city: '',
        otherAddr: []
    },
    newBranches: [],
    creditNumber: '',
    expense: initExpense,
    colReport,
    delAmt: '',
    specificDelivery: false
}

const reducer = (state = initState, action) => {
    console.log('book reducer called for : ', action.type)
    switch (action.type) {
        case 'BOOK_BILL': {
            return { ...state, bill: action.payload }
        }
        case 'BOOK_PURPOSE': {
            return {
                ...state,
                purpose: action.payload,
                ewb: '',
                bill: '',
                bookTouches: state.bookTouches.setIn(['purpose'], true)
            }
        }
        case 'BOOK_DOCKET': {
            return { ...state, docketNumber: action.payload }
        }
        case 'BOOK_EWB': {
            return { ...state, ewb: action.payload }
        }
        case 'BOOK_SENDER': {
            let { receiver } = state
            let sender = Object.assign({}, state.sender)
            let { what, val } = action.payload
            console.log('book sender val : ', val)
            sender[what] = val
            let senderGst = false
            if (what === 'contact' && receiver && receiver.contact && receiver.contact === val) {
                toast.error('Sender Contact Cannot Be same as Receiver Contact')
                return state
            }
            if (what == 'gst') {
                let { gstNos } = sender
                if (isArrayCheck(gstNos) && val && val.value) {
                    let gstConcern = gstNos.find(g => g.GSTIN == val.value)
                    console.log('gst concern : ', gstConcern)
                    if (gstConcern) {
                        sender.name = {
                            label: gstConcern.name,
                            value: gstConcern.name
                        }
                        senderGst = true
                    }
                }
                if (val && val.value) {
                    sender[what] = {
                        label: val.label.toUpperCase(),
                        value: val.value.toUpperCase()
                    }
                }
                else {
                    sender[what] = null
                }
            }
            return {
                ...state,
                sender: sender,
                bookTouches: state.bookTouches.setIn(['sender', what], true),
                senderGst
            }
        }
        case TOUCH_SENDER: {
            return {
                ...state,
                bookTouches: state.bookTouches.setIn(['sender', action.payload], true)
            }
        }
        case 'BOOK_SET_SENDER': {
            console.log('\n\ncaught him here : ', action.payload)
            const {
                address: serverAddress,
                GSTIN: gst,
                otherAddr,
                name,
                gstNos,
                names,
                // bookedfor
            } = action.payload, { pincode } = serverAddress
            const address = { ...serverAddress, pin: pincode }
            let newOthr = [], senderData
            if (isArrayCheck(otherAddr) && address) {
                newOthr = otherAddr.map(a => ({ ...a, pin: a.pincode }))
            }
            newOthr.push(address)
            if (state.sender.gst) {
                senderData = {
                    ...state.sender,
                    name: name,
                    otherAddr: newOthr
                }
            }
            else {
                senderData = {
                    ...state.sender,
                    name: name,
                    gst,
                    otherAddr: newOthr
                }
            }
            if (state.sender.name != '') delete senderData.name

            return {
                ...state,
                sender: {
                    ...state.sender,
                    ...senderData,
                    gstNos: isArrayCheck(gstNos) ? gstNos.filter(g => g.GSTIN && g.GSTIN.length > 0 && g.name && g.name.length > 0) : [],
                    names
                },
                // receiverSuggestions: bookedfor
            }
        }
        case 'SET_RECEIVER_SUGGESTIONS': {
            return {
                ...state,
                receiverSuggestions: action.payload
            }
        }
        case 'SET_RATE_SUGGESTIONS' : {
            return {
                ...state,
                rateSuggestions: action.payload
            }
        }
        case 'SET_PACKAGE_SUGGESTIONS' : {
            return {
                ...state,
                packageSuggestions: action.payload
            }
        }
        case 'BOOK_SET_RECEIVER': {
            console.log('\n\ncaught him here : ', action.payload)
            const {
                address: serverAddress,
                GSTIN: gst,
                otherAddr,
                name,
                gstNos,
                names } = action.payload,
                { pincode } = serverAddress

            const address = { ...serverAddress, pin: pincode }
            let newOthr = [], receiverData
            // if()
            if (isArrayCheck(otherAddr) && address) {
                console.log('reciever name: ', name);
                newOthr = otherAddr.map(a => ({ ...a, pin: a.pincode }))
            }
            newOthr.push(address)
            if (state.receiver.gst) {
                console.log('INIF');
                receiverData = {
                    ...state.receiver,
                    name: name,
                    // gst:state.receiver.gst,
                    otherAddr: newOthr,
                }
            }
            else {
                console.log('inelse');
                receiverData = {
                    ...state.receiver,
                    name: name,
                    gst,
                    otherAddr: newOthr,
                }
            }
            if (state.receiver.name != '') delete receiverData.name
            console.log('receiverData2: ', receiverData);
            return {
                ...state,
                receiver: {
                    ...state.receiver,
                    ...receiverData,
                    gstNos,
                    names
                }
            }
        }
        case TOUCH_RECEIVER: {
            return {
                ...state,
                bookTouches: state.bookTouches.setIn(['receiver', action.payload], true)
            }
        }
        case 'BOOK_RECEIVER': {
            let { sender } = state
            let receiver = Object.assign({}, state.receiver)
            let receiverGst = false
            let { what, val } = action.payload
            receiver[what] = val
            if (what === 'contact' && sender && sender.contact && sender.contact === val) {
                toast.error('Sender Contact Cannot Be same as Receiver Contact')
                return state
            }
            if (what === 'gst') {
                if (val && val.value) {
                    let { gstNos } = receiver
                    if (isArrayCheck(gstNos) && val && val.value) {
                        let gstConcern = gstNos.find(g => g.GSTIN == val.value)
                        console.log('gst concern : ', gstConcern)
                        if (gstConcern) {
                            receiver.name = {
                                label: gstConcern.name,
                                value: gstConcern.name
                            }
                            receiverGst = true
                        }
                    }
                    receiver[what] = {
                        label: val.label.toUpperCase(),
                        value: val.value.toUpperCase()
                    }
                }
                else {
                    receiver[what] = null
                }
            }
            return {
                ...state,
                receiver,
                bookTouches: state.bookTouches.setIn(['receiver', what], true),
                receiverGst
            }
        }
        case 'SET_DOCKET': {
            let {
                docket,
                packages,
                billUrl,
                idUrl,
                fedexLabels,
                docketUrls,
                delivery,
                consigneeIdImage,
                podImage
            } = action.payload
            // for older fedex bookings wihtout dest branch
            if(!docket.destBranch){
                docket.destBranch = docket.originBranch
            }
            let {
                purpose,
                originBranch,
                eWayBill,
                billNo,
                paymentMode,
                senderName,
                receiverName,
                senderGst: senderGST,
                receiverGst: receiverGST,
                franchiseNumber,
                createdAt,
                docketNumber
            } = docket
            const purposes = {
                'P': { label: 'Personal', value: 'P' },
                'C': { label: 'Commercial', value: 'C' }
            }

            let sender = {
                name: toRSOption(senderName),
                contact: docket.sender.contact,
                gst: toRSOption(senderGST),
                gstNos: docket.sender.gstNos,
                names: docket.sender.names,
                l1: docket.sAddress.l1,
                l2: docket.sAddress.l2,
                pin: docket.sAddress.pincode,
                city: docket.sAddress.city.name
            },
                receiver = {
                    name: toRSOption(receiverName),
                    contact: docket.receiver.contact,
                    gst: toRSOption(receiverGST),
                    gstNos: docket.receiver.gstNos,
                    names: docket.receiver.names,
                    l1: docket.rAddress.l1,
                    l2: docket.rAddress.l2,
                    pin: docket.rAddress.pincode,
                    city: docket.rAddress.city.name
                },
                orgBranch,
                findCity,
                originCity,
                senderGst = false,
                receiverGst = false
            if (docket.sender.gst && docket.purpose != 'P') {
                senderGst = true
            }
            if (docket.receiver.gst && docket.purpose != 'P') {
                receiverGst = true
            }
            orgBranch = { label: originBranch.branchName, value: originBranch.branchName }
            findCity = state.allcities.find(c => c._id == originBranch.address.city)
            originCity = { label: findCity.name, value: findCity.name }

            const uniquePackageMap = {}
            packages.forEach(p => {
                const {
                    materialType,
                    packingType,
                    size,
                    dimension,
                    weight,
                    rate,
                    amount,
                    unit,
                    stack,
                    haz,
                    frag
                } = p

                const { l, b, h } = dimension
                const uid = [
                    materialType && materialType.name ? materialType.name : 'N/A',
                    materialType && materialType._id ? materialType._id : 'N/A',
                    packingType && packingType.name ? packingType.name : 'N/A',
                    packingType && packingType._id ? packingType._id : 'N/A',
                    size,
                    l,
                    b,
                    h,
                    unit,
                    weight,
                    rate,
                    amount,
                    stack,
                    haz,
                    frag
                ].join('@')
                if (uid in uniquePackageMap) {
                    uniquePackageMap[uid] += 1
                }
                else {
                    uniquePackageMap[uid] = 1
                }
            })
            let tempPackages = Object.keys(uniquePackageMap).map((uid, idx) => {
                const [
                    materialType_name,
                    materialType_id,
                    packingType_name,
                    packingType_id,
                    size,
                    l,
                    b,
                    h,
                    unit,
                    weight,
                    rate,
                    amount,
                    stack,
                    haz,
                    frag
                ] = uid.split('@')
                console.log('uid is : ', uid)
                console.log('uid split : ', uid.split('@'))

                let qty = uniquePackageMap[uid]
                const freightRates = {
                    'Direct': { label: 'Direct', value: 'Direct' },
                    'Per package': { label: 'Per package', value: 'Per package' },
                    // 'Per Kg': { label: 'Per Kg', value: 'Per Kg' }
                }
                const units = {
                    'm': { label: 'Mt', value: 'm' },
                    'cm': { label: 'Cm', value: 'cm' },
                    'in': { label: 'Inch', value: 'in' }
                }
                console.log('state.company', state.company);
                let sizeArr = state.company.sizes,
                    sizeMap = {}
                if (isArrayCheck(sizeArr)) {
                    sizeArr.forEach(s => {
                        sizeMap[s.name] = { label: s.name, value: s.name }
                    })
                }
                console.log('\n\n============>rate: ', rate)
                return {
                    qty,
                    materialType: {
                        label: materialType_name,
                        value: materialType_id
                    },
                    packingType: {
                        label: packingType_name,
                        value: packingType_id
                    },
                    size: sizeMap[size],
                    dimension: { l, b, h },
                    weight,
                    rate: freightRates[rate],
                    amount: parseInt(amount),//rate == 'Per package' ? parseInt(amount) : parseInt(amount, 10) * parseInt(qty, 10),
                    unit: units[unit],
                    stack: stack == 'true',
                    haz: haz == 'true',
                    frag: frag == 'true',
                    id: idx
                }
            })
            let paymentModeMap = {
                'paid': { label: 'Paid', value: 'paid' },
                'topay': { label: 'ToPay', value: 'topay' },
                'credit': { label: 'Credit', value: 'credit' },
                'foc': { label: 'FOC', value: 'foc' }
            }
            console.log('CHECK: ', paymentModeMap[docket.paymentMode], docket.paymentMode);
            console.log('temp packages : ', tempPackages)
            let resPackages = tempPackages
            const destBranchObj = new Branch(docket.isFedexBooking ? {
                branchName: "FEDEX",
                address: {
                    l1: docket.rAddress.l1,
                    l2: docket.rAddress.l2,
                    pin: docket.rAddress.pincode,
                    city: docket.rAddress.city.name
                }
            } : docket.destBranch)
            let orangeLR = docket.isOrangeBooking ? docket.orangeDetails.lrNumber : null
            let otherValues = {
                hf: docket.handling,
                bf: docket.bilty,
                orangeLR,
                senderGst,
                receiverGst,
                pickupCharge: docket.pickupCharge,
                deliveryCharge: docket.doorDelCharges,
                fr: docket.freight,
                risk: docket.risk,
                ins: docket.insAmt,
                insValue: docket.insFee,
                isCol: docket.colEnabled,
                howcollect: docket.collectionType,
                col: docket.colAmt,
                colValue: docket.colFee,
                packValue: docket.packFee,
                colMonth: docket.colMonth,
                customPack: docket.customPack,
                bookingRate: docket.freightType,
                bookingMode: !delivery ? paymentModeMap[paymentMode] : paymentMode,
                goods: docket.goods,
                purpose: purposes[purpose],
                docketStatus: docket.status,
                franchiseNumber,
                specificDelivery: docket.delSpecific,
                isEwayBill: eWayBill && eWayBill.length > 0,
                bookingTime: createdAt,
                recvAddr: destBranchObj.getAddress(),
                fromAddr: docket.originBranch ? docket.originBranch.branchName : '',
                toAddr: docket.destBranch ? docket.destBranch.branchName : ''
            }
            console.log('otherValues: ', otherValues);
            let packageSample = packages[0]
            let bookdestcity = state.allcities ? ( docket.isFedexBooking ? state.allcities.filter(c => c._id == docket.receiver.address.city): state.allcities.filter(c => c._id == packageSample.destCity)[0] ) : null
            let bookdestbranch = docket.destBranch
            let filbranches = state.filbranches && state.filbranches.length > 0 ? state.filbranches.concat(bookdestbranch) : [bookdestbranch]
            let bookroute
            if (packageSample.route) {
                bookroute = packageSample.route
            }
            const delDocket = {
                docket: {
                    ...docket,
                    paymentMode: paymentModeMap[docket.paymentMode],
                    consigneeIdImage,
                    podImage
                },
                packages
            }
            console.log('billNo: ', billNo);
            const { bookingUpdate } = state
            console.log('got dest city : ', bookdestcity, '\ndest branch : ', bookdestbranch, '\n route : ', bookroute)
            return {
                ...state,
                docketNumber,
                fedexLabels,
                sender,
                receiver,
                bill: billNo,
                bookingStatus: docket.status,
                ewb: eWayBill,
                packages: resPackages,
                filbranches,
                delDocket,
                editDocket: true,
                idUrl,
                orgBranch,
                originCity,
                idFetch: typeof idUrl === 'string' && idUrl.length > 0 ? true : false,
                billUrl,
                billFetch: typeof billUrl === 'string' && billUrl.length > 0 ? true : false,
                docketUrls,
                docketsFetch: Array.isArray(docketUrls) && docketUrls.length > 0 ? true : false,
                bookingUpdate: {
                    ...bookingUpdate,
                    payment: true,
                    packages: true,
                    destCity: true
                },
                bookdestcity: { label: bookdestcity.name, value: bookdestcity._id },
                bookdestbranch: bookdestbranch ? { label: bookdestbranch.branchName, value: bookdestbranch._id } : null,
                bookroute: bookroute ? { label: bookroute.name, value: bookroute._id } : { label: 'Select Route', value: '' },
                ...otherValues
            }
        }
        case UPDATE_BUILTY: {
            return { ...state, updateBuilty: true }
        }
        case SET_DOCKET_FROM_EWAYBILL: {
            console.log('action.payload', action.payload);
            let data = Object.assign({}, action.payload)
            let toRSOption = (value) => value ? ({ label: value, value: value }) : null
            let sender = {
                ...state.sender,
                name: toRSOption(data.legal_name_of_consignor),
                gst: toRSOption(data.gstin_of_consignor)
            }
            console.log('sender: ', sender);
            let receiver = {
                ...state.receiver,
                name: toRSOption(data.legal_name_of_consignee),
                gst: toRSOption(data.gstin_of_consignee)
            }
            console.log('receiver: ', receiver);
            let bill = data.document_number
            console.log('bill: ', bill);
            let goods = data.total_invoice_value
            console.log('goods: ', goods);
            return {
                ...state,
                sender: sender,
                receiver,
                bill,
                goods,
                isEwayBill: true
            }
        }
        case 'RESET_SENDER_NAME': {
            console.log('RESET_SENDER_NAME:');
            let sender = {
                ...state.sender,
                name: ''
            }
            return {
                ...state,
                sender,
                senderGst: false
            }
        }
        case 'RESET_RECEIVER_NAME': {
            let receiver = {
                ...state.receiver,
                name: ''
            }
            return {
                ...state,
                receiver,
                receiverGst: false
            }
        }
        case REFRESH_BOOKING: {

            return {
                ...state,
                bookingUpdate: {
                    ...state.bookingUpdate,
                    payment: true,
                    packages: true,
                    destCity: true,
                },
                senderGstInvalid: false,
                receiverGstInvalid: false
            }
        }
        case 'SET_BUILTY_CONCERNED': {
            return {
                ...state,
                updateBuilty: true,
                concernedBuilty: action.payload
            }
        }
        case GET_BRANCHES_BY_COMPANY: {
            return {
                ...state,
                newBranches: action.payload
            }
        }
        case CANCEL_BOOKING: {
            const { status, canceledInfo } = action.payload
            showMessage('Booking Cancelled', success)
            let { docket } = state.delDocket
            docket = {
                ...docket,
                status,
                canceledInfo
            }

            return {
                ...state,
                delDocket: {
                    ...state.delDocket,
                    docket
                }
            }
        }
        case CANCEL_BOOKING_FAILED: {
            console.log('action.payload:', action.payload);
            showMessage(action.payload.response || action.payload.message || "cancel failed", failed)
            return state
        }
        case 'BOOK_COL_MONTH': {
            return { ...state, colMonth: parseInt(action.payload, 10) }
        }
        case 'BOOK_RATE': {
            return { ...state, bookingRate: action.payload }
        }
        case 'BOOK_PACK': {
            try {
                let val = action.payload
                let charge = 0
                state.company.packaging.forEach(slab => {
                    console.log('slab is : ', slab, '\nval is ', val)

                    if (slab.name == val) {
                        charge = slab.price
                    }
                })
                return {
                    ...state,
                    customPack: action.payload,
                    packValue: charge
                }
            }
            catch (err) { console.log(err) }
        }
        case 'BOOK_HOW_COL': {
            try {
                if (!state.goods || state.goods.length == 0) throw "GOODS_NULL"
                let newCol = parseInt(state.col, 10)
                let arr = state.company[action.payload]

                let colCharge = 0
                if (arr && arr.length > 0) {
                    arr.forEach(ele => {
                        if (newCol >= ele.min && newCol <= ele.max) {
                            colCharge = ele['charge']
                        }
                    })
                }
                else if (action.payload == '') {
                    return {
                        ...state,
                        howcollect: action.payload,
                        colValue: 0,
                        col: 0
                    }
                }
                return {
                    ...state,
                    howcollect: action.payload,
                    colValue: colCharge
                }
            }
            catch (err) {
                if (err == 'GOODS_NULL') {
                    showMessage('Insert goods value first', failed)
                }
                console.log(err)
            }

        }
        case 'PICKUP_CHARGE_CHANGE': {
            const { what, val } = action.payload
            console.log(action.payload);
            let newState = { ...state }
            newState[what] = val
            return newState
        }
        case 'BOOK_COL_TOGGLE': {
            try {
                if (!state.goods) throw "GOODS NULL"
                let collection = state.isCol ? false : true
                let newCol = parseInt(state.goods, 10)
                let arr = state.company[state.howcollect]
                if (!arr || arr.length === 0) throw "INVALID CHOICE"
                let colCharge = 0
                arr.forEach(ele => {
                    if (newCol >= ele.min && newCol <= ele.max) {
                        colCharge = ele['charge']
                    }
                })

                if (collection === false) {
                    colCharge = 0
                }
                return {
                    ...state,
                    isCol: collection,
                    colValue: colCharge
                }
            }
            catch (err) {
                console.log(err)
                return state
            }
        }
        case 'BOOK_RISK_CHANGE': {
            try {
                if (!state.goods || state.goods.length == 0) throw "GOODS_NULL"
                let flag = action.payload
                let arr = []
                if (flag == 0) {
                    return { ...state, risk: action.payload, insValue: 0 }
                }
                if (flag == 1) {
                    arr = state.company.insurance.owner
                }
                if (flag == 2) {
                    arr = state.company.insurance.carrier
                }
                console.log('got arr : ', arr)

                let newIns = parseInt(state.ins, 10)
                let insCharge = 0
                arr.forEach(ele => {
                    if (newIns >= ele.min && newIns <= ele.max) {
                        insCharge = ele['charge']
                    }
                })
                return {
                    ...state,
                    ins: newIns,
                    insValue: insCharge,
                    risk: action.payload
                }
            }
            catch (err) {
                console.log(err)
                if (err == 'GREATER') showMessage("Insurance base value must be less than goods value", failed)
                else if (err == 'GOODS_NULL') showMessage("Set goods value first", failed)
                return state
            }
        }
        case 'BOOK_GOODS_CHANGE': {
            try {
                let insCharge = 0, val = parseInt(action.payload, 10)
                //calculate insurance
                if (state.risk != 0) {
                    let arr = []
                    if (state.risk == 1) arr = state.company.insurance.owner
                    if (state.risk == 2) arr = state.company.insurance.carrier
                    arr.forEach(ele => {
                        if (val >= ele.min && val <= ele.max) {
                            insCharge = ele['charge']
                        }
                    })
                }
                let colCharge = 0
                console.log('state.isCol: ', state.isCol);
                if (state.isCol) {
                    let arr = []
                    if (state.howcollect == 'cod') {
                        arr = state.company.cod
                    }
                    else if (state.howcollect == 'col') {
                        arr = state.company.col
                    }
                    console.log('BOOK_GOODS_CHANGE');

                    if (state.howcollect == 'cod' || state.howcollect == 'col') {
                        arr.forEach(ele => {
                            if (val >= ele.min && val <= ele.max) {
                                colCharge = ele[state.across]
                            }
                        })
                    }
                    else colCharge = 0
                    console.log('BOOK_GOODS_CHANGE 2');
                    console.log('colCharge: ', colCharge);
                    console.log('checl me :', (colCharge == null || colCharge == 0) && (state.howcollect == 'cod' || state.howcollect == 'col'));
                    if ((colCharge == null || colCharge == 0) && (state.howcollect == 'cod' || state.howcollect == 'col')) {
                        colCharge = arr[arr.length - 1].charge
                        console.log('colCharge main: ', colCharge);
                    }
                }
                return {
                    ...state,
                    goods: action.payload,
                    ins: action.payload,
                    col: action.payload,
                    insValue: insCharge,
                    colValue: colCharge
                }
            }
            catch (err) { console.log(err) }
        }
        case 'BOOK_INS_CHANGE': {
            try {
                if (!state.goods || state.goods.length == 0) throw "GOODS_NULL"
                let newIns = parseInt(action.payload, 10)
                let goods = parseInt(state.goods, 10)
                if (newIns > goods) throw "GREATER"
                let arr = null
                if (state.risk == 0) throw "SELECT"
                if (state.risk == 1) {
                    arr = state.company.insurance.owner
                }
                if (state.risk == 2) {
                    arr = state.company.insurance.carrier
                }
                let insCharge = 0
                arr.forEach(ele => {
                    if (newIns >= ele.min && newIns <= ele.max) {
                        insCharge = ele['charge']
                    }
                })
                return {
                    ...state,
                    ins: newIns,
                    insValue: insCharge
                }
            }
            catch (err) {
                console.log(err)
                if (err == 'GREATER') showMessage("Insurance base value must be less than goods value", failed)
                else if (err == 'GOODS_NULL') showMessage("Set goods value first", failed)
                return state
            }
        }
        case 'BOOK_COL_CHANGE': {
            try {
                if (!state.goods || state.goods.length == 0) throw "GOODS_NULL"
                let newCol = parseInt(action.payload, 10)
                let goods = parseInt(state.goods, 10)
                if (newCol > goods) throw "GREATER"
                let arr = state.company[state.howcollect]
                if (!arr || arr.length === 0) throw "INVALID CHOICE"
                let colCharge = 0
                arr.forEach(ele => {
                    if (newCol >= ele.min && newCol <= ele.max) {
                        colCharge = ele['charge']
                    }
                })
                return {
                    ...state,
                    col: newCol,
                    colValue: colCharge
                }
            }
            catch (err) {
                console.log(err)
                if (err == 'GREATER') showMessage("Collection value must be less than goods value", failed)
                else if (err == 'GOODS_NULL') showMessage("Set goods value first", failed)
                return state
            }
        }
        case 'BOOKING_REFRESH': {
            try {
                const { packages } = state
                let freight = state.fr
                console.log('final packages : ', packages)

                const { handling, bilty } = state.company
                let hf = countHandling(packages, handling, state.across)
                let bf = 0
                freight = parseInt(freight, 10)
                bf = countBuilty(bilty, state.across)

                return {
                    ...state,
                    fr: freight,
                    hf: hf,
                    bf: bf,
                    gross: freight + hf + bf
                }
            }
            catch (err) {
                console.log(err)
                switch (err) {
                    case 'AcrossError': { showMessage('Select Destination city first', failed) }
                    default: { showMessage('Something went wrong', failed) }
                }
                return state
            }
        }
        case 'BOOKING_EDIT_PACKAGE': {
            try {
                if (!state.across || !state.deliveryType) throw new BookingError('Select Proper Destination first', 'across')
                if(state.deliveryType.value == 'Branch To Branch' && (!state.bookdestcity || !state.bookdestcity.value)) throw new BookingError('Select Proper Destination first', 'bookdestcity')
                if(state.deliveryType.value == 'Home Delivery' && (!state.receiver.pin || toString(state.receiver.pin).length == 6)) throw new BookingError('Select Proper Destination first', 'receiverPincode')
                const { bookingUpdate } = state
                let packages = []
                let { id, name } = action.payload
                switch (name) {
                    case 'stack': {
                        state.packages.forEach(p => {
                            console.log('for package : ', p)
                            if (p.id == id) {
                                let temp = Object.assign({}, p)
                                temp.stack = !p.stack
                                packages.push(temp)
                            }
                            else packages.push(p)
                        })
                        break
                    }
                    case 'frag': {
                        state.packages.forEach(p => {

                            console.log('for package : ', p)
                            if (p.id == id) {
                                let temp = Object.assign({}, p)
                                temp.frag = !p.frag
                                packages.push(temp)
                            }
                            else packages.push(p)
                        })
                        break
                    }
                    case 'haz': {
                        state.packages.forEach(p => {

                            console.log('for package : ', p)
                            if (p.id == id) {
                                let temp = Object.assign({}, p)
                                temp.haz = !p.haz
                                packages.push(temp)
                            }
                            else packages.push(p)
                        })
                        break
                    }
                    case 'size': {
                        const newSize = action.payload.val.value
                        state.packages.forEach(p => {
                            console.log('for package : ', p)
                            const units = [
                                { label: 'Mt', value: 'm' },
                                { label: 'Cm', value: 'cm' },
                                { label: 'Inch', value: 'in' }
                            ]
                            if (p.id == id) {
                                let temp = Object.assign({}, p)
                                temp.size = action.payload.val
                                if (action.payload.val != '') {
                                    const { sizes } = state.company
                                    sizes.forEach(size => {
                                        if (size.name == newSize) {
                                            console.log('size matched')
                                            let { l, b, h } = size
                                            temp.dimension = { l, b, h }
                                            temp.weight = size.weight
                                            units.map(u => {
                                                if (u.value == size.unit) {
                                                    console.log('u: ', u);
                                                    temp.unit = u
                                                }
                                            })
                                        }
                                    })
                                }
                                console.log('temp: ', temp);
                                packages.push(temp)
                            }
                            else packages.push(p)
                        })
                        break
                    }
                    case 'mat': {
                        state.packages.forEach(p => {

                            console.log('for package : ', p)
                            if (p.id == id) {
                                let temp = Object.assign({}, p)
                                temp.materialType = action.payload.val
                                packages.push(temp)
                            }
                            else packages.push(p)
                        })
                        break
                    }
                    case 'pack': {
                        state.packages.forEach(p => {

                            console.log('for package : ', p)
                            if (p.id == id) {
                                let temp = Object.assign({}, p)
                                temp.packingType = action.payload.val
                                packages.push(temp)
                            }
                            else packages.push(p)
                        })
                        break
                    }
                    case 'qty': {
                        state.packages.forEach(p => {

                            console.log('for package : ', p)
                            if (p.id == id) {
                                let temp = Object.assign({}, p)
                                temp.qty = action.payload.val
                                packages.push(temp)
                            }
                            else packages.push(p)
                        })
                        break
                    }
                    case 'l': {
                        state.packages.forEach(p => {

                            console.log('for package : ', p)
                            if (p.id == id) {
                                let temp = Object.assign({}, p),
                                    dimension = Object.assign({}, temp.dimension)
                                dimension.l = action.payload.val
                                temp.dimension = dimension
                                packages.push(temp)
                            }
                            else packages.push(p)
                        })
                        break
                    }
                    case 'b': {
                        state.packages.forEach(p => {

                            console.log('for package : ', p)
                            if (p.id == id) {
                                let temp = Object.assign({}, p),
                                    dimension = Object.assign({}, temp.dimension)
                                dimension.b = action.payload.val
                                temp.dimension = dimension
                                packages.push(temp)
                            }
                            else packages.push(p)
                        })
                        break
                    }
                    case 'h': {
                        state.packages.forEach(p => {
                            console.log('for package : ', p)
                            if (p.id == id) {
                                let temp = Object.assign({}, p),
                                    dimension = Object.assign({}, temp.dimension)
                                dimension.h = action.payload.val
                                temp.dimension = dimension
                                packages.push(temp)
                            }
                            else packages.push(p)
                        })
                        break
                    }
                    case 'u': {
                        console.log('\n\ngot to change unit : ', action.payload)
                        let { val, id } = action.payload
                        packages = state.packages.map(item => item.id == id ? ({ ...item, unit: val }) : item)
                        break
                    }
                    case 'w': {
                        state.packages.forEach(p => {
                            console.log('for package : ', p)
                            if (p.id == id) {
                                let temp = Object.assign({}, p)
                                temp.weight = parseInt(action.payload.val, 10)
                                packages.push(temp)
                            }
                            else packages.push(p)
                        })
                        break
                    }
                    case 'rate': {
                        let resPackages = []
                        state.packages.forEach((p, index) => {
                            console.log('for package : ', p, ' condition : ', p.id == id)
                            if (p.id == id) {
                                let temp = Object.assign({}, p)
                                temp.rate = action.payload.val
                                resPackages.push(temp)
                            }
                            else resPackages.push(p)
                        })
                        packages = resPackages
                        break
                    }
                    case 'amount': {
                        const { val } = action.payload, amt = parseInt(val)
                        let resPackages = []
                        state.packages.forEach((p, index) => {
                            console.log('for package : ', p, ' condition : ', p.id == id)
                            if (p.id == id) {
                                resPackages.push({ ...p, amount: isNaN(amt) ? '' : amt })
                            }
                            else resPackages.push(p)
                        })
                        packages = resPackages
                        break
                    }
                }
                if (!state.packages || state.packages.length == 0) return state
                let freight = state.fr
                console.log('final packages : ', packages)

                const { handling, bilty } = state.company
                let hf = countHandling(packages, handling, state.across)
                let bf = 0
                freight = parseInt(freight, 10)
                bf = countBuilty(bilty, state.across)
                console.log('got bf : ', bf)

                return {
                    ...state,
                    fr: freight,
                    hf: hf,
                    bf: bf,
                    gross: freight + hf + bf,
                    packages,
                    bookingUpdate: {
                        ...bookingUpdate,
                        packages: true,
                        payment: true
                    }
                }
            }
            catch (err) {
                if (err instanceof BookingError) {
                    console.log('got the correct one')
                    showMessage(err.message, failed)
                }
                console.log(err, ' is ', err instanceof BookingError)
                return state
            }
        }
        case 'BOOK_ADD_PACKAGE': {
            console.log('add package payload : ', action.payload)
            const { bookingUpdate } = state
            const packages = state.packages.slice()
            packages.push(action.payload)
            return {
                ...state,
                packages,
                bookingUpdate: {
                    ...bookingUpdate,
                    packages: true
                }
            }
        }
        case 'BOOK_DEL_PACKAGE': {
            try {
                let { handling } = state.company
                let { id } = action.payload,
                    packages = state.packages.filter(p => (p.id != id)).map((p, idx) => ({ ...p, id: idx }))
                let hf = countHandling(packages, handling, state.across)
                console.log('BOOK_DEL_PACKAGE: ', id);
                const { bookingUpdate } = state
                return {
                    ...state,
                    packages: packages,
                    hf,
                    bookingUpdate: {
                        ...bookingUpdate,
                        packages: true,
                        payment: true
                    }
                }
            }
            catch (err) {
                console.log(err)
                return state
            }
        }
        case 'SET_ACROSS': {
            if (typeof action.payload !== 'string') return state
            const { bookingUpdate } = state
            let msg = acrossFun(action.payload)
            console.log('msg got : ', msg)
            msg += ' charges will be calculated'
            showMessage(msg, success)
            return {
                ...state,
                across: action.payload,
                bookingUpdate: { ...bookingUpdate, packages: true }
            }
        }
        case 'BOOK_FR_CHANGE': {
            try {
                if (!state.packages || state.packages.length == 0) return state
                console.log('fr change payload: ', action.payload)
                let { freight } = action.payload
                let nop = 0
                state.packages.forEach(p => {
                    nop += parseInt(p.qty, 10)
                })

                let rate = state.bookingRate
                let hf = parseInt(countHandling(nop, rate, state.company.handling, state.across, action.payload.freight), 10)
                let bf = 0
                freight = parseInt(freight, 10)
                if (state.across == 'interState') bf = parseInt(state.company.bilty.interState, 10)
                else if (state.across == 'intraState') bf = parseInt(state.company.bilty.intraState, 10)
                return {
                    ...state,
                    fr: freight,
                    hf: hf,
                    bf: bf,
                    gross: freight + hf + bf
                }
            }
            catch (err) {
                console.log(err)
                return state
            }
        }
        case 'FILTER_ROUTES_BY_BRANCH': {
            if (action.payload == '') return { ...state, suggFilroutes: state.suggroutes }
            let branch = action.payload
            console.log('we got branch')
            let result = [],
                taken = {}
            console.log('state.suggroutes: ', state.suggroutes);
            if (!Array.isArray(state.suggroutes)) return state
            for (let j = 0; j < state.suggroutes.length; j++) {
                let route = state.suggroutes[j]

                let dest = route.destination
                for (let i = 0; i < dest.length; i++) {
                    let d = dest[i]
                    if (d.branch == branch && d.unloading) {
                        console.log('branch found')
                        if (!taken[route._id]) {
                            taken[route._id] = 1
                            result.push(route)
                        }
                        break
                    }
                }
            }
            if (result.length > 0)
                return {
                    ...state,
                    suggFilroutes: result
                }
            else return {
                ...state,
                suggFilroutes: null
            }
        }
        case 'SUGG_PRICE': {
            return { ...state, suggPrice: action.payload }
        }
        case 'SUGG_BOOK_ROUTES': {
            if (!action.payload) return state
            return {
                ...state,
                suggroutes: action.payload.slice(),
                suggFilroutes: action.payload.slice()
            }
        }
        case 'SUGG_BOOK_RATE': {
            if (!action.payload) return state
            let { cat, arr } = action.payload
            return { ...state, routeSugg: { cat: cat, arr: arr } }
        }
        case 'ROUTE_RATE': {
            let { byWeight, perPackage } = action.payload
            return {
                ...state,
                routeSugg: {
                    byWeight: byWeight,
                    perPackage: perPackage
                }
            }
        }
        case 'GEN_BUILTY': {
            try {
                let {
                    packages,
                    from,
                    to,
                    sname,
                    rname,
                    scontact,
                    rcontact,
                    sgst,
                    rgst,
                    rl1,
                    rl2,
                    rpin,
                    rcity,
                    freight,
                    bf,
                    hf,
                    ins,
                    risk
                } = action.payload
                let weight = 0
                packages.forEach(p => {
                    let w = parseInt(p.weight, 10),
                        qty = parseInt(p.qty, 10)
                    console.log(`package w: ${w} and qty : ${qty}`)

                    if (!isNaN(w)) {
                        weight += w * qty
                    }
                    else {
                        state.company.sizes.forEach(sz => {
                            if (sz.name == p.size) {
                                weight += parseInt(sz.weight, 10) * qty
                            }
                        })
                    }
                })
                let colFinal = 0, insFinal = 0, packFinal = 0
                if (state.isCol && !isNaN(parseInt(state.colValue, 10))) {
                    console.log('add col charge')
                    colFinal = parseInt(state.colValue, 10)
                }
                if (state.risk != '0' && !isNaN(parseInt(state.insValue, 10))) {
                    console.log('add ins charge')
                    insFinal = parseInt(state.insValue, 10)
                }
                if (state.customPack !== 'null' && !isNaN(parseInt(state.packValue, 10))) {
                    console.log('add packing charge')
                    packFinal = parseInt(state.packValue, 10)
                }
                console.log('\ncol : ', colFinal, '\nins : ', insFinal, '\npack : ', packFinal)

                let gross = state.fr + state.bf + state.hf + colFinal + insFinal + packFinal

                let desc = ''
                state.packages.forEach(p => {
                    desc += p.qty + p.materialType + '\n' + p.packingType + '\n'
                })

                return {
                    ...state,
                    fromAddr: from,
                    toAddr: to,
                    senderName: sname,
                    receiverName: rname,
                    senderMobile: scontact,
                    receiverMobile: rcontact,
                    senderGST: sgst,
                    receiverGST: rgst,
                    packages: packages,
                    bookdesc: desc,
                    recvAddr: rl1 + rl2 + ' - ' + rpin + ', ' + (rcity ? rcity : ''),
                    weight: weight,
                    fr: freight,
                    bf: bf,
                    hf: hf,
                    gross: gross,
                    insurance: ins,
                    updateBuilty: true
                }
            }
            catch (err) {
                console.log(err)
                return state
            }
        }
        case 'SET_LR': {
            showMessage("Booking Successfull", success)
            return {
                ...state,
                LR: action.payload,
                updateBuilty: true
            }
        }
        case 'SET_ORANGE_LR': {
            return {
                ...state,
                orangeLR: action.payload,
                updateBuilty: true
            }
        }
        case 'RESET_BOOK': {
            return {
                ...state,
                redirect: true,
                destination: '/dashboard',
                fromAddr: null,
                toAddr: null,
                senderName: null,
                receiverName: null,
                pickupCharge: 0,
                deliveryCharge: 0,
                senderMobile: null,
                receiverMobile: null,
                senderGST: null,
                receiverGST: null,
                packages: null,
                bookdesc: null,
                recvAddr: null,
                weight: null,
                fr: null,
                bf: null,
                hf: null,
                gross: null
            }
        }
        case 'SET_BOOK': {
            return { ...state, redirect: false, destination: null }
        }
        case 'BOOK_BRANCHES': {
            let { payload } = action
            console.log('got payload : ', action.payload)
            try {
                let { bookingUpdate } = state
                let cityMap = new Map(payload.filter(p => p.address && p.address.city).map(p => ([
                    p.address.city._id,
                    p.address.city.name
                ])))

                let cities = []
                for (let entry of cityMap.entries()) {
                    console.log('entry is : ', entry)
                    cities.push({ name: entry[1], _id: entry[0] })
                }

                cities.sort((a, b) => a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1)

                return {
                    ...state,
                    filbranches: action.payload.filter(br => (br && br.operation && br.operation.delivery)),
                    bookCities: cities,
                    bookingUpdate: { ...bookingUpdate, destCity: true },
                }
            }
            catch (err) {
                console.log(err)
                return state
            }
        }
        case 'BOOK_CHANGE_INS': {
            let booking = state.booking ? Object.assign({}, state.booking) : {}
            booking.insurance = action.payload
            return {
                ...state,
                booking
            }
        }
        case 'BOOK_CHANGE_DELIVERY_TYPE': {
            return {
                ...state,
                deliveryType: action.payload,
            }
        }
        case 'BOOK_CHANGE_FEDEX_SERVICE_TYPE':{
            return {
                ...state,
                fedexServiceType: action.payload
            }
        }
        case 'SET_FEDEX_SERVICE_OPTIONS':{
            return {
                ...state,
                fedexServiceTypeOptions: action.payload
            }
        }
        case 'BOOK_DEST_CITY': {
            const { bookingUpdate } = state
            return {
                ...state,
                bookingUpdate: { ...bookingUpdate, destCity: true },
                bookdestcity: action.payload,
                bookdestbranch: null,
                bookTouches: state.bookTouches.setIn(['bookdestcity'], true),
                packages: initState.packages,
                bookingUpdate: {
                    ...state.bookingUpdate,
                    packages: true,
                    destCity: true
                }
            }
        }
        case 'BOOK_DEST_BRANCH': {
            let { value, company } = action.payload
            console.log("got company:--------",company);
            
            let {
                filbranches,
                bookRoutes,
                company: ownCompany,
                loginType,
                opBranch
             } = state
            let branch = filbranches.find(b => b._id === value)
            console.log('got branch: ', branch)
            let receiverAddress = branch.address
            if (!branch || !branch.address || !branch.address.city) return state
            let additionalChange = {}

            // if(branch.company._id == ownCompany._id && loginType == 'B'){
            if(loginType == 'B'){
                console.log("finding routes:----------------------");
                console.log("source:--",opBranch._id);
                console.log("desttination:---",value);             
                
                console.log("bookRoutes:--",bookRoutes);
                
                let routes = bookRoutes.filter(r => {
                    if(r.destination.length == 2){
                        console.log("got 2 dests:",r.destination);
                        
                    }
                    let ind1 = r.destination.findIndex((val,a,b)=> {
                        console.log("got destination:",val);
                        return (opBranch._id == val.branch && val.loading == true)
                    })
                    let ind2 = r.destination.findIndex((val,a,b)=> {
                        return (value == val.branch && val.unloading == true)
                    })
                    console.log("got inds:--",ind1,ind2);
                    
                    return (ind1 != -1 && ind2 != -1 && ind2 > ind1) ? true : false
                })
                console.log("found routes:----------------------",routes);
                if(routes && routes.length > 0)
                additionalChange.bookroute = {
                    label: routes[0].name,
                    value: routes[0]._id
                }
            }
            try{
                if(!additionalChange.bookroute){
                    if (company && company.companyName && company.companyName.match(/Orange Logistics PLTD./i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/Orange General/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if (company && company.companyName && company.companyName.match(/Kabra Official/i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/Kabra General/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if (company && company.companyName && company.companyName.match(/S.R.E./i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/SRE GENERAL/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if (company && company.companyName && company.companyName.match(/EAGLE CONNECT/i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/EAGLE FALCON GENERAL/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if (company && company.companyName && company.companyName.match(/Shree Jalaram Viral Travels/i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/Viral General/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if (company && company.companyName && company.companyName.match(/Time India Express Cargo Service/i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/Times general/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if(company && company._id && isArrayCheck(bookRoutes)){
                        let bookroute = bookRoutes.find(r => (r.name.match(/general/i) && r.company == company._id))
                        if(bookroute){
                            additionalChange.bookroute = {
                                label: bookroute.name,
                                value: bookroute._id
                            }
                        }
                    }
                }
                if(!additionalChange.bookroute){
                    if (ownCompany && ownCompany.companyName && ownCompany.companyName.match(/Orange/i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/Orange General/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if (ownCompany && ownCompany.companyName && ownCompany.companyName.match(/Kabra Official/i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/Kabra General/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if (company && company.companyName && company.companyName.match(/S.R.E./i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/SRE GENERAL/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if (ownCompany && ownCompany.companyName && ownCompany.companyName.match(/EAGLE CONNECT/i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/EAGLE FALCON GENERAL/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if (ownCompany && ownCompany.companyName && ownCompany.companyName.match(/Shree Jalaram Viral Travels/i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/Viral General/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if (ownCompany && ownCompany.companyName && ownCompany.companyName.match(/Time India Express Cargo Service/i) && isArrayCheck(bookRoutes)) {
                        let bookroute = bookRoutes.find(r => r.name.match(/Times general/i))
                        additionalChange.bookroute = {
                            label: bookroute.name,
                            value: bookroute._id
                        }
                    }
                    if(ownCompany && ownCompany._id && isArrayCheck(bookRoutes)){
                        let bookroute = bookRoutes.find(r => (r.name.match(/general/i) && r.company == ownCompany._id))
                        if(bookroute){
                            additionalChange.bookroute = {
                                label: bookroute.name,
                                value: bookroute._id
                            }
                        }
                    }
                }                
                if(!additionalChange.bookroute){
                    let bookroute = bookRoutes.find(r => r.name.match(/Kabra General/i))
                    additionalChange.bookroute = {
                        label: bookroute.name,
                        value: bookroute._id
                    }
                }
            }catch(err){console.log(err);
            }

            if(!additionalChange.bookroute){
                console.log("got company:-",company);
                // dont let book without routes
                window.alert("Consult TapTap Support: NO ROUTE AVAILABLE")
                return {
                    ...state
                }
            } 
            
            console.log("reciever-----", receiverAddress, '\nbranch found : ', branch)
            let receiver = {
                ...state.receiver,
                l1: receiverAddress.l1,
                l2: receiverAddress.l2,
                pin: receiverAddress.pincode,
                city: receiverAddress.city.name
            }
            return {
                ...state,
                receiver,
                receiverBranchAddress: receiverAddress,
                bookdestbranch: action.payload,
                bookdestcity: {
                    label: branch.address.city.name,
                    value: branch.address.city._id
                },
                bookTouches: state.bookTouches.setIn(['bookdestbranch'], true),
                receiverAddressLock: true,
                bookingUpdate: {
                    ...state.bookingUpdate,
                    destCity: true
                },
                ...additionalChange
            }
        }
        case "BOOK_DEST_ROUTE": {
            const { value } = action.payload
            const { bookRoutes, company } = state
            let ownBooking = true
            if (Array.isArray(bookRoutes) && bookRoutes.length > 0) {
                for (let i = 0; i < bookRoutes.length; i++) {
                    let route = bookRoutes[i]
                    if (route._id == value) {
                        if (route.company != company.id) {
                            ownBooking = false
                        }
                    }
                }
            }
            else return state
            return {
                ...state,
                ownBooking,
                bookroute: action.payload,
                bookTouches: state.bookTouches.setIn(['bookroute'], true)
            }
        }
        case 'RESET_EDIT': {
            let exclude = {}
            if (action.payload) {
                exclude = action.payload.exclude
            }
            exclude.filbranches = 1
            const retain = {}
            if (exclude) {
                Object.keys(exclude).forEach(key => retain[key] = state[key])
            }
            console.log('retain : ', retain)
            const bookingUpdate = {
                packages: true,
                destCity: true,
                payment: true
            }
            let sender = {
                name: '',
                contact: '',
                gst: '',
                l1: '',
                l2: '',
                pin: '',
                city: ''
            },
                receiver = {
                    name: '',
                    contact: '',
                    gst: '',
                    l1: '',
                    l2: '',
                    pin: '',
                    city: ''
                }
            let resPackages = []
            let otherValues = {
                LR: '',
                orangeLR: '',
                hf: "",
                docketNumber: '',
                bf: "",
                fr: "",
                risk: '0',
                ins: '',
                insValue: 0,
                isCol: false,
                howcollect: '',
                pickupCharge: 0,
                deliveryCharge: 0,
                col: 0,
                colValue: 0,
                packValue: 0,
                colMonth: 0,
                customPack: 'null',
                bookingRate: 0,
                bookingMode: null,
                goods: '',
                docketStatus: false,
                bill: '',
                purpose: { label: 'Commercial', value: 'C' },
                bookingStatus: true,
                franchiseNumber: null
            }
            if (state.loginType == 'B' && state.opBranch && state.opBranch.address) {
                let { address } = state.opBranch
                console.log('state.allcities: ', state.allcities);
                let city = ''
                if (state.allcities) {
                    city = state.allcities.find(c => c._id == address.city)
                    if (city) {
                        city = city.name
                    }
                }
                let sender = {
                    name: null,
                    gst: null,
                    contact: '',
                    l1: address.l1,
                    l2: address.l2,
                    pin: address.pincode,
                    city: city
                }
                otherValues.sender = sender
                otherValues.senderAddressLock = true
            }
            return {
                ...state,
                orangeLR: null,
                resetPackageErrors: true,
                receiverGstInvalid: false,
                senderGstInvalid: false,
                ewb: '',
                sender, receiver,
                bookingUpdate,
                packages: resPackages,
                editDocket: false,
                isEwayBill: false,
                deliveryType: {value: "Branch To Branch",label:"Branch To Branch"},
                bookdestcity: '',
                bookdestbranch: '',
                receiverGst: false,
                senderGst: false,
                bookroute: '',
                bookErrors: Map(),
                bookTouches: Map(),
                specificDelivery: false,
                delDocket: {
                    docket: {
                        status: true,
                    }
                },
                packages: [{
                    id: 0,
                    qty: '',
                    materialType: '',
                    packingType: '',
                    size: 'custom',
                    dimension: { l: '', b: '', h: '' },
                    weight: '',
                    rate: 'Direct',
                    unit: null,
                    amount: '',
                    stack: false,
                    haz: false,
                    frag: false
                }],
                ...otherValues,
                ...retain,
                resetBooking: true,
                bookingTime: null
            }
        }
        case SET_PACKAGE_ERROR_FLAG_FALSE: {
            if (state.resetPackageErrors === true)
                return {
                    ...state,
                    resetPackageErrors: false,
                }
            else { return state }
        }
        case 'BOOK_PAY_MODE': {
            const { bookingUpdate } = state
            return {
                ...state,
                bookingMode: action.payload,
                bookingUpdate: { ...bookingUpdate, payment: true }
            }
        }
        case 'CREDIT_NUM_CHANGE': {
            const { bookingUpdate } = state
            return {
                ...state,
                creditNumber: action.payload,
                bookingUpdate: {
                    ...bookingUpdate,
                    payment: true
                }
            }
        }
        case 'BOOK_CREDIT_SET': {
            const { bookingUpdate } = state
            return {
                ...state,
                bookingCredit: action.payload,
                bookingUpdate: {
                    ...bookingUpdate,
                    payment: true
                }
            }
        }
        case 'BOOK_CREDIT_RESET': {
            return { ...state, bookingCredit: initCredit }
        }
        case SET_SENDER_ADDRESS_FROM_OPBRANCH: {
            if (!state.opBranch || !state.opBranch.address)
                return state
            let { address } = state.opBranch
            console.log('state.allcities: ', state.allcities);
            let city = ''
            if (state.allcities) {
                city = state.allcities.find(c => c._id == address.city)
                if (city) {
                    city = city.name
                }
            }
            let sender = {
                ...state.sender,
                l1: address.l1,
                l2: address.l2,
                pin: address.pincode,
                city: city
            }
            return {
                ...state,
                sender,
                senderAddressLock: true
            }
        }
        case SET_RECEIVER_ADDRESS_FROM_OPBRANCH: {
            let address = state.receiverBranchAddress
            console.log('receiver address: ', address);
            let receiver = {
                ...state.receiver,
                l1: address.l1,
                l2: address.l2,
                pin: address.pincode,
                city: address.city ? address.city.name : ''
            }
            return {
                ...state,
                receiver,
                receiverAddressLock: true
            }
        }
        case SET_SENDER_ADDRESS_FROM_OTHER: {
            const address = action.payload
            console.log('address: ', address, action.payload);
            const sender = state.sender
            let newSender = {
                ...sender,
                city: (address.city.length == 24) ? state.allcities.find(c=>(c._id==address.city)).name : address.city ,
                l1: address.l1,
                l2: address.l2,
                pin: address.pin
            }
            return {
                ...state,
                sender: newSender
            }
        }
        case SET_RECEIVER_ADDRESS_FROM_OTHER: {
            const address = action.payload
            console.log('address: ', address, action.payload);
            const receiver = state.receiver
            let newReceiver = {
                ...receiver,
                city: (address.city.length == 24) ? state.allcities.find(c=>(c._id==address.city)).name : address.city,
                l1: address.l1,
                l2: address.l2,
                pin: address.pin
            }
            return {
                ...state,
                receiver: newReceiver
            }
        }
        case 'CHANGE_TO_CREDIT_SUCCESS': {
            showMessage('Payment mode changed to credit!', success)
            let { credit, docket } = action.payload
            let delDocket = Object.assign({}, state.delDocket)
            delDocket.docket = docket
            return {
                ...state,
                credit: credit.response.credit,
                delDocket
            }
        }
        case 'DELIVERED_AT_BRANCH': {
            showMessage("Docket delivered Successfully", success)
            return state
        }
        case 'BOOK:VALIDATION_ERROR': {
            const { path, message } = action.payload
            let oldMessage = state.bookErrors.getIn(path)
            if (oldMessage != message || oldMessage.length != message.length) {
                return { ...state, bookErrors: state.bookErrors.setIn(path, message) }
            }
            return state
        }
        case 'SPECIFIC_DELIVERY': {
            if (state.editDocket &&
                state.delDocket &&
                state.delDocket.docket &&
                state.delDocket.docket.delSpecific === true) {
                return state
            }

            return {
                ...state,
                specificDelivery: !state.specificDelivery
            }
        }
        case 'BOOK:SET_SENDER_BY_GST': {
            const { trade_name, address1: l1, address2: l2, pincode } = action.payload
            return {
                ...state,
                sender: {
                    ...state.sender,
                    name: toRSOption(trade_name),
                },
                bookTouches: state.bookTouches.setIn(['sender', 'name'], true),
                senderGstInvalid: false,
                senderGst: true
            }
        }
        case 'BOOK:SET_RECEIVER_BY_GST': {
            const { trade_name, address1: l1, address2: l2, pincode } = action.payload
            console.log('action.payload: ', action.payload);

            let { otherAddr, name } = state.receiver
            console.log('otherAddr: ', otherAddr, name);
            return {
                ...state,
                receiver: {
                    ...state.receiver,
                    name: toRSOption(trade_name),
                },
                bookTouches: state.bookTouches.setIn(['receiver', 'name'], true),
                receiverGstInvalid: false,
                receiverGst: true
            }
        }
        case 'BOOK:SERVER_ERROR': {
            try {
                const { reasons } = action.payload
                if (Array.isArray(reasons) && reasons[0]) {
                    showMessage(reasons[0].message, failed)
                }
            }
            catch (err) {
                console.log(err)
            }
            return state
        }
        case BUILTY_UPDATE_ACK: {
            return { ...state, updateBuilty: false }
        }
        case BUILTY_UPDATE_REQ: {
            return { ...state, updateBuilty: true }
        }
        case PACKAGE_UPDATE_ACK: {
            const { bookingUpdate } = state
            return { ...state, bookingUpdate: { ...bookingUpdate, packages: false } }
        }
        case DEST_CITY_UPDATE_ACK: {
            const { bookingUpdate } = state
            return { ...state, bookingUpdate: { ...bookingUpdate, destCity: false } }
        }
        case PAYMENT_UPDATE_ACK: {
            const { bookingUpdate } = state
            return {
                ...state,
                bookingUpdate: { ...bookingUpdate, payment: false },
                resetBooking: false
            }
        }
        case ID_FETCH_ACK: {
            return { ...state, idFetch: false }
        }
        case BILL_FETCH_ACK: {
            return { ...state, billFetch: false }
        }
        case DOCKETS_FETCH_ACK: {
            return {
                ...state,
                docketsFetch: false
            }
        }
        case SENDER_GST_INVALID: {
            return {
                ...state,
                senderGstInvalid: true
            }
        }
        case RECEIVER_GST_INVALID: {
            return {
                ...state,
                receiverGstInvalid: true
            }
        }
        case RESET_RECEIVER_ADDRESS: {
            console.log(' iam working');
            return {
                ...state,
                receiver: {
                    ...state.receiver,
                    l1: '',
                    l2: '',
                    pin: '',
                    city: ''
                },
                receiverAddressLock: false
            }
        }
        case SHOW_LOADER: {
            return {
                ...state,
                loading: true
            }
        }
        case SHOW_LOADER_WITHOUT_BLUR: {
            return {
                ...state,
                loadingWithOutBlur: true
            }
        }
        case HIDE_LOADER_WITHOUT_BLUR: {
            return {
                ...state,
                loadingWithOutBlur: false
            }
        }
        case HIDE_LOADER: {
            return {
                ...state,
                loading: false
            }
        }
        case SHOW_EWAYBILL_LOADER: {
            return {
                ...state,
                ewayBillLoading: true
            }
        }
        case HIDE_EWAYBILL_LOADER: {
            return {
                ...state,
                ewayBillLoading: false
            }
        }
        case SHOW_GST_LOADER: {
            return {
                ...state,
                gstLoading: true
            }
        }
        case HIDE_GST_LOADER: {
            return {
                ...state,
                gstLoading: false
            }
        }
        case CREDIT_NUMBER_HIDE_LOADER: {
            return {
                ...state,
                creditNumberLoading: false
            }
        }
        case CREDIT_NUMBER_SHOW_LOADER: {
            return {
                ...state,
                creditNumberLoading: true
            }
        }
        case SHOW_GST_LOADING_FOR_RECEIVER: {
            return {
                ...state,
                gstLoadingReceiver: true
            }
        }
        case HIDE_GST_LOADING_FOR_RECEIVER: {
            let { gstLoadingReceiver } = state
            if (gstLoadingReceiver === true)
                return {
                    ...state,
                    gstLoadingReceiver: false
                }
            else {
                return state
            }
        }
        case NO_DATA: {
            showMessage('Please Check And Try Again', failed)
            return state
        }
        case RESET_SENDER_ADDRESS: {
            return {
                ...state,
                sender: {
                    ...state.sender,
                    l1: '',
                    l2: '',
                    pin: '',
                    city: ''
                },
                senderAddressLock: false
            }
        }
        case SET_ROUTES: {
            try {
                const { franchise, own } = action.payload
                const routes = franchise.map(r => ({ ...r, isFranchise: true })).concat(own)
                return {
                    ...state,
                    bookRoutes: routes
                }
            }
            catch (err) {
                console.log(err)
                return state
            }
        }
        case TOGGLE_FRANCHISE: {
            return {
                ...state,
                giveCommission: !state.giveCommission
            }
        }
        case CHANGE_FRANCHISE_NO: {
            return {
                ...state,
                franchiseNumber: action.payload,
                driverFranchise: {}
            }
        }
        case GIVE_COMMISSION: {
            return {
                ...state,
                driverFranchise: action.payload,
                franchiseLoading: false
            }
        }
        case FRANCHISE_LOADING: {
            return {
                ...state,
                franchiseLoading: true
            }
        }
        case FRANCHISE_FAILED: {
            return {
                ...state,
                franchiseLoading: false
            }
        }
    }
}

export default reducer