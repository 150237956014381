import API from '../axios'
import assert from "assert"

const generic_error = "Something Went Wrong !"
/**
 * 
 * @param {string} url -example branch/manage 
 * @param {string} method - GET | POST | DELETE | PUT
 * @param {object} data - http body payload
 * @param {number=} level
 * 
 * @example
 * ServiceMaker('branch/manage','POST',{field1: 'fsdff',field2: 20})
 * 
 */

export const ServiceMaker = (url, method, data, level) => new Promise((resolve, reject) => {
    const processRequest = (res) => {
        assert.equal(res.data.status, 1)
        if (level == 0) resolve(res.data)
        resolve(res.data.response)
    }
    const handleError = (err) => {
        if (err instanceof assert.AssertionError) {
            reject(generic_error)
        }
        else {
            if(err && err.response && err.response.status == 401){
                // window.location.assign('/login')
                sessionStorage.removeItem('kabra:token')
                alert("Session timed out.")
            }
            reject((err && err.response && err.response.data)
             ? 
             err.response.data
             : ((err && err.response) ? (err.response) : err)
            )
        }
    }
    switch (method) {
        case 'GET': {
            API.get(url)
                .then(processRequest)
                .catch(handleError)
            break
        }
        case 'POST': {
            API.post(url, data)
                .then(processRequest)
                .catch(handleError)
            break
        }
        case 'PUT': {
            API.put(url, data)
                .then(processRequest)
                .catch(handleError)
            break
        }
        case 'DELETE': {
            API.delete(url, { data })
                .then(processRequest)
                .catch(handleError)
            break
        }
    }
})

// export const ServiceMaker = (url, method, data, level) => new Promise((resolve, reject) => {
//     switch (method) {
//         case 'GET': {
//             API.get(url)
//                 .then(res => {
//                     // showMessage("session timed out")
//                     assert.equal(res.data.status, 1)
//                     if (level == 0) resolve(res.data)
//                     resolve(res.data.response)
//                 })
//                 .catch(err => {
//                     if (err instanceof assert.AssertionError) {
//                         reject(generic_error)
//                     }
//                     else {
//                         throw err
//                     }
//                 })
//                 .catch(err =>{
//                     if(err && err.response && err.response.status == 401){
//                         // window.location.assign('/login')
//                         sessionStorage.removeItem('kabra:token')
                        
//                         alert("Session timed out.")

//                     }
//                     reject(err.response ? err.response.data : err.response)
//                 } )
//             break
//         }
//         case 'POST': {
//             API.post(url, data)
//                 .then(res => {
//                     assert.equal(res.data.status, 1)
//                     if (level == 0) resolve(res.data)
//                     resolve(res.data.response)
//                 })
//                 .catch(err => {
//                     if (err instanceof assert.AssertionError) {
//                         reject(generic_error)
//                     }
//                     else {
//                         throw err
//                     }
//                 })
//                 .catch(err =>{
//                     if(err && err.response && err.response.status == 401){
//                         sessionStorage.removeItem('kabra:token')
//                         alert("Session timed out.")
//                     }
//                     reject(err.response ? err.response.data : err.response)
//                 } )
//             break
//         }
//         case 'PUT': {
//             API.put(url, data)
//                 .then(res => {
//                     assert.equal(res.data.status, 1)
//                     if (level == 0) resolve(res.data)
//                     resolve(res.data.response)
//                 })
//                 .catch(err => {
//                     if (err instanceof assert.AssertionError) {
//                         reject(generic_error)
//                     }
//                     else {
//                         throw err
//                     }
//                 })
//                 .catch(err => {
//                     if(err && err.response && err.response.status == 401){
//                         sessionStorage.removeItem('kabra:token')
//                         alert("Session timed out.")
//                     }
//                     reject(err.response ? err.response.data : err)
//                 })
//             break
//         }
//         case 'DELETE': {
//             API.delete(url, { data })
//                 .then(res => {
//                     assert.equal(res.data.status, 1)
//                     if (level == 0) resolve(res.data)
//                     resolve(res.data.response)
//                 })
//                 .catch(err => {
//                     if (err instanceof assert.AssertionError) {
//                         reject(generic_error)
//                     }
//                     else {
//                         throw err
//                     }
//                 })
//                 .catch(err => {
//                     if(err && err.response && err.response.status == 401){
//                         sessionStorage.removeItem('kabra:token')
//                         alert("Session timed out.")
//                     }
//                     reject(err.response ? err.response.data : err)
//                 })
//             break
//         }
//     }
// })