import { fromJS, merge, List } from 'immutable'

import {
    GET_INCOME_TYPES,
    CHANGE_ADD_INCOME,
    GET_INCOME_TYPES_FAILED,
    GET_SUB_TYPES,
    POST_INCOME_RECORD_SUCCESS,
    CLEAR_ADD_INCOME,
    SET_INCOME_RECORD,
    RESET_INCOME_RECORD,
    DELETE_INCOME_RECORD,
    SET_ASSET_TYPES_BALANCE,
    SET_ASSET_TYPES,
    // SET_ASSET_TYPES_FOR_INCOME,
    EDIT_INCOME_RECORD_FAILED,
    POST_INCOME_RECORD_FAILED,
    EDIT_INCOME_RECORD_SUCCESS,
    SET_ASSET_TYPES_INCOME,
    REACT_SELECT_ERROR,
    CHECK_INCUR_INCOME_VALIDATION,
    SET_PAST_INCOMES,
    GET_ASSET_BAL,
    UPDATE_PAST_INCOMES,
    ADD_INCOME_TYPE,
    REMOVE_INCOME_TYPE
} from '../constants/income'
import { showMessage, failed } from './UserReducer'
import { SUCCESS, FAILED, INFO } from './helpers'
import {
    incur_Income_Boolean,
    incur_Income_Validation,
    validateIncome,
    final_Incur_Income_Validation
} from './schemas/addIncome'
import { isArrayCheck } from '../containers/generics/CheckArray';
import { toast } from 'react-toastify';

const initialState = fromJS({
    fetchedInputTypes: [],
    fetchedSubInputTypes: [],
    assetTypes: [],
    selectRefTypes: [],
    selectSubInputTypes: [],
    postIncomeRecord: {
        type: 'add',
        ref: '',
        assetType: "",
        amt: '',
        sub: '',
        sub_val: '',
        route: "",
        start_date: new Date(),
        end_date: new Date(),
        user: '',
        is_route: false,
        branch: '',
        category: "",
        non_cash: false,
        remarks: '',
        monthly_duration: false,
        // incomeRecordValid: false
    },
    assetBalances: [],
    postIncomeRecordErrors: { ...incur_Income_Boolean(true) },
    postIncomeRecordTouched: { ...incur_Income_Boolean(false) },
})

export default (state = initialState, action) => {
    switch (action.type) {

        case ADD_INCOME_TYPE: {
            return state.set('fetchedInputTypes', state.get('fetchedInputTypes').push(action.payload))
        }

        case REMOVE_INCOME_TYPE: {
            let id = action.payload
            return state.set('fetchedInputTypes', state.get('fetchedInputTypes').filter(i => i.id !== id))
        }

        case GET_INCOME_TYPES: {
            console.log('action.payload', action.payload)
            const mapfetchedInputTypes = isArrayCheck(action.payload) ?
                action.payload.map(t => ({ label: t.name, value: t.id })) : []

            return state
                .setIn(['fetchedInputTypes'], List(action.payload))
                .setIn(['selectRefTypes'], mapfetchedInputTypes)
        }

        case GET_INCOME_TYPES_FAILED: {
            showMessage('Could not fetch Income types !', FAILED)
            return state
        }

        case CHANGE_ADD_INCOME: {
            let { what, val } = action.payload
            let finalValue = val
            if (what == 'non_cash') {
                finalValue = !(state.getIn(['postIncomeRecord', 'non_cash']) === true)
            }
            console.log('action.payload: ', action.payload);
            return state
                .setIn(['postIncomeRecord', ...what], finalValue)
                .setIn(['postIncomeRecordErrors', ...what], validateIncome(incur_Income_Validation.getIn(what), finalValue))
                .setIn(['postIncomeRecordTouched', ...what], true)
        }

        case REACT_SELECT_ERROR: {
            let { what } = action.payload
            console.log('REACT_SELECT_ERROR: ', what);
            return state.setIn(['postIncomeRecordErrors', ...what], 'This is required field')
        }

        case CLEAR_ADD_INCOME: {
            return state
                .set('fetchedSubInputTypes', [])
                .set('selectSubInputTypes', [])
                .setIn(['postIncomeRecord', 'sub_val'], '')
                .setIn(['postIncomeRecord', 'assetType'], '')
        }

        case GET_SUB_TYPES: {
            console.log('fetchedSubInputTypes', action.payload);
            const selectBranches = isArrayCheck(action.payload) ? action.payload.map(e =>
                ({ label: e.branchName ? e.branchName : 'N/A', value: e._id ? e._id : '' })) : []
            return state
                .set('fetchedSubInputTypes', action.payload)
                .set('selectSubInputTypes', selectBranches)
        }

        case GET_ASSET_BAL: {
            const selectAssetBal = isArrayCheck(action.payload) ?
                action.payload.map(t => ({
                    label: t.uniqueId ? t.uniqueId : t.qty + ' ' + t.type.assetName,
                    value: t && t._id ? t._id : ''
                })) : []
            return state
                .set('fetchedSubInputTypes', action.payload)
                .set('selectSubInputTypes', selectAssetBal)
        }

        case SET_ASSET_TYPES: {
            let assetTypes = action.payload
            const mapAssetTypes = isArrayCheck(assetTypes) ? assetTypes.map(a => ({ label: a.assetName, value: a._id })) : []
            //here sub input is asset balance
            return state
                .set('assetTypes', assetTypes)
                .set('selectAssetTypes', mapAssetTypes)
        }
        case POST_INCOME_RECORD_SUCCESS: {
            showMessage('Income Record successfully Added', SUCCESS)
            let data = {
                type: 'add',
                ref: '',
                assetType: "",
                amt: '',
                sub: '',
                sub_val: '',
                is_route: false,
                route: "",
                start_date: new Date(),
                end_date: new Date(),
                user: '',
                branch: '',
                category: "",
                non_cash: false,
                remarks: '',
                monthly_duration: false,
                incomeRecordValid: false
            }
            return state.set('postIncomeRecord', data)
        }

        case RESET_INCOME_RECORD: {
            let data = {
                type: 'add',
                ref: '',
                assetType: "",
                no_of_seats:'',
                amt: '',
                sub: '',
                sub_val: '',
                route: "",
                is_route: false,

                start_date: new Date(),
                end_date: new Date(),
                user: '',
                branch: '',
                category: "",
                non_cash: false,
                remarks: '',
                monthly_duration: false,
                incomeRecordValid: false
            }
            return state
                .set('postIncomeRecord', data)
                .set('postIncomeRecordErrors', incur_Income_Boolean(true))
                .set('fetchedSubInputTypes', [])
                .set('assetTypes', [])
                .set('selectSubInputTypes', [])
        }
        case DELETE_INCOME_RECORD: {
            showMessage('Succssfully Deleted !', SUCCESS)
            let data = {
                type: 'add',
                ref: '',
                assetType: "",
                amt: '',
                sub: '',
                is_route: false,

                sub_val: '',
                route: "",
                start_date: new Date(),
                end_date: new Date(),
                user: '',
                branch: '',
                category: "",
                non_cash: false,
                remarks: '',
                monthly_duration: false,
                incomeRecordValid: false
            }
            return state.set('postIncomeRecord', data)
        }
        case POST_INCOME_RECORD_FAILED: {
            showMessage(action.payload, FAILED)
            return state
        }
        case EDIT_INCOME_RECORD_FAILED: {
            showMessage(action.payload, failed)
            return state
        }
        case EDIT_INCOME_RECORD_SUCCESS: {
            toast.success("Income Record Edited Successfully ")
            let data = {
                type: 'add',
                ref: '',
                assetType: "",
                amt: '',
                sub: '',
                sub_val: '',
                is_route: false,
                route: "",
                start_date: new Date(),
                end_date: new Date(),
                user: '',
                branch: '',
                category: "",
                non_cash: false,
                remarks: '',
                monthly_duration: false,
                incomeRecordValid: false
            }
            return state.set('postIncomeRecord', data)
        }
        case POST_INCOME_RECORD_FAILED: {
            showMessage("Couldn't Add Income Record", failed)
            return state
        }
        case SET_INCOME_RECORD: {
            console.log('got payload ');
            let data = action.payload
            data['type'] = 'edit'
            return state.set('postIncomeRecord', data)
        }
        case SET_PAST_INCOMES: {
            return state.set('pastIncomes', action.payload)
        }
        case UPDATE_PAST_INCOMES: {
            let {
                payload,
                options
            } = action
            let result
            if (options && options.remove === true)
                return state.set('pastIncomes', state.get('pastIncomes')
                    .filter(i => i.id != options.id))
                    .set('postIncomeRecord', initialState.get('postIncomeRecord'))
            if (options && options.update === true) {
                return state
                    .set('pastIncomes', state
                        .get('pastIncomes')
                        .map(i => {
                            if (i.id == options.id) {
                                return { ...i, amt: payload.amt }
                            }
                            return i
                        })
                    )
                    .set('postIncomeRecord', initialState.get('postIncomeRecord'))
            }
            return state.set('pastIncomes', [action.payload].concat(state.get('pastIncomes')))
        }
        default: {
            return state
        }
    }
}