const prefix = 'CUSTOMER:'

export const CHANGE_PERMITTED_BRANCH = prefix + 'CHANGE_PERMITTED_BRANCH'
export const CHANGE_CONNECTED_CUSTOMERS = prefix + 'CHANGE_CONNECTED_CUSTOMERS'
export const GET_CUSTOMER_LIST = prefix + 'GET_CUSTOMER_LIST'
export const CREDIT_FIELD_CHANGE = prefix + 'CREDIT_FIELD_CHANGE'
export const RATES_ADD = prefix + 'RATES_ADD'
export const RATES_DEL = prefix + 'RATES_DEL'
export const RATES_EDIT = prefix + 'RATES_EDIT'
export const GET_ROUTES = prefix + 'GET_ROUTES'
export const GET_MATERIAL_TYPES = prefix + 'GET_MATERIAL_TYPES'
export const NEW_CREDIT_SUBMIT = prefix + 'NEW_CREDIT_SUBMIT'
export const FETCH_DUE_CREDIT = prefix + 'FETCH_DUE_CREDIT'
export const SETTLE_DUES_SUCCESS = prefix + 'SETTLE_DUES_SUCCESS'
export const SETTLE_DUES_FAILED = prefix + 'SETTLE_DUES_FAILED'
export const CHANGE_PACKAGE_AMOUNT_SETTLEMENT = prefix + 'CHANGE_PACKAGE_AMOUNT_SETTLEMENT'
export const GET_INVOICE_DETAILS = prefix + 'GET_INVOICE_DETAILS'
export const CLEAR_RECEIPT_NUMBER = prefix + 'CLEAR_RECEIPT_NUMBER'
export const TOGGLE_CREDIT_ROW = prefix + 'TOGGLE_CREDIT_ROW'
export const CREDIT_UPDATED_SUCCESS = prefix + 'CREDIT_UPDATED_SUCCESS'
export const CHANGE_FIXED = prefix + 'CHANGE_FIXED'
export const CLOSE_CREDIT_PRINT = prefix + "CLOSE_CREDIT_PRINT"
export const USER_ALL_BALANCES = prefix + "USER_ALL_BALANCES"