import * as Yup from 'yup'
import {fromJS} from 'immutable'

export const validateCompanydoc = (what,value)=>{
    try{
        companyDocSchema.getIn([...what]).validateSync(value)
        return false
    }
    catch(err){
        console.log("validations errors",err);
        return (err.errors)?err.errors[0]:err
        
    }
}

export const validateWholeCompanydoc = (value)=>{
    try{
        wholeCompanyDocSchema.validateSync(value)
        return false
    }
    catch(err){
        console.log("validations errors",err);
        return (err.errors)?err.errors[0]:err
       
    }
}

export const companyDocSchema = fromJS({

    isNew: Yup.string(),
    _id: Yup.string(),
    individual: Yup.boolean().required(),
    companyName: Yup.string().when('individual', {
        is: (val) => val,
        then: Yup.string(),
        otherwise: Yup.string().required('This is a required field.')
      }),
    kind: Yup.string().required('This is a required field.'),
    url: Yup.string().url("Invalid url").nullable(),
    email: Yup.string().email("Invalid Email address").nullable(),

    contactPerson: {
        contact: Yup.string().required('This is a required field.'),
        name: {
            fName: Yup.string().required('This is a required field.'),
            lName: Yup.string().required('This is a required field.'),
        },
        password:Yup.string().when('isNew',{
            is:true,
            then:Yup.string().required('This is a required field.').min(6,"password is too short").max(16,"password is too short"),
            otherwise: Yup.string().nullable()
        }),
        email: Yup.string().email("Invalid Email address").nullable(),
    },

    address: {
        l1: Yup.string().required('This is a required field.'),
        l2: Yup.string().required('This is a required field.'),
        city: Yup.string().required('This is a required field.').min(24).max(24),
        pincode: Yup.number().default(0).required('This is a required field.').min(100000).max(999999),
    },

    bankAccount: Yup.string().nullable(),
    ifscCode: Yup.string().nullable(),

    licenseImage: Yup.string().nullable(),
    licenseExpiry: Yup.string().nullable(),

    panNumber: Yup.string().nullable(),
    panCardImage: Yup.string().nullable(),

    companyLogo: Yup.string().nullable(),

    userProfile: Yup.string().nullable(),
   
    active: Yup.string().nullable(),
    verified: Yup.string().nullable(),
})


export const wholeCompanyDocSchema = Yup.object().shape({



    companyName: Yup.string().when('individual', {
        is: false, 
        then: Yup.string().required('Enter Company name'),
      
      }),
    kind: Yup.string().when('individual', {
        is: false, 
        then: Yup.string().required('Select company type'),
      
      }),
    url: Yup.string().when('individual', {
        is: false, 
        then: Yup.string().url("Invalid company website URL").nullable(),
      
      }),
    email: Yup.string().when('individual', {
        is: false, 
        then: Yup.string().email("Invalid comapny Email address").nullable(),
      
      }),

    contactPerson: Yup.object().shape({
        contact: Yup.string().required('Enter user contact no.'),
        name: Yup.object().shape({
            fName: Yup.string().required('Enter firstname'),
            lName: Yup.string().required('Enter lastname'),
        }),
        password:Yup.string().when('isNew',{
            is:true,
            then:Yup.string().required('This is a required field.').min(6,"password is too short").max(16,"password is too short"),
            otherwise: Yup.string().nullable()
        }),
        email: Yup.string().email("Invalid Email address").nullable(),
    }),

    address: Yup.object().shape({
        l1: Yup.string().required('Enter line1 of address.'),
        l2: Yup.string().required('Enter line2 of address'),
        city: Yup.string().required('Enter City').min(24).max(24),
        pincode: Yup.number('pincode number required').required('Enter pincode.').min(100000,'Enter valid pincode.').max(999999,'Enter valid pincode.'),
    }),

    // bankAccount: Yup.string().nullable(),
    // ifscCode: Yup.string().nullable(),

    // licenseImage: Yup.string().when('individual', {
    //     is: true, 
    //     then: Yup.string().nullable(),
      
    //   }),
    // licenseExpiry: Yup.string().when('individual', {
    //     is: true, 
    //     then: Yup.string().nullable(),
      
    //   }),

    // panNumber: Yup.string().nullable(),
    // panCardImage: Yup.string().nullable(),

    // companyLogo: Yup.string().when('individual', {
    //     is: false, 
    //     then: Yup.string().notRequired().nullable(),
      
    //   }),

    // userProfile: Yup.string().when('individual', {
    //     is: true, 
    //     then: Yup.string().notRequired().nullable(),
      
    //   })
    
})
// export const schema = joi.object({
//     jwtUser: joi.string().alphanum().min(24).max(24).required(),
//     companyName: joi.string().min(3).max(50).required(),
//     kind: joi.when('individual', {
//         is: false,
//         then: joi.string().min(1).max(25).required(),
//         otherwise: joi.any().valid('?!@#2?')
//             .error(new Error('kind not allow when individual is true.')),
//     }),
//     contactPerson: {
//         contact: joi.number().integer().min(1000000000).max(999999999999).required(),
//         name: {
//             fName: joi.string().min(3).max(20).required(),
//             lName: joi.string().min(3).max(20).required(),
//         },
//         password: joi.string().alphanum().min(6).max(16).required(),
//         email: joi.string().email()
//     },
//     address: {
//         l1: joi.string().min(3).max(60).required(),
//         l2: joi.string().min(3).max(60).required(),
//         city: joi.string().alphanum().min(24).max(24).required(),
//         pincode: joi.number().integer().min(100000).max(999999).required(),
//     },
//     url: joi.when('individual', {
//         is: false,
//         then: joi.string(),
//         otherwise: joi.any().valid('?!@%#2?')
//             .error(new Error('Url not allow when individual is true.')),
//     }),
//     email: joi.when('individual', {
//         is: false,
//         then: joi.string().email(),
//         otherwise: joi.any().valid('?!@%*#2?')
//             .error(new Error('email not allow when individual is true.')),
//     }),
//     panNumber: joi.string(),
//     bankAccount: joi.number(),
//     ifscCode: joi.string().min(11).max(11),
//     individual: joi.boolean().required(),
//     licenseImage: joi.when('individual', {
//         is: true,
//         then: joi.string().required(),
//         otherwise: joi.any().valid('?!@%#2?')
//             .error(new Error('licenseImage not allow when individual is false.')),
//     }),
//     licenseExpiry: joi.when('individual', {
//         is: true,
//         then: joi.date().required(),
//         otherwise: joi.any().valid('?!@%#2?')
//             .error(new Error('licenseExpiry not allow when individual is false.')),
//     }),
//     panCardImage: joi.string().required(),
//     userProfile: joi.when('individual', {
//         is: true,
//         then: joi.string(),
//         otherwise: joi.any().valid('?!@%#2?')
//             .error(new Error('userProfile not allow when individual is false.')),
//     }),
//     companyLogo: joi.when('individual', {
//         is: false,
//         then: joi.string(),
//         otherwise: joi.any().valid('?!@%#2?')
//             .error(new Error('companyLogo not allow when individual is true.')),
//     }),
// })