import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSocketIoMiddleware from 'redux-socket.io'
import io from 'socket.io-client'
import { endPoint } from '../axios'
import logger from 'redux-logger'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'

import asset from '../reducers/asset'
import userReducer from '../reducers/UserReducer'
import companyReducer from '../reducers/company'
import incomeReducer from '../reducers/income'
import fleetReducer from '../reducers/fleet'
import rightsReducer from '../reducers/rights'
import franchiseReducer from '../reducers/franchise'
import franchiser from '../reducers/franchiser'
import tallyReducer from '../reducers/tally'
import userForm from '../reducers/user'
import routeReducer from '../reducers/route'

export const socket = io(endPoint)

const testing = process.env.NODE_ENV == 'test'

const id = setInterval(() => {
    socket.emit('refresh', sessionStorage.getItem('kabra:token'))
}, 5000)

socket.on('logout',()=>{
    clearInterval(id)
    console.log("gotta logout")
    window.location.reload()
})

const socketIOMiddleware = createSocketIoMiddleware(socket, 'server/')

const reducers = combineReducers({
    user: userReducer,
    company: companyReducer,
    income: incomeReducer,
    fleet: fleetReducer,
    userForm,
    asset,
    rights: rightsReducer,
    franchise: franchiseReducer,
    franchiser,
    tally: tallyReducer,
    route: routeReducer
})


const store = !testing ? createStore(reducers, composeWithDevTools(applyMiddleware(socketIOMiddleware, thunk, promise(), logger))) :
    createStore(reducers, composeWithDevTools(applyMiddleware(socketIOMiddleware, thunk, promise())))
// const store = composeWithDevTools(applyMiddleware(socketIOMiddleware, thunk, promise(), logger)(createStore)(reducers))

export default store
