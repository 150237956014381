export const procPackages = (packages) => {
    if (!packages) return null

    const packageMap = {}
    packages.forEach(p => {
        const {
            materialType,
            packingType,
            dimension,
            weight,
            rate,
            amount,
            stack,
            haz,
            frag
        } = p, { l, b, h } = dimension
        // console.log('materialType: ', materialType);
        // console.log('packingType: ', packingType);
        if (!materialType || !packingType) return null
        let uniqueId = [
            materialType._id,
            packingType._id,
            l, b, h,
            weight,
            rate,
            amount,
            stack,
            haz,
            frag
        ]
        let uid = uniqueId.join('@')
        // console.log('unique id : ', uid)
        if (!(uid in packageMap)) {
            packageMap[uid] = [p]
        }
        else packageMap[uid].push(p)
    })
    return packageMap
}