const prefix = 'FLEET:'

export const CHANGE_SIMPLE = prefix + 'CHANGE_SIMPLE'
export const FLEET_CREATED = prefix + 'FLEET_CREATED'
export const FLEET_UPDATED = prefix + 'FLEET_UPDATED'
export const SET_FLEETS = prefix + 'SET_FLEETS'
export const SET_FLEET = prefix + 'SET_FLEET'
export const SET_FRANCHISE_FLEETS = prefix + 'SET_FRANCHISE_FLEETS'
export const RESET = prefix + 'RESET'
export const FLEET_CREATE_SUCCESS = prefix + 'FLEET_CREATE_SUCCESS'
export const FLEET_CREATE_FAIL = prefix + 'FLEET_CREATE_FAIL'
export const FLEET_EDIT_SUCCESS = prefix + 'FLEET_EDIT_SUCCESS'
export const FLEET_EDIT_FAIL = prefix + 'FLEET_EDIT_FAIL'