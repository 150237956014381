import axios from 'axios'
import { API_URL } from './config'
export const endPoint = process.env.REACT_APP_API_URL || API_URL[process.env.NODE_ENV] 
export const baseURL = endPoint + '/api'

const test = process.env.NODE_ENV == 'test'

const attachJWT = config => {
    const { url } = config
    console.log('got url in axios : ', url)

    const oldHeaders = { ...config.headers }
    if (url && !url.includes('assetslms')) {
        oldHeaders.authorization = sessionStorage.getItem('kabra:token')
    }
    return {
        ...config,
        headers: {
            ...oldHeaders
        }

    }
}

const API = axios.create({
    baseURL: baseURL + '/',
    headers: {
        'authorization': sessionStorage.getItem('kabra:token')
    }
})

if (!test) {
    API.interceptors.request.use(attachJWT)
    axios.interceptors.request.use(attachJWT)
}

export default API