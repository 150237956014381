export function acrossFun(val){
    switch(val){
        case 'interState':{
            return 'Inter-State'
        }
        case 'intraState':{
            return 'Intra-State'
        }
        case 'International': {
            return 'International'
        }
        default:{
            return 0
        }
    }
}

export const clearNewPackage = () => {
    try{
        document.getElementById('nQty').value= ''
        document.getElementById('lnew').value = ''
        document.getElementById('bnew').value = ''
        document.getElementById('hnew').value = ''
        document.getElementById('new_weight').value = ''
        document.getElementById('stacknew').checked = false
        document.getElementById('fragnew').checked = false
        document.getElementById('haznew').checked = false
    }
    catch(err){
        console.log(err)
    }
}