import { fromJS } from 'immutable'
import { 
  CREATE_ASSET_TYPE_SUCCESS,
  CREATE_ASSET_TYPE_FAILED,
  EDIT_ASSET_TYPE_SUCCESS,
  EDIT_ASSET_TYPE_FAILED,
  SET_CATEGORY_LIST,
  SET_CATEGORY_LIST_FAIL,
  CHANGE_SIMPLE,
  SET_ASSET_TYPES,
  CREATE_ASSET_BALANCE_FAILED,
  CREATE_ASSET_BALANCE_SUCCESS,
  SET_ASSET_TYPE_FOR_EDIT,
  SET_ASSET_BALANCES,
  RESET
} from '../constants/asset'

import { showMessage, success, failed, info } from './UserReducer'

const initState = fromJS({
  editType: false,
  category: { label: 'Select Category', value: '' },
  categories: [],
  assetName: '',
  isUnique: false,
  consumable: false,
  branch: '',
  assetTypes: [],
  assetType: '',
  uniqueId: '',
  uniqueAsset: false,
  price: '',
  qty: '',
  balances: []
})

const clearWritables = (state) => (
  state
    .set('branch', '')
    .set('assetType', '')
    .set('price', '')
    .set('uniqueAsset', false)
    .set('qty', '')
    .set('uniqueId', '')
    .set('category', '')
    .set('assetName', '')
    .set('isUnique', false)
    .set('consumable', false)
    .set('editType', false)
    .set('balances', [])
)

export default ( state = initState, action ) => {
  switch(action.type){
    
    case CREATE_ASSET_TYPE_SUCCESS: {
      showMessage('Asset type created successfully', success)
      return state
    }
    
    case CREATE_ASSET_TYPE_FAILED: {
      showMessage('Could not create asset type', failed)
      return state
    }
    
    case SET_CATEGORY_LIST: {
      return state.set('categories', action.payload)
    }
    
    case SET_CATEGORY_LIST_FAIL: {
      return state
    }
    
    case CHANGE_SIMPLE: {
      const {what, val} = action.payload
      let asset
      if(what == 'assetType' && state.get('assetTypes')){
        asset = state.get('assetTypes').find(a => a._id == val.value)
      }
      console.log('previous unique: ', state.uniqueAsset, '\nfound : ', asset)
      return state
        .set(what, val)
        .set('uniqueAsset', what == 'assetType' && asset ? asset.isUnique : state.get('uniqueAsset'))
    }

    case SET_ASSET_TYPES: { 
      return state.set('assetTypes', action.payload) 
    }

    case CREATE_ASSET_BALANCE_SUCCESS:{
      showMessage('Asset created successfully', success)
      return state
        .set('branch', '')
        .set('assetType', '')
        .set('price', '')
        .set('uniqueAsset', false)
        .set('qty', '')
        .set('uniqueId', '')
    }

    case CREATE_ASSET_BALANCE_FAILED:{
      const { reasons } = action.payload
      if(reasons && reasons[0]){
        showMessage(reasons[0].message, failed)
      }
      else{
        showMessage('Could not create asset', failed)
      }
      return state
    }

    case SET_ASSET_TYPE_FOR_EDIT: {
      const { payload } = action
      return state
        .set('consumable', payload.consumable)
        .set('isUnique', payload.isUnique)
        .set('editType', payload._id)
        // .set('category',{ value: payload._id})
     
    }

    case RESET : {
      return clearWritables(state)
    }

    case EDIT_ASSET_TYPE_SUCCESS: {
      const { _id } = action.payload
      showMessage('Successfully updated asset type', success)
      return clearWritables(state)
        .set('assetTypes', state.get('assetTypes').map(a => a._id == _id ? ({...action.payload}) : a))
    }

    case SET_ASSET_BALANCES: {
      return state
        .set('balances', action.payload)
    }
    
    default: {
      return state
    }
  }
}