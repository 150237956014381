import { showMessage } from './UserReducer'

export const SUCCESS = '#42f462',
    FAILED = '#ef533e',
    INFO = '#37befc'

export const errorHandler = (data) => {
    const { reasons, message } = data
    if(reasons){
        showMessage(reasons[0].message, FAILED)
    }
    else if(message){
        showMessage(message, FAILED)
    }
}