import { fromJS, Map } from 'immutable'

import {
    MISSING_REQUEST_SUCCESS,
    DAMAGED_REQUEST_SUCCESS,
    ODA_REQUEST_SUCCESS,
    VERIFY_REQUEST_SUCCESS,
    RESET_MODAL,
    FETCH_LOADING_REPORT,
    FETCH_LOAD_PENDING,
    FETCH_LOAD_FAILED,
    LOADING_PENDING,
    LOADING_SUCCESS,
    LOADING_FAIL,
    OPEN_LOAD_MODAL_ACK,
    OPEN_LOAD_MODAL_REQ,
    OPEN_LOAD_MODEL,
    CLOSE_LOAD_MODEL,
    SET_LOAD_ACTION,
    SET_REMARK_TITLE,
    SET_PRELOAD,
    PRELOAD_UPDATE_ACK,
    SELECT_PACKAGE_GROUP,
    DOCKET_UPDATE_ACK,
    SET_FILTER,
    RESET_FILTER,
    CREATE_BOX_SUCCESS,
    SET_BOXES,
    CHANGE_BOX_NUMBER,
    ADDED_TO_BOX,
    REMOVED_FROM_BOX,
    TWO_WAY,
    LOADING_REQUEST,
    LOAD_REQ_SUCCESS,
    PILFERAGE_REQUEST_SUCCESS,
    SHOW_MESSAGE_ACTION,
    HIDE_LOADER_IN_MODAL,
    SHOW_LOADER_IN_MODAL,
    RESET_LOAD_ACTION,
    TWO_WAY_TIMER_UPDATE,
    TWO_WAY_REJECT,
    TWO_WAY_FLUSH,
    TWO_WAY_APPROVED,
    NOTIFY_TWO_WAY_REJECT,
    RESET_VEHICLE_NUMBER,
    UPDATE_ALL_DOCKETS,
    UPDATE_ALL_DOCKETS_ACK,
} from '../constants/loading'
import { SHOW_LOADER, HIDE_LOADER } from '../constants/booking'
import { toast } from 'react-toastify'
import { isBox, getDocketNumber } from './UserReducer'
import { isArrayCheck } from '../containers/generics/CheckArray'
import {
    updateDocketMap,
    makeUID,
    boxifyUpdateDocketMap
} from '../containers/operations/loadutils/filterUtil'
import { procPackages } from '../containers/operations/loadutils/processPackages'
import { stat } from 'fs';
const success = '#42f462',
    failed = '#ef533e',
    info = '#37befc'
let padTo = (number, to) => number <= 9999 ? ("000" + number).slice(-to) : number;
const d = new Date()
let day = padTo(d.getDate(), 2), month = padTo(d.getMonth() + 1, 2), year = padTo(d.getFullYear(), 4)

function showMessage(msg, color, duration = 2000) {
    var x = document.getElementById("snackbar")
    x.innerHTML = msg
    x.style.backgroundColor = color
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, duration)
}

const initExpense = {
    sub: null,
    expenseTypes: null,
    expenseType: '',
    subExpenses: null,
    subExpense: '',
    supplier: '',
    suppliers: null,
    amt: 0,
    fromDate: year + '-' + month + '-' + day,
    toDate: year + '-' + month + '-' + day,
    route: '',
    tripDate: null,
    routes: null,
    attachRoute: false,
    duration: true,
    assetType: '',
    assetTypes: null,
    assetBalances: null,
    assetBalance: ''
}

const initCredit = {
    balance: null,
    company: null,
    customer: null,
    permissions: []

}

const colReport = {
    bookings: [],
    expenses: [],
    deliveries: [],
    sumOfExpense: 0,
    sumOfGross: 0
}

const initState = {
    loginType: null,
    userName: null,
    uid: null,
    token: null,
    contact: null,
    pinPoint: {},
    opBranch: {},
    opFleet: {},
    company: {
        id: null,
        name: null
    },
    bookingMode: 'paid',
    branchForm: {
        branchName: '',
        shortName: '',
        working: {
            rohh: 0, romm: 0, rchh: 0, rcmm: 0,
            mohh: 0, momm: 0, mchh: 0, mcmm: 0,
            tohh: 0, tomm: 0, tchh: 0, tcmm: 0,
            wohh: 0, womm: 0, wchh: 0, wcmm: 0,
            thohh: 0, thomm: 0, thchh: 0, thcmm: 0,
            fohh: 0, fomm: 0, fchh: 0, fcmm: 0,
            saohh: 0, saomm: 0, sachh: 0, sacmm: 0,
            suohh: 0, suomm: 0, suchh: 0, sucmm: 0,
        },
        pContact: '',
        sContact: '',
        email: '',
        paid: false,
        topay: false,
        credit: false,
        isBooking: false,
        isDelivery: false,
        isTr: false,
        trBy: '',
        trArr: [],
        ownership: '',
        frBook: [],
        frDel: [],
        isHub: false,
        cams: [],
        l1: '',
        l2: '',
        pin: '',
        city: ''
    },
    branches: null,
    rights: null,
    tDeps: null,
    rDeps: null,
    tBrs: null,
    rBrs: null,
    gross: null,
    routes: null,
    buffBr: null,
    buffCt: null,
    selectedRoute: null,
    selectedFuelRoute: null,
    rates: null,
    selectedRate: null,
    prates: null,
    allcities: null,
    allbranches: null,
    alldeps: null,
    fuel: {
        open: 0,
        close: 0,
        ppl: 0,
        places: [],
        new: null,
        vnum: null,
        contact: null,
        driverName: null,
        from: null,
        to: null,
        selectedFuel: null,
        filterContact: null,
        filterVehicle: null
    },
    assign: {
        branches: null,
        departments: null,
        fleets: null
    },
    ppf: false,
    kgw: false,
    fr_ppf: false,
    fr_kgw: false,
    editBranchId: null,
    suggPrice: null,
    routeSugg: null,
    customer: Map({
        name: '',
        contact: '',
        GSTIN: '',
        address: {
            l1: '',
            l2: '',
            city: '',
            pincode: ''
        }
    }),
    fr: 0,
    bookroute: '',
    editDocket: false,
    bf: 0,
    hf: 0,
    gross: 0,
    packages: [{
        id: 0,
        qty: 1,
        materialType: '',
        packingType: '',
        size: 'custom',
        dimension: { l: 0, b: 0, h: 0 },
        weight: 0,
        rate: 'Direct',
        unit: 'Mt',
        amount: 0,
        stack: false,
        haz: false,
        frag: false
    }],
    risk: 0,
    isCol: false,
    howcollect: 'cod',
    colMonth: 0,
    customPack: 'null',
    bookingRate: 'Direct',
    purpose: '',
    credit: fromJS(initCredit),
    loadcity: '',
    loadroute: '',
    loadbranch: '',
    loaddocket: '',
    loadVehicle: null,
    assignroute: '',
    bookdestbranch: '',
    loading_pending: false,
    sender: {
        name: '',
        contact: '',
        gst: '',
        l1: '',
        l2: '',
        pin: '',
        city: ''
    },
    receiver: {
        name: '',
        contact: '',
        gst: '',
        l1: '',
        l2: '',
        pin: '',
        city: ''
    },
    creditNumber: '',
    expense: initExpense,
    colReport,
    delAmt: '',
    twoWayLoad: false
}

export default (state = initState, action) => {
    switch (action.type) {

        case MISSING_REQUEST_SUCCESS: {
            showMessage("Updated missing status successfully!", success)
            return {
                ...state,
                docketMap: updateDocketMap({ ...state.docketMap }, (p) => (p.selected != true), null, { filterData: true }),
                docketReRenderMap: state.docketReRenderMap.set('all', true)
            }
        }

        case VERIFY_REQUEST_SUCCESS: {
            let docketMap = updateDocketMap({ ...state.docketMap }, (p) => p.selected === true, {
                verified: true,
                verifiedAt: new Date(),
                selected: false
            })
            showMessage("Verified packages successfully!", success)
            return {
                ...state,
                docketMap,
                loadModal: false,
                remarkTitle: null
            }
        }

        case PILFERAGE_REQUEST_SUCCESS: {
            let docketMap = updateDocketMap({ ...state.docketMap }, (p) => p.selected === true, {
                verified: true,
                verifiedAt: new Date(),
                selected: false,
                pilferage: true
            })
            showMessage("Verified packages successfully!", success)
            return {
                ...state,
                docketMap,
                loadModal: false,
                remarkTitle: null
            }
        }
        case DAMAGED_REQUEST_SUCCESS: {
            let docketMap = updateDocketMap({ ...state.docketMap }, (p) => p.selected === true, {
                verified: true,
                verifiedAt: new Date(),
                selected: false,
                damaged: true
            })
            showMessage("Verified packages successfully!", success)
            return {
                ...state,
                docketMap,
                loadModal: false,
                remarkTitle: null
            }
        }
        case SHOW_MESSAGE_ACTION: {
            showMessage(action.payload, success)
            return state
        }
        case ODA_REQUEST_SUCCESS: {
            // let packages = action.payload,
            //     pSet = new Set(action.payload.map(p => p._id))

            // let resDocketMap = Object.assign({}, state.docketMap)
            // packages.forEach(p => {
            //     let { order } = p
            //     let docketNo = isBox(p) ? p.docketBox.boxNumber : getDocketNumber(order)
            //     resDocketMap[docketNo] = resDocketMap[docketNo].filter(pck => !pSet.has(pck._id))
            // })
            // showMessage("Set Out for Delivery status successfully!", success)
            return {
                ...state,
                docketMap: updateDocketMap({ ...state.docketMap }, (p) => (p.selected != true), null, { filterData: true })
            }
        }

        case RESET_MODAL: {
            let resState = { ...state }
            delete resState.loadedPackages
            delete resState.loadingReport
            console.log('RESET_MODAL');
            console.log(resState);
            return resState
        }

        case FETCH_LOADING_REPORT: {
            console.log('RESPONSE OF LOADING REPORT', action.payload);
            let branchMap = {}
            let packagesByBranch = {}
            let packages = action.payload.records;
            packagesByBranch.regNumber = action.payload.regNumber
            packagesByBranch.userName = action.payload.userName
            packagesByBranch.createdAt = (action.payload.report.createdAt) ? new Date(action.payload.report.createdAt).toLocaleString() :''

            packages.forEach(p => {
                let branch = p.BranchDoc[0]._id;
                branchMap[branch] = p.BranchDoc[0]
                if (branch in packagesByBranch) {
                    packagesByBranch[branch].push(p)
                }
                else {
                    packagesByBranch[branch] = [p]
                }
            })
            console.log('Value of loading report', packagesByBranch);
            return {
                ...state,
                packagesByBranch,
                branchMap,
                loadingReport: null,
                loadModal: false
            }
        }

        case LOADING_PENDING: {
            return { ...state, loading_pending: true }
        }

        case LOADING_SUCCESS: {
            let { packages: arr } = action
            let { report } = action.payload
            console.log('action.payload: ', action.payload, report.reportNumber);
            console.log('action.payload: ', action.payload.report)

            return {
                ...state,
                loadVehicle: null,
                loadedPackages: arr.length,
                loadingReport: report.reportNumber,
                fetchLoadError: null
            }

        }

        case LOADING_FAIL: {
            // showMessage('Could not load packages', failed)
            return {
                ...state,
                loading_pending: false,
                loadVehicle: null,
                loadModal: false,
            }
        }

        case OPEN_LOAD_MODAL_ACK: {
            return { ...state, loadModal: false }
        }

        case OPEN_LOAD_MODAL_REQ: {
            return { ...state, loadModal: true }
        }

        case OPEN_LOAD_MODEL: {
            return {
                ...state,
                loadModal: true
            }
        }

        case CLOSE_LOAD_MODEL: {
            return {
                ...state,
                loadModal: false,
                loadingReport: null,
                loadAction: '',
                fedexPickupNumber: null
            }
        }
        case RESET_LOAD_ACTION: {
            return {
                ...state,
                loadAction: '',
            }
        }
        case RESET_VEHICLE_NUMBER: {
            return {
                ...state,
                loadVehicle: null
            }
        }
        case SET_LOAD_ACTION: {
            return {
                ...state,
                loadAction: action.payload,
                remarkTitle: null
            }
        }
        case SET_REMARK_TITLE: {
            console.log('title is here');
            return {
                ...state,
                remarkTitle: action.payload
            }
        }
        case SET_PRELOAD: {

            console.log('RESPONSE OF PRELOAD REPORT', action.payload);

            let branchMap = {}
            let packagesByBranch = {}
            let { records: packages } = action.payload;

            console.log('packages: ', packages);
            packages.forEach(p => {
                console.log('branch doc of p: ', p.BranchDoc)
                let branch = p.BranchDoc[0]._id;
                branchMap[branch] = p.BranchDoc[0]
                if (branch in packagesByBranch) {
                    packagesByBranch[branch].push(p)
                }
                else {
                    packagesByBranch[branch] = [p]
                }
            })

            console.log('Packages by branch : ', packagesByBranch);

            return {
                ...state,
                preloadPackagesByBranch: packagesByBranch,
                preloadBranchMap: branchMap,
                refreshPreload: true
            }
        }
        case PRELOAD_UPDATE_ACK: {
            return {
                ...state,
                refreshPreload: false
            }
        }
        case SELECT_PACKAGE_GROUP: {
            const { uid, docketNo } = action.payload
            return {
                ...state,
                docketMap: updateDocketMap({ ...state.docketMap },
                    p => makeUID(p) == uid,
                    p => ({ selected: !p.selected }),
                    {
                        docketNo
                    }),
                docketReRenderMap: state.docketReRenderMap.set(docketNo, true)
            }
        }
        case SHOW_LOADER: {
            return {
                ...state,
                loading: true
            }
        }
        case HIDE_LOADER: {
            return {
                ...state,
                loading: false
            }
        }
        case SET_FILTER: {
            const { what, val } = action.payload
            console.log(' what, val: ', what, val)
            const loadFilters = { ...state.loadFilters }
            let { docketMap } = state

            let newDocketMap = {}
            if (typeof docketMap == 'object' && Object.keys(docketMap).length > 0) {
                Object.keys(docketMap).forEach(d => {
                    let packageMap = { ...docketMap[d] }
                    Object.keys(packageMap).forEach(uid => {
                        packageMap[uid] = packageMap[uid].map(p => ({ ...p, selected: false }))
                    })
                    newDocketMap[d] = packageMap
                })
            }
            switch (what) {
                case 'deliverable': {
                    return {
                        ...state,
                        docketMap: newDocketMap,
                        loadFilters: {
                            ...loadFilters,
                            deliverable: val
                        }
                    }
                }

                case 'unverified': {
                    return {
                        ...state,
                        docketMap: newDocketMap,
                        loadFilters: {
                            ...loadFilters,
                            unverified: val
                        }
                    }
                }

                case 'destCity': {
                    return {
                        ...state,
                        docketMap: newDocketMap,
                        loadcity: val,
                        loadbranch: null
                    }
                }

                case 'loadBranch': {
                    return {
                        ...state,
                        docketMap: newDocketMap,
                        loadbranch: val,
                        loadcity: val && val.city ? {
                            label: val.city.name,
                            value: val.city._id
                        } : null
                    }
                }

                case 'loadroute': {
                    return {
                        ...state,
                        loadroute : val
                    }
                }

                default: {
                    return state
                }
            }
        }
        case UPDATE_ALL_DOCKETS: {
            return { ...state, docketReRenderMap: state.docketReRenderMap.set("all", true) }
        }
        case UPDATE_ALL_DOCKETS_ACK: {
            return { ...state, docketReRenderMap: state.docketReRenderMap.set("all", false) }
        }

        case FETCH_LOAD_PENDING: {
            return {
                ...state,
                fetchLoadPending: true
            }
        }

        case FETCH_LOAD_FAILED: {
            return {
                ...state,
                fetchLoadPending: false,
                fetchLoadError: true
            }
        }

        case RESET_FILTER: {

            let { docketMap } = state
            let filteredPackages = 0
            if (docketMap != null && docketMap != undefined && typeof docketMap == 'object') {
                filteredPackages = Object.values(docketMap)
                    .map(packages => packages.length)
                    .reduce((acc, i) => acc + i, 0)
            }
            console.log('filteredPackages: ', filteredPackages);
            return {
                ...state,
                loadbranch: null,
                loadcity: null,
                loadroute: null,
                loaddocket: '',
                loadFilters: {},
                filteredPackages
            }
        }

        case CREATE_BOX_SUCCESS: {
            // toast.success('Box Created Successfully')
            let {
                boxNumber
            } = action.payload.docketBox

            let criteria = (p) => (p.selected == true)
            let updateDoc = {
                isBox: true,
                selected: true,
                verified: true,
            }
            let docketMap = { ...state.docketMap }
            boxifyUpdateDocketMap(docketMap, criteria, updateDoc, {
                boxNumber,
                create: true
            })
            let boxOptions = [...state.boxOptions, { label: boxNumber, value: boxNumber }]


            return {
                ...state,
                docketMap,
                loadModal: false,
                loadingReport: null,
                boxOptions
            }
        }

        case HIDE_LOADER_IN_MODAL: {
            return {
                ...state,
                modalLoader: false
            }
        }

        case SHOW_LOADER_IN_MODAL: {
            return {
                ...state,
                modalLoader: true
            }
        }

        case ADDED_TO_BOX: {
            let {
                boxNumber
            } = action.payload.docketBox

            let docketMap = Object.assign({}, state.docketMap)
            let criteria = (p) => (p.selected == true)
            let updateDoc = {
                isBox: true,
                selected: false,
                verified: true
            }
            boxifyUpdateDocketMap(docketMap, criteria, updateDoc, { add: true, boxNumber })

            return {
                ...state,
                docketMap,
                loadModal: false,
                loadingReport: null
            }
        }

        case REMOVED_FROM_BOX: {
            let docketMap = Object.assign({}, state.docketMap)
            let criteria = (p) => (p.selected == true)
            let updateDoc = {
                isBox: false,
                verified: true,
                selected: false
            }
            boxifyUpdateDocketMap(docketMap, criteria, updateDoc, { remove: true })
            return {
                ...state,
                docketMap: docketMap,
                loadModal: false,
                loadingReport: null
            }
        }

        case SET_BOXES: {
            return { ...state, docketBoxes: action.payload }
        }

        case CHANGE_BOX_NUMBER: {
            return {
                ...state,
                boxNumber: action.payload
            }
        }

        case TWO_WAY: {
            console.log('state.twoWayLoad', state.twoWayLoad);
            return {
                ...state,
                twoWayLoad: !state.twoWayLoad
            }
        }

        case LOADING_REQUEST: {
            toast.success('You have got a new Loading Request')

            const {
                loadingReport,
                sender,
                receiver,
                fleet,
                branch
            } = action.data

            const pendingLoadingReports = Array.isArray(state.pendingLoadingReports) ? state.pendingLoadingReports : []

            return {
                ...state,
                pendingLoadingReports: pendingLoadingReports.concat({
                    ...loadingReport,
                    sender,
                    receiver,
                    branch,
                    fleet
                }),
                bell: Date.now(),
                updateSidebar: true
            }
        }

        case LOAD_REQ_SUCCESS: {
            const { report } = action.payload
            return {
                ...state,
                fetchLoadPending: false,
                fetchLoadError: null,
                twoWayTimer: true,
                twoWayTime: 60,
                twoWayReport: report
            }
        }

        case TWO_WAY_TIMER_UPDATE: {
            try {
                let { twoWayTime } = state
                if (twoWayTime > 0) {
                    return {
                        ...state,
                        twoWayTime: twoWayTime - 1
                    }
                }
                else {
                    console.log('clear the interval here : ', global.twoWayInterval)
                    clearInterval(global.twoWayInterval)
                    return {
                        ...state,
                        twoWayTimer: false
                    }
                }
            }
            catch (err) {
                console.log(err)
                return state
            }
        }

        case TWO_WAY_REJECT: {
            toast.success('Request cancelled successfully!')
            const { pendingLoadingReports } = state
            const { reportNumber } = action.payload
            const reports = pendingLoadingReports ? pendingLoadingReports.slice().map(r => r.reportNumber == reportNumber ? ({
                ...r,
                approved: false,
                status: 'R',
                rejectedAt: new Date()
            }) : r) : []
            return {
                ...state,
                pendingLoadingReports: reports,
                twoWayReport: null,
                twoWayTime: -1
            }
        }

        case TWO_WAY_FLUSH: {
            return {
                ...state,
                twoWayTimer: false,
                rejectedByOtherParty: false
            }
        }

        case TWO_WAY_APPROVED: {
            if (action.data && isArrayCheck(state.pendingLoadingReports)) {
                // #####################################
                // Update loading report receiver side
                // #####################################
                const { reportNumber } = action.data
                let dd = new Date()
                const reports = state.pendingLoadingReports.slice().map(r => r.reportNumber == reportNumber ? ({
                    ...r,
                    approved: true,
                    approvedAt: dd.toLocaleString()
                }) : r)
                return {
                    ...state,
                    pendingLoadingReports: reports
                }
            }
            else {
                console.log('got action : ', action)
                // ###############################
                // Update timer view sender side
                // ###############################
                toast.success('Report approved successfully!')
                if (global.utils && typeof global.utils.setupData == 'function') {
                    setTimeout(() => {
                        console.log('update whole view')
                        global.utils.setupData()
                    }, 1000)
                }
                return {
                    ...state,
                    twoWayReport: null,
                    twoWayTimer: false,
                    loadModal: false
                }
            }
            return state
        }

        case NOTIFY_TWO_WAY_REJECT: {
            const { reportNumber } = action.data
            if (state.twoWayReport && state.twoWayReport.reportNumber == reportNumber) {
                return {
                    ...state,
                    twoWayReport: null,
                    twoWayTime: -1,
                    rejectedByOtherParty: true
                }
            }
            return state
        }

        case DOCKET_UPDATE_ACK: {
            const { docket } = action.payload
            return {
                ...state,
                docketReRenderMap: state.docketReRenderMap.set(docket, false)
            }
        }

        default: {
            return state
        }
    }
}