import err from '../err'

export const branchName = (val) => {
    let ch = val[val.length - 1]
    console.log('\nchar is : ', ch)
    let nums = ['1', '2', '3', '4', '5', '6', '7', '8', '9','0']
    if(nums.includes(ch)){
        return err('Branch name can\'t contain a number', 'BranchField')
    }
    if(val.length > 25) {
        return err('Branch name must be less than 25 characters', 'BranchName')
    }
    return null
}

export const branchShortName = (val) => {
    if(val.length > 4){
        return err('Short name must be upto 4 characters', 'ShortName')
    }
    return null
}

export const email = (val) => {
    let patt = /^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/
    if(patt.test(val)){
        return null
    }
    return err('Email address is not valid', 'Email')
}
