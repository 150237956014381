
export const countHandling = (packages, slab, across) => {
    console.log('packages : ', packages, 'slab : ', slab, ' across : ', across)
    let result = 0
    switch (across) {
        case 'interState': {
            console.log('got interstate')
            packages.forEach(p => {
                let { rate, amount, qty } = p
                rate = rate.value
                let gotAny = false
                qty = parseInt(qty, 10)
                let flag = false, perPackAmt = rate != 'Per package' ? amount : amount / qty
                slab.forEach(({ min, max, interState }) => {
                    if (!flag && min <= perPackAmt && perPackAmt <= max && !isNaN(qty)) {
                        result += interState * qty
                        flag = true
                        gotAny = true
                    }
                })
                if (!gotAny && !isNaN(qty)) {
                    result += slab[slab.length - 1].interState
                }
            })
            break
        }
        case 'intraState': {
            console.log('got intrastate')
            packages.forEach(p => {
                let gotAny = false
                let { rate, amount, qty } = p
                rate = rate.value
                qty = parseInt(qty, 10)
                let flag = false, perPackAmt = rate != 'Per package' ? amount : amount / qty
                slab.forEach(({ min, max, intraState }) => {
                    if (!flag && min <= perPackAmt && perPackAmt <= max && !isNaN(qty)) {
                        console.log('inside if');
                        result += intraState * qty
                        flag = true
                        gotAny = true
                    }
                })
                if (!gotAny && !isNaN(qty)) {
                    result += slab[slab.length - 1].intraState
                }
            })
            break
        }
        default: {
            console.log("error: got no across value");
            
            throw "AcrossError"
        }
    }
    console.log('counted handling : ', result)
    return result
}

export const countBuilty = (builty, across) => {
    console.log('bilty is : ', builty, '\nacross : ', across)
    if (!across) throw "AcrossError"
    const result = builty[across]
    if (!(result>=0)) throw "BuiltyError:- interState, intrastate or international rate missing"
    return result
}
