import {
    SET_RIGHTS,
    SET_SIDEBAR_MEMOS
} from '../constants/rights'

import { processRights } from './utils/ProcessRights'

const initState = {}

export default (state = initState, action) => {
    switch (action.type) {

        case SET_RIGHTS: {
            // console.log('REQUIRED DATA: ', action.payload);
            const rights = processRights(action.payload)
            return { ...state, ...rights, role: action.role }
        }

        case SET_SIDEBAR_MEMOS: {
            return {
                ...state,
                noOfMemos: action.payload
            }
        }

        default: {
            return state
        }
    }
}