import React, {Component} from 'react'


class Loading extends Component {
    render(){
        return (
            <div>
                Loading
            </div>
        )
    }
}

export default Loading
