import {
    MEMO_RECEIVED,
    SET_RECEIVED_UNAPPROVED_MEMOS,
    APPROVE_OR_REJECT_NOTIFY_MEMO,
    SET_APPROVED_REJECTED_MEMOS
} from '../constants/memo'

import {
    toast
} from 'react-toastify'
import { isArrayCheck } from '../containers/generics/CheckArray';

export default (state, action) => {
    switch (action.type) {

        case MEMO_RECEIVED: {
            const { memo: memoState } = state
            const { contact } = state
            let memoDoc = action.data
            if (memoDoc.recipientContact != contact) {
                console.log('not our memo : ', memoDoc.recipientContact, ' our contact : ', contact)
                return state
            }
            const pendingMemos = Array.isArray(memoState.notifyMemos) ? memoState.notifyMemos.slice() : []
            toast.info('You have received a new memo!')
            return {
                ...state,
                memo: {
                    ...memoState,
                    notifyMemos: pendingMemos.concat([action.data])
                },
                updateSidebar: true
            }
        }

        case APPROVE_OR_REJECT_NOTIFY_MEMO :  {
            if (!isArrayCheck(state.memo.notifyMemos)) return state
            console.log(action.payload,'approved or reject');
            const memo = action.payload
            return {
                ...state,
                memo: {
                    ...state.memos,
                    notifyMemos: state.memo.notifyMemos.filter(m => m._id != memo._id)
                },
                updateSidebar: true
            }
        }
        case SET_APPROVED_REJECTED_MEMOS : {
               const{message} = action.data        
               toast.info(message)
               return state
        }
         case SET_RECEIVED_UNAPPROVED_MEMOS: {
            const memos = action.payload
            return {
                ...state,
                memo: {
                    ...state.memo,
                    notifyMemos: memos
                }
            }
        }

        default: {
            return state
        }
    }
}