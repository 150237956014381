import { fromJS, List } from 'immutable'
import {
    CHANGE_SIMPLE,
    CHANGE_DESTINATION,
    ADD_DESTINATION,
    REMOVE_DESTINATION,
    CLEAR_ROUTE,
    SET_ROUTE,
    ADD_PACK_SIZE,
    DELETE_PACK_SIZE
} from '../constants/route'
import { createRoute, updateRoute, listRoute } from '../services/route'
import { routeValidation } from './schemas/route'

const initState = fromJS({
    isEditing: false,
    routeType: '',
    serviceType: '',
    subServiceType: '',
    name: '',
    modeOfTransfer: '',
    modeOfPayment: [],
    TransportSubType: '',
    working: {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false
    },
    constraints: {
        perPackageVolume: {
            l: '',
            b: '',
            h: ''
        },
        perPackageWeight: ''
    },
    capacity: {
        weight: '',
        volume: ''
    },
    // packageRates:[],
    active: false,
    insurance: false,
    collections: false,
    fragile: false,
    unstackable: false,
    hazardous: false,
    denominator: '',
    minChargeWeight: '',

    destination: [{
        id: null,
        company: '',
        city: '',
        branch: '',
        cutOffTime: {
            h: 0,
            m: 0
        },
        noOfDays: 0,
        deliveryTime: {
            h: 0,
            m: 0
        },
        loading: false,
        unloading: false
    }],
    errors: {
        routeType: null,
        serviceType: null,
        subServiceType: null,
        name: null,
        modeOfTransfer: null,
        TransportSubType: null,
        modeOfPayment: null,
        working: {
            sun: null,
            mon: null,
            tue: null,
            wed: null,
            thu: null,
            fri: null,
            sat: null,
        },
        constraints: {
            perPackageVolume: {
                l: null,
                b: null,
                h: null,
            },
            perPackageWeight: null,
        },
        capacity: {
            weight: null,
            volume: null,
        },
        insurance: null,
        collections: null,
        fragile: null,
        unstackable: null,
        hazardous: null,
        denominator: null,
        minChargeWeight: null,

        destination: [{
            id: null,
            company: null,
            city: null,
            branch: null,
            cutOffTime: {
                h: null,
                m: null,
            },
            noOfDays: null,
            deliveryTime: {
                h: null,
                m: null,
            },
            loading: null,
            unloading: null,
        }]
    }
})

const dest = fromJS({
    id: null,
    city: null,
    branch: null,
    cutOffTime: {
        h: 0,
        m: 0
    },
    noOfDays: 0,
    deliveryTime: {
        h: 0,
        m: 0
    },
    loading: false,
    unloading: false,

})

export default function (state = initState, action) {
    switch (action.type) {

        case CHANGE_SIMPLE: {
            const { what, value } = action.payload
            try {

                if(routeValidation.getIn([...what])) routeValidation.getIn([...what]).validateSync(value)
                if (what[0] == 'name') {
                    return initState.setIn([...what], value).setIn(['isEditing'], false)
                }
                return state.setIn([...what], value).setIn(['errors', ...what], null)
            }
            catch (err) {
                console.log("validation err:", err);
                return state.setIn([...what], value).setIn(['errors', ...what], err.errors[0])
            }
        }

        case CHANGE_DESTINATION: {
            const { index, what, value } = action.payload
            const destination = state.get('destination').update(index, (dest) => {
                return dest.setIn([...what], value)
            })
            //const destinationErrors = 
            return state.setIn(['destination'], destination)//.setIn(['errors','destinations'],destinationErrors)
        }

        case ADD_DESTINATION: {
            const destination2 = state.get('destination').push(dest)
            return state.set('destination', destination2)
        }

        case REMOVE_DESTINATION: {

            return state.set('destination', state.get('destination').delete(action.payload.index))
        }

        case CLEAR_ROUTE: {
            return initState
        }

        case SET_ROUTE: {
            return fromJS({ ...action.payload.route, isEditing: true })
        }

        // case ADD_PACK_SIZE: {
        //     return state.update('packageRates', packageRates => (packageRates || List()).push(fromJS({
        //       name: '',
        //       l: null,
        //       b: null,
        //       h: null,
        //       unit: 'cm',
        //       weight: null,
        //       rate: null 
        //     })))
        // }
        // case DELETE_PACK_SIZE : {
        //     return state.update('packageRates', packageRates => packageRates.delete(action.payload))
        // }

        default:
            return state
    }
}