const prefix = 'LOADING:'

export const MISSING_REQUEST_SUCCESS = prefix + 'MISSING_REQUEST_SUCCESS'
export const VERIFY_REQUEST_SUCCESS = prefix + 'VERIFY_REQUEST_SUCCESS'
export const DAMAGED_REQUEST_SUCCESS = prefix + 'DAMAGED_REQUEST_SUCCESS'
export const ODA_REQUEST_SUCCESS = prefix + 'ODA_REQUEST_SUCCESS'
export const RESET_MODAL = prefix + 'RESET_MODAL'
export const FETCH_LOADING_REPORT = prefix + 'FETCH_LOADING_REPORT'
export const LOADING_PENDING = prefix + 'LOADING_PENDING'
export const LOADING_SUCCESS = prefix + 'LOADING_SUCCESS'
export const LOADING_FAIL = prefix + 'LOADING_FAIL'
export const OPEN_LOAD_MODAL_REQ = prefix + 'OPEN_LOAD_MODAL_REQ'
export const OPEN_LOAD_MODAL_ACK = prefix + 'OPEN_LOAD_MODAL_ACK'
export const OPEN_LOAD_MODEL = prefix + 'OPEN_LOAD_MODEL'
export const CLOSE_LOAD_MODEL = prefix + 'CLOSE_LOAD_MODEL'
export const SET_LOAD_ACTION = prefix + 'SET_LOAD_ACTION'
export const SET_REMARK_TITLE = prefix + 'SET_REMARK_TITLE'
export const SET_PRELOAD = prefix + 'SET_PRELOAD'
export const SELECT_PACKAGE_GROUP = prefix + 'SELECT_PACKAGE_GROUP'
export const SET_FILTER = prefix + 'SET_FILTER'
export const DOCKET_UPDATE_ACK = prefix + 'DOCKET_UPDATE_ACK'
export const FETCH_LOAD_PENDING = prefix + 'FETCH_LOAD_PENDING'
export const FETCH_LOAD_FAILED = prefix + 'FETCH_LOAD_FAILED'
export const RESET_FILTER = prefix + 'RESET_FILTER'
export const CREATE_BOX_SUCCESS = prefix + 'CREATE_BOX_SUCCESS'
export const SET_BOXES = prefix + 'SET_BOXES'
export const CHANGE_BOX_NUMBER = prefix + 'CHANGE_BOX_NUMBER'
export const ADDED_TO_BOX = prefix + 'ADDED_TO_BOX'
export const REMOVED_FROM_BOX = prefix + 'REMOVED_FROM_BOX'
export const TWO_WAY = prefix + 'TWO_WAY'
export const LOAD_REQ_SUCCESS = prefix + 'LOAD_REQ_SUCCESS'
export const LOADING_REQUEST = prefix + 'LOADING_REQUEST'
export const PILFERAGE_REQUEST_SUCCESS = prefix + 'PILFERAGE_REQUEST_SUCCESS'
export const SHOW_MESSAGE_ACTION = prefix + 'SHOW_MESSAGE_ACTION'
export const HIDE_LOADER_IN_MODAL = prefix + 'HIDE_LOADER_IN_MODAL'
export const SHOW_LOADER_IN_MODAL = prefix + 'SHOW_LOADER_IN_MODAL'
export const PRELOAD_UPDATE_ACK = prefix + 'PRELOAD_UPDATE_ACK'
export const RESET_LOAD_ACTION = prefix + 'RESET_LOAD_ACTION'
export const TWO_WAY_TIMER_UPDATE = prefix + 'TWO_WAY_TIMER_UPDATE'
export const TWO_WAY_REJECT = prefix + 'TWO_WAY_REJECT'
export const TWO_WAY_FLUSH = prefix + 'TWO_WAY_FLUSH'
export const TWO_WAY_APPROVED = prefix + 'TWO_WAY_APPROVED'
export const NOTIFY_TWO_WAY_REJECT = prefix + 'NOTIFY_TWO_WAY_REJECT'
export const RESET_VEHICLE_NUMBER = prefix + 'RESET_VEHICLE_NUMBER'
export const UPDATE_ALL_DOCKETS = prefix + 'UPDATE_ALL_DOCKETS'
export const UPDATE_ALL_DOCKETS_ACK = prefix + 'UPDATE_ALL_DOCKETS_ACK'
