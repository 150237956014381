import { fromJS } from 'immutable'

import { validateCompanydoc } from './schemas/companydoc'

import {
    GET_COMPANY_REQ_SUCCESS,
    EDIT_COMPANY_REQ_SUCCESS,
    CHANGE_ADD_EXPENSE_TYPE,
    SUBMIT_EXPENSE_SUCCESS,
    SUBMIT_EXPENSE_FAILED,
    GET_EXPENSE_TYPES,
    GET_EXPENSE_TYPES_FAILED,
    CHANGE_FIXED_EXPENSE,
    CHANGE_ENTITY_ID,
    SUBMIT_FIXED_EXPENSE_FAILED,
    EDIT_FIXED_EXPENSE_FAILURE,
    SUBMIT_FIXED_EXPENSE_SUCCESS,
    CHANGE_ADD_INCOME_TYPE,
    SUBMIT_INCOME_TYPE_FAILED,
    SUBMIT_INCOME_TYPE_SUCCESS,
    CHANGE_SUB_INCOME_TYPE,
    ADD_SUB_INCOME_TYPE,
    ADD_SUB_INCOME_TYPE_FAILED,
    SET_INCOME_TYPE_FOR_EDIT,
    EDIT_INCOME_TYPE_SUCCESS,
    INCOME_TYPE_DELETED,
    RESET_INCOME_TYPE_FORM,
    SET_SUBTYPES_FOR_EDIT,
    GET_POSTED_SUB_TYPES,
    EDIT_SUB_INCOME_DATA,
    RESET_SUB_INCOME_TYPE,
    SET_EXPENSE_TYPE,
    RESET_EXPENSE_TYPE,
    DELETE_EXPENSE_TYPE,
    RESET_FIXED_EXPENSE,
    GET_FIXED_EXPENSE_BY_ID,
    SET_FIXED_EXPENSE_TYPE,
    EDIT_FIXED_EXPENSE_SUCCESS,
    DELETE_FIXED_EXPENSE_TYPE,
    SET_ASSET_TYPES,
    EDIT_EXPENSE_SUCCESS,
    EDIT_EXPENSE_FAILED,
    SET_ASSET_TYPES_INCOME_TYPE,
    SET_ASSET_TYPES_FOR_FIXED_EXP,
    GET_COMPANY_LIST,
    GET_VEHICLE_ID,
    CHANGE_COMPANY_DOC,
    SET_COMPANY_DOC,
    CLEAR_COMPANY_DOC,

} from '../constants/company'
import { showMessage, failed } from './UserReducer'
import { SUCCESS, FAILED, INFO } from './helpers'
import {
    add_Expense_Boolean,
    add_Fixed_Expense_Boolean,
    add_Expense_Validation,
    add_Fixed_Expense_Validation,
    add_Sub_Income_Boolean,
    add_Sub_Income_Validation,
    validateExpense
} from './schemas/addExpense'
import { yupToFormErrors } from 'formik';
import { isObject, isString } from 'util';
import { isArrayCheck } from '../containers/generics/CheckArray';

const initExpenseType = {
    type: 'add',
    name: '',
    fleet: false,
    branch: false,
    general: false,
    variance: 'V',
    sub: '',
    is_route: '',
    duration: false,
    monthly_duration: false,
    company: '',
    category: ''
}

const initIncomeType = {
    name: '',
    fleet: false,
    branch: false,
    general: false,
    sub: '',
    is_route: false,
    duration: false,
    company: '',
    type: 'add',
    monthly_duration: false
}
const d = new Date();
const date = d.getFullYear() + '-' + ((d.getMonth() < 9) ? '0' : '') + (d.getMonth() + 1)
const initCompanyDoc = {
    isNew: true,
    active: false,
    isDocVerified: false,
    individual: false,
    bodyType: '',
    fleetType: '',
    regNumber: '',
    _id: '',
    companyName: '',
    kind: '',
    url: '',
    email: '',
    contactPerson: {
        contact: '',
        name: {
            fName: '',
            lName: '',
        },
        password: '',
        email: '',
    },
    address: {
        l1: '',
        l2: '',
        city: '',
        pincode: '',
    },
    bankAccount: '',
    ifscCode: '',
    licenseImage: '',
    licenseExpiry: date,
    panNumber: '',
    panCardImage: '',
    companyLogo: '',
    userProfile: '',
    errors: {}
}

const initState = fromJS({
    expense_type: initExpenseType,
    assetTypes: [],
    expense_type_erorrs: { ...add_Expense_Boolean(true) },
    expense_type_touched: { ...add_Expense_Boolean(true) },
    fetched_fixed_expensesById: [],
    fixed_expenses: {
        vehicleType: '',
        fetched_expense_types: [],
        fetched_entity_types: [],
        post_fixed_expense: {
            type: 'add',
            entity: '',
            assetType: '',
            entity_id: '',
            ref: '',
            duration: 1,
            amt: '',
            company: '',
        },
        fixed_expense_type_erorrs: { ...add_Fixed_Expense_Boolean(true) },
        fixed_expense_type_touched: { ...add_Fixed_Expense_Boolean(true) },
    },
    income_type: initIncomeType,
    income_type_errors: { ...add_Expense_Boolean(true) },
    income_type_touched: { ...add_Expense_Boolean(false) },
    fetched_sub_income_types: [],
    sub_income_type: {
        type: 'add',
        name: '',
        ref: ''
    },
    sub_income_errors: { ...add_Sub_Income_Boolean(true) },
    sub_income_touched: { ...add_Sub_Income_Boolean(false) },
    companyList: [],
    company_doc: initCompanyDoc
})

export default (state = initState, action) => {
    switch (action.type) {
        case CHANGE_COMPANY_DOC: {
            return state.setIn(['company_doc', ...action.payload.what], action.payload.value).setIn(['company_doc', 'errors', ...action.payload.what], validateCompanydoc(action.payload.what, action.payload.value))
        }
        case SET_COMPANY_DOC: {
            return state.setIn(['company_doc'], fromJS(action.payload))
        }
        case CLEAR_COMPANY_DOC: {
            return state.setIn(['company_doc'], fromJS({ ...initCompanyDoc, individual: action.payload.individual }))
        }
        case GET_COMPANY_REQ_SUCCESS: {
            showMessage('Fetched company successfully', SUCCESS)
            console.log('action.type: ', action.payload);
            return state.merge(action.payload)
            // ...action.payload, expense_type: initState.expense_type, fixed_expenses:initState.fixed_expenses }
        }
        case EDIT_COMPANY_REQ_SUCCESS: {
            showMessage('Company saved successfully!', SUCCESS)
            
            return state.merge(action.payload)
            // return { ...action.payload, expense_type: initState.expense_type, fixed_expenses:initState.fixed_expenses }
        }
        case CHANGE_ADD_EXPENSE_TYPE: {
            let { what, val } = action.payload
            console.log('what, val: ', what);
            console.log('what, val: ', val);
            return state
                .setIn(['expense_type', ...what], val)
                .setIn(['expense_type_erorrs', ...what], validateExpense(add_Expense_Validation.getIn(what), val))
                .setIn(['expense_type_touched', ...what], true)
        }
        case GET_VEHICLE_ID: {
            console.log('action.payload: ', action.payload);
            return state.setIn(['fixed_expenses', 'vehicleType'], action.payload)
        }
        case SUBMIT_EXPENSE_SUCCESS: {
            showMessage('Expense type created successfully!', SUCCESS)
            let data = {
                type: 'add',
                name: '',
                fleet: false,
                branch: false,
                general: false,
                variance: 'V',
                sub: '',
                is_route: '',
                duration: false,
                company: ''
            }
            return state.set('expense_type', data)
        }
        case EDIT_EXPENSE_SUCCESS: {
            showMessage('Expense Type Edited Successfully!', SUCCESS)
            let data = {
                type: 'add',
                name: '',
                fleet: false,
                branch: false,
                general: false,
                variance: 'V',
                sub: '',
                is_route: '',
                duration: false,
                company: ''
            }
            return state.set('expense_type', data)
        }
        // case EDIT_EXPENSE_FAILED: {
        //     showMessage(isString(action.payload) ? action.payload : "Couldn't Edit Expense Type", FAILED)
        //     return state
        // }
        // case 'DELETE_EXPENSE_TYPE_FAILED':{
        //     showMessage(isString(action.payload) ? action.payload : "Couldn't Delete Expense Type", FAILED)
        //     return state
        // }
        case SET_ASSET_TYPES: {
            console.log('action.payload: ', action.payload);
            let assets = action.payload
            console.log('assets: ', assets);
            return state.set('assetTypes', assets)
        }
        // case SUBMIT_EXPENSE_FAILED: {
        //     showMessage(isString(action.payload) ? action.payload : "Couldn't Add Expense Type !", FAILED)
        //     return state
        // }
        // case SET_ASSET_TYPES_INCOME_TYPE: {
        //   console.log('action.paylaod:', action.payload );
        //   return state.set('')
        // }
        case GET_EXPENSE_TYPES: {
            return state.setIn(['fixed_expenses', 'fetched_expense_types'], action.payload)
        }
        // case GET_EXPENSE_TYPES_FAILED: {
        //     showMessage('Something went wrong, please reload !', FAILED)
        //     return state
        // }
        case SET_EXPENSE_TYPE: {
            let data = action.payload
            console.log('data: ', data);
            data['type'] = 'edit'
            return state.set('expense_type', data)
        }
        case RESET_EXPENSE_TYPE: {
            let data = { ...initExpenseType }

            let expense_type_erorrs = { ...add_Expense_Boolean(true) }

            return state
                .set('expense_type', data)
                .set('expense_type_erorrs', expense_type_erorrs)
        }
        case DELETE_EXPENSE_TYPE: {
            
            let data = {
                type: 'add',
                name: '',
                fleet: false,
                branch: false,
                general: false,
                variance: 'V',
                sub: '',
                is_route: '',
                duration: false,
                company: ''
            }
            return state.set('expense_type', data)
        }
        case CHANGE_FIXED_EXPENSE: {
            let { what, val } = action.payload
            console.log(' what, val : ', what, val);
            return state
                .setIn(['fixed_expenses', 'post_fixed_expense', ...what], val)
                .setIn(['fixed_expenses', 'fixed_expense_type_erorrs', ...what], validateExpense(add_Fixed_Expense_Validation.getIn(what), val))
                .setIn(['fixed_expenses', 'fixed_expense_type_touched', ...what], true)

        }
        case DELETE_FIXED_EXPENSE_TYPE: {
            showMessage('Fixed Expense Type Deleted !', SUCCESS)
            let payload = {
                type: 'add',
                entity: '',
                entity_id: '',
                ref: '',
                duration: 1,
                amt: '',
                company: '',
            }
            return state.setIn(['fixed_expenses', 'post_fixed_expense'], payload)
        }
        case GET_POSTED_SUB_TYPES: {
            console.log('action.payload', action.payload);
            return state.set('fetched_sub_income_types', action.payload)
        }
        case SET_INCOME_TYPE_FOR_EDIT: {
            console.log("typeeeeee", action.payload);
            let payload = action.payload
            payload['type'] = 'edit'
            return state.set('income_type', payload)
        }
        case EDIT_INCOME_TYPE_SUCCESS: {
            showMessage('Income Type Edited Successfully ', SUCCESS)
            let payload = {
                name: '',
                fleet: false,
                branch: false,
                general: false,
                sub: '',
                is_route: false,
                duration: false,
                company: '',
                type: 'add'
            }
            return state.set('income_type', payload)
        }
        case INCOME_TYPE_DELETED: {
            showMessage('Income Type Deleted ', SUCCESS)
            let payload = {
                name: '',
                fleet: false,
                branch: false,
                general: false,
                sub: '',
                is_route: false,
                duration: false,
                company: '',
                type: 'add'
            }
            return state.set('income_type', payload)
        }
        case RESET_INCOME_TYPE_FORM: {
            let income_type_errors = { ...add_Expense_Boolean(true) }
            return state
                .set('income_type', initIncomeType)
                .set('income_type_errors', income_type_errors)
        }
        case RESET_FIXED_EXPENSE: {
            let payload = {
                type: 'add',
                entity: '',
                entity_id: '',
                ref: '',
                duration: 1,
                amt: '',
                company: '',
            }
            let fixed_expense_type_erorrs = { ...add_Fixed_Expense_Boolean(true) }

            return state
                .setIn(['fixed_expenses', 'post_fixed_expense'], payload)
                .setIn(['fixed_expenses', 'fixed_expense_type_erorrs'], fixed_expense_type_erorrs)
        }
        case SET_FIXED_EXPENSE_TYPE: {
            let data = action.payload
            console.log('reducer data: ', data);
            // delete data.ref
            data['type'] = 'edit'
            // delete data.name
            return state.setIn(['fixed_expenses', 'post_fixed_expense'], data)
        }
        case GET_FIXED_EXPENSE_BY_ID: {
            return state.set('fetched_fixed_expensesById', action.payload.fixedExpenses)
        }
        case RESET_SUB_INCOME_TYPE: {
            let payload = {
                type: 'add',
                name: '',
                ref: ''
            }
            let sub_income_errors = { ...add_Sub_Income_Boolean(true) }

            return state
                .set('sub_income_type', payload)
                .set('sub_income_errors', sub_income_errors)
        }
        case SET_SUBTYPES_FOR_EDIT: {
            let data = action.payload
            data['type'] = 'edit'
            return state.set('sub_income_type', action.payload)
        }
        case CHANGE_ENTITY_ID: {
            return state.setIn(['fixed_expenses', 'fetched_entity_types'], action.payload)
        }
        case SUBMIT_FIXED_EXPENSE_SUCCESS: {
            // showMessage('Fixed Expense type created successfully!', SUCCESS)
            let payload = {
                type: 'add',
                entity: '',
                entity_id: '',
                ref: '',
                duration: 1,
                amt: '',
                company: '',
            }
            return state.setIn(['fixed_expenses', 'post_fixed_expense'], payload)
        }
        case EDIT_FIXED_EXPENSE_SUCCESS: {
            showMessage('Fixed Expense type Edited Successfully!', SUCCESS)
            let payload = {
                type: 'add',
                entity: '',
                entity_id: '',
                ref: '',
                duration: 1,
                amt: '',
                route: '',
                company: '',
            }
            return state.setIn(['fixed_expenses', 'post_fixed_expense'], payload)
        }
        // case SUBMIT_FIXED_EXPENSE_FAILED: {
            
        //     showMessage(action.payload?action.payload:'Could Not Add Fixed Expense !', FAILED)
        //     return state
        // }
        // case EDIT_FIXED_EXPENSE_FAILURE: {
        //     showMessage(action.payload?action.payload:'Could Not Edit Fixed Expense !', FAILED)
        //     return state
        // }
        case CHANGE_ADD_INCOME_TYPE: {
            let { what, val } = action.payload
            let result = state
                .setIn(['income_type', ...what], val)
                .setIn(['income_type_errors', ...what], validateExpense(add_Expense_Validation.getIn(what), val))
                .setIn(['income_type_touched', ...what], true)
            if (what[0] === 'fleet' || what[0] === 'branch') {
                return result.setIn(['income_type', 'general'], false)
            }
            if (what[0] === 'general') {
                return result
                    .setIn(['income_type', 'fleet'], false)
                    .setIn(['income_type', 'branch'], false)
            }
            return result
        }
        case SUBMIT_INCOME_TYPE_SUCCESS: {
            showMessage('Income type created successfully!', SUCCESS)
            let payload = {
                name: '',
                fleet: false,
                branch: false,
                general: false,
                sub: '',
                is_route: false,
                duration: false,
                company: '',
                type: 'add'
            }
            return state.set('income_type', payload)

        }
        case SUBMIT_INCOME_TYPE_FAILED: {
            showMessage('Could Not Add Income Type !', FAILED)
            return state
        }
        case CHANGE_SUB_INCOME_TYPE: {
            let { what, val } = action.payload
            return state
                .setIn(['sub_income_type', ...what], val)
                .setIn(['sub_income_errors', ...what], validateExpense(add_Sub_Income_Validation.getIn(what), val))
                .setIn(['sub_income_touched', ...what], true)

        }
        case EDIT_SUB_INCOME_DATA: {
            showMessage('Sub Income type Edited Successfully ', SUCCESS)
            let payload = {
                type: 'add',
                name: '',
                ref: ''
            }
            return state.set('sub_income_type', payload)
        }
        case ADD_SUB_INCOME_TYPE: {
            showMessage('Sub Income type added Successfully', SUCCESS)
            let payload = {
                type: 'add',
                name: '',
                ref: ''
            }
            return state.set('sub_income_type', payload)
            return state
        }
        case ADD_SUB_INCOME_TYPE_FAILED: {
            showMessage('Something Went Wrong !', SUCCESS)

            return state
        }
        case GET_COMPANY_LIST: {
            return state.set('companyList', action.payload)
        }
        default: {
            return state
        }
    }
}