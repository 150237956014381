import { fromJS } from 'immutable'

import {
  CHANGE_SIMPLE,
  SET_FLEETS,
  SET_FLEET,
  SET_FRANCHISE_FLEETS,
  RESET,
  FLEET_CREATE_SUCCESS,
  FLEET_CREATE_FAIL,
  FLEET_EDIT_SUCCESS,
  FLEET_EDIT_FAIL
} from '../constants/fleet'

import {
  showMessage,
  success,
  failed
} from './UserReducer'

import { errorHandler } from './helpers'
import { isArrayCheck } from '../containers/generics/CheckArray';

const initState = fromJS({
  regNumber: '',
  modelNumber: '',
  manufacturer: '',
  capacity: '',
  volume: {
    l: '',
    b: '',
    h: ''
  },
  fleetType: '',
  active: true,
  noOfSeats: '',
  fleetsMap: {},
  selectFleets: [],
  distanceCovered: '',
  bodyType: '',
  fleets: [],
  idealFuel: '',
  insuranceDate: new Date(),
  fitnessDate: new Date(),
  permitDate: new Date(),
  emiDate:new Date(),
  franchiseFleets: [],
  franchiseFleetsMap: {},
  selectFranchiseFleets : []
})

export default (state = initState, action) => {

  switch (action.type) {

    case CHANGE_SIMPLE: {
      const { what, val } = action.payload
      return state.setIn(what, val)
    }

    case SET_FLEETS: {
      console.log('action.payload: ', action.payload);
      let fleetsMap = {},
      selectFleets=[]
      if (isArrayCheck(action.payload)) {
        action.payload.forEach(e => {
          fleetsMap[e.regNumber] = e
          if(e.regNumber) {
            selectFleets.push({label: e.regNumber, value: e.regNumber})
          }
        });
      }
      return state
        .set('fleets', action.payload)
        .set('fleetsMap', fleetsMap)
        .set('selectFleets', selectFleets)

    }

    case SET_FRANCHISE_FLEETS: {
      console.log('action.payload: ', action.payload);
      let fleetsMap = {},
      selectFranchiseFleets=[]
      if (isArrayCheck(action.payload)) {
        action.payload.forEach(e => {
          fleetsMap[e.regNumber] = e
          if(e.regNumber) {
            selectFranchiseFleets.push({label: e.regNumber + ' ' + e.companyName, value: e.regNumber,company:e.companyId })
          }
        });
      }
      return state
        .set('franchiseFleets', action.payload)
        .set('franchiseFleetsMap', fleetsMap)
        .set('selectFranchiseFleets', selectFranchiseFleets)
    }

    case SET_FLEET: {
      const { _id, fleetType, bodyType, noOfSeats } = action.payload
      return state.merge({
        ...action.payload,
        fleetType,
        bodyType,
        noOfSeats,
        _id: _id
      })
    }

    case RESET: {
      return initState
    }

    case FLEET_CREATE_SUCCESS: {
      showMessage('Fleet created successfully', success)
      return state
    }

    case FLEET_CREATE_FAIL: {
      errorHandler(action.payload)
      return state
    }

    case FLEET_EDIT_SUCCESS: {
      showMessage('Fleet edited successfully', success)
      return state
    }

    case FLEET_EDIT_FAIL: {
      errorHandler(action.payload)
      return state
    }

    default: {
      return state
    }

  }

}