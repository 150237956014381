import { List } from "immutable";
export const isArrayCheck = (arr) => {
    return ((Array.isArray(arr) && arr.length > 0) || List.isList(arr)) || false
    // if (Array.isArray(arr) && arr.length > 0) return true
    // else return false
}
export const sortBranchArray = (a,b) => {
    let comparison = 0;
        
        const branchA = a.branchName.toUpperCase();
        const branchB = b.branchName.toUpperCase();    
        
        if (branchA >  branchB) {
          comparison = 1;
        } else if (branchA < branchB) {
          comparison = -1;
        }   
    
    return comparison;
}

export const time_convert = (num) => { 
  const hours = Math.floor(num /3600000);  
  const minutes =  Math.floor(((num /3600000)-hours) * 60);
  return `${hours}:${minutes}`;         
}
export const convertMS = (millisec) => {
    var seconds = (millisec / 1000).toFixed(0);
    var minutes = Math.floor(seconds / 60);
    var hours = "";
    if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        hours = (hours >= 10) ? hours : "0" + hours;
        minutes = minutes - (hours * 60);
        minutes = (minutes >= 10) ? minutes : "0" + minutes;
    }

    seconds = Math.floor(seconds % 60);
    seconds = (seconds >= 10) ? seconds : "0" + seconds;
    if (hours != "") {
        return hours + ":" + minutes + ":" + seconds;
    }
    return minutes + ":" + seconds;
}
export const FindDate = (date) => {
  return new Date(new Date(new Date(date.setHours(0)).setMinutes(0)).setSeconds(0))
}

export const notificationDateFormat = (date) =>{

    //let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    let options ={day:'numeric', month: 'short'}       
    return  new Date(date).toLocaleDateString(undefined, options)+' at '+ new Date(date).getHours() +':'+new Date(date).getMinutes()
}