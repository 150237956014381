import { fromJS } from 'immutable'
import * as Yup from 'yup'

import {
    GET_BRANCHES_BY_COMPANY,
    GET_CITY,
    GETDETAILS,
    CHANGE_INPUT,
    USER_CREATE_SUCCESS,
    USER_CREATE_FAILED,
    USER_EDIT_SUCCESS,
    USER_EDIT_FAILED,
    RESET_USER_FORM,
    CHANGE_ASSIGNED_INFO,
    RESET_USER_FORM_NEW_USER,
    ASSIGN_BRANCH_FAILED,
    ASSIGN_FLEET_FAILED,
    ASSIGN_FLEET,
    ASSIGN_BRANCH,
    FETCH_DEPARTMENT_LIST
} from '../constants/user'
import { failed, showMessage, success } from './UserReducer';
import { isArrayCheck,sortBranchArray } from '../containers/generics/CheckArray';

const booleanSchema = (defaultValue) => ({
    name: {
        fName: defaultValue,
        mName: defaultValue,
        lName: defaultValue
    },
    dob: defaultValue,
    address: {
        l1: defaultValue,
        l2: defaultValue,
        city: defaultValue,
        pincode: defaultValue,

    },
    // role:defaultValue,
    contact: defaultValue,
    password: defaultValue,
    email: defaultValue,
})

const validationSchema = fromJS({
    contact: Yup.string().required().min(10, 'Contact must be 10 digits').max(10, 'Contact must be 10 digits'),
    name: {
        fName: Yup.string().required(),
        mName: Yup.string().max(10),
        lName: Yup.string().required()
    },
    address: {
        l1: Yup.string().required().max(15),
        l2: Yup.string().required().max(15),
        city: Yup.string().required().max(15),
        pincode: Yup.string().required().max(10)
    },
    password: Yup.string().required().min(3, 'Password should be minimum 6 characters'),
    email: Yup.string().email().required()
})

const validate = (schema, obj) => {
    try {
        if(!schema) return false
        const res = schema.validateSync(obj)
        return false
    }
    catch (err) {
        console.log(err)
        return err.message
    }
}

const initialState = fromJS({
    values: {
        _id: '',
        type: 'add',
        contact:'',
        name: {
            fName: '',
            // mName: '',
            lName: ''
        },
        role: 'O',
        dob: new Date(),
        address: {
            l1: '',
            l2: '',
            city: '',
            pincode: '',

        },
        active:true,
        password: '',
        email: '',
        company:'',
        assignedBranch: [],
        assignedFleet: [],
        assignedDepartment: [],
        allbranches:[],
        userShiftDuty:{
            startTime:'',
            endTime:''
        }
    },
    assignedBranches: [],
    departMentList: [],
    errors: { ...booleanSchema(true) },
    touched: { ...booleanSchema(false) }
})

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_BRANCHES_BY_COMPANY:    
                let allBranches = action.payload;
                let sortedBranches = allBranches.sort(sortBranchArray);
                return state
                .setIn(['values', 'allbranches'], isArrayCheck(action.payload) ? sortedBranches.map(b => ({ label: b.branchName, value: b._id })) : [])
        case GET_CITY:               
                return state
                .setIn(['values', 'address','city'],  action.payload)
        case GETDETAILS:
            // const { dob } = action.payload, { d, m, y } = dob
            const { branches, fleets, departments } = action.payload
            console.log(action.payload,'data contact')
            const values = {
                type: 'edit',
                ...action.payload,
                companyId: action.payload.company,   
                userShiftDuty:(action.payload.userShiftDuty) ? 
                               {   startTime:action.payload.userShiftDuty.startTime,
                                   endTime:action.payload.userShiftDuty.endTime
                               } : {startTime:'',endTime:''},           
                assignedBranch: isArrayCheck(branches) ? branches.map(b => ({ label: b.branchName, value: b._id })) : [],
                assignedFleet: isArrayCheck(fleets) ? fleets.map(f => ({ label: f.regNumber, value: f._id })) : [],
                assignedDepartment: isArrayCheck(departments) ? departments.map(f => ({ label: f.departmentName, value: f._id })) : []
            }
            return state.setIn(['values'], { ...values })

        case CHANGE_INPUT: {
            const { key, val } = action.payload
            return state
                .setIn(['values', ...key], val)
                .setIn(['touched', ...key], true)
                .setIn(['errors', ...key], validate(validationSchema.getIn(key), val))
        }
        case FETCH_DEPARTMENT_LIST: {
            const temp = isArrayCheck(action.payload) ? action.payload.map(a => ({ label: a.departmentName, value: a._id })) :[]
            return state.set('departMentList', temp)
        }
        case USER_CREATE_SUCCESS:
            showMessage('User Created Successfully !', success)
            return state
        case USER_CREATE_FAILED:
            showMessage('Could not create user !', success)
            return state
        case USER_EDIT_SUCCESS:
            showMessage("User Edited Successfully !", success)
            return state
        case USER_EDIT_FAILED:
            showMessage('Could not edit user !', success)
            return state

        case RESET_USER_FORM: {
            if(action.payload){
                let { contact } = action.payload;
                 return state.set('values', initialState.get('values')).setIn(['values','contact'],contact)
            }
            return state.set('values', initialState.get('values'))
        }

        case RESET_USER_FORM_NEW_USER: {
            let data = {
                contact:'',
                name: {
                    fName: '',
                    mName: '',
                    lName: ''
                },
                company:'',
                role:'O',
                dob: new Date(),
                address: {
                    l1: '',
                    l2: '',
                    city: '',
                    pincode: '',

                },
                password: '',
                email: '',
                active:true,
                userShiftDuty: {
                    startTime:'',
                    endTime:''
                },
            }
            return state.set('values', data)
        }
        case ASSIGN_BRANCH: {
            showMessage("Branches Assigned Successfully !", success)
            return state
        }
        case ASSIGN_FLEET: {
            showMessage("Fleets Assigned Successfully !", success)
            return state
        }
        case ASSIGN_BRANCH_FAILED: {
            showMessage("Couldn't Assign Branch !", failed)
            return state
        }
        case ASSIGN_FLEET_FAILED: {
            showMessage("Couldn't Assign Fleet !", failed)
            return state
        }
        default:
            return state;
    }
}